import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import CreateSpotForm from './booking-forms/CreateSpotForm';

interface BookingCreateDialogProps {
  Trigger?: React.ElementType;
  slug: string;
}

export function BookingCreateDialog(props: BookingCreateDialogProps) {
  const { Trigger, slug } = props;
  const [open, setOpen] = React.useState(false);
  const dialogContentRef = React.useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open && dialogContentRef.current) {
      // Scroll the modal content to the top when it opens
      dialogContentRef.current.scrollTop = 0;
    }
  }, [open]);

  return (
    <>
      {Trigger && (
        <Box onClick={handleOpen} p={0} m={0}>
          <Trigger />
        </Box>
      )}
      <Dialog open={open} onClose={handleClose} fullScreen scroll="body" ref={dialogContentRef} keepMounted>
        <Stack spacing={1} p={3} height="100%">
          <CreateSpotForm handleClose={handleClose} spotSlug={slug} />
        </Stack>
      </Dialog>
    </>
  );
}
