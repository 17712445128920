import { Navigate } from 'react-router-dom';

import { AccountTypeEnum } from '@graphql';

interface RequireRoleProps {
  children: JSX.Element;
  role: 'Guest' | 'Consumer' | 'Spot' | 'Maker' | 'Support' | 'Organizer';
  allowedRoles: Array<string | null>;
  redirection?: string;
}

export function RequireRole(props: RequireRoleProps) {
  const { children, role, allowedRoles, redirection } = props;

  // handle Guest user or Consumer account
  if (!allowedRoles.includes(role) && ['Guest', 'Consumer'].includes(role)) {
    return <Navigate to="/spots" replace />;
  }

  // handle Spot connected account
  if (!allowedRoles.includes(role) && role === 'Spot') {
    return <Navigate to="/me" replace />;
  }

  if (redirection) {
    return <Navigate to={redirection} replace />;
  }

  return children;
}

interface RequireMeRoleProps {
  children: JSX.Element;
  role: 'Guest' | 'Consumer' | 'Spot' | 'Maker' | 'Support' | 'Organizer';
  allowedRoles: Array<string | null>;
  redirection?: string;
}

export function RequireMeRole(props: RequireMeRoleProps) {
  const { children, role, allowedRoles, redirection } = props;

  if (!role) {
    return <Navigate to="/spots" replace />;
  }

  // handle Guest user or Consumer account
  if (!allowedRoles.includes(role) && ['Guest', 'Consumer'].includes(role)) {
    return <Navigate to="/spots" replace />;
  }

  // handle Spot account
  if (!allowedRoles.includes(role) && role === 'Spot') {
    return <Navigate to="/me" replace />;
  }

  if (redirection) {
    return <Navigate to={redirection} replace />;
  }

  return children;
}
