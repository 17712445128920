import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useToastError } from '@hooks';
import { useSupdateAccountMutation } from '@graphql';
import { TextField } from '@components';
import { Theme } from '@mui/material/styles';

interface AccountDescriptionProps {
  id: string;
  description?: string | null;
}

export function AccountDescription(props: AccountDescriptionProps) {
  const { id, description = null } = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateAccount] = useSupdateAccountMutation();

  const formik = useFormik({
    initialValues: { description },
    validationSchema: yup.object({
      description: yup.string().nullable().required(),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      await updateAccount({
        variables: {
          id,
          input: {
            description: values.description?.length ? values.description : null,
          },
        },
        onCompleted() {
          formik.resetForm({ values });
          setIsEditing(false);
        },
        onError: toastError,
      });
      setIsSubmitting(false);
    },
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack width="100%" spacing={1}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack>
            <Typography fontWeight="bold">{t('accountDescription.label', { ns: 'field' })}</Typography>
            <Typography variant="caption" color="text.secondary">
              {t('accountDescription.caption', { ns: 'field' })}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="flex-start">
            {isEditing ? (
              <>
                <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid}
                  loading={isSubmitting}
                  variant="contained"
                  color="success"
                  size="small"
                  type="submit"
                >
                  {t('validate')}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
                {t('edit')}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack>
          {isEditing ? (
            <TextField
              multiline
              required
              fullWidth
              rows={4}
              size="small"
              type="description"
              name="description"
              placeholder="saisis la description du compte"
              disabled={isEditing && isSubmitting}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          ) : (
            <Stack
              p={2}
              sx={{ backgroundColor: (theme: Theme) => theme.palette.background.default, borderRadius: 3 }}
            >
              <Typography
                color={`text.${description ? 'primary' : 'secondary'}`}
                fontStyle={description ? 'normal' : 'italic'}
              >
                {description ?? 'aucune description'}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </form>
  );
}
