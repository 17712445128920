import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { Spot, useSupdateSpotPricingMutation, SpotPricingEnum } from '@graphql';
import { useToastError } from '@hooks';

interface PricingProps {
  account: Spot;
}

export default function Pricing(props: PricingProps) {
  const { account } = props;
  const toastError = useToastError();
  const { t } = useTranslation('spot');

  const [updateSpotPricing] = useSupdateSpotPricingMutation();

  const initialValues = { pricing: account.configuration.pricing ?? '' };
  const validationSchema = yup.object({
    pricing: yup.string().oneOf(Object.values(SpotPricingEnum)).nullable().defined(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateSpotPricing({
        variables: { id: account.id, pricing: values.pricing as SpotPricingEnum },
        onCompleted({ supdateSpotPricing }) {
          formik.resetForm({ values });
          toast(t('spotPricing.toast.success', { ns: 'field' }), { type: 'success' });
        },
        onError: toastError,
      });
    },
  });

  React.useEffect(() => {
    if (formik.dirty && formik.isValid) {
      formik.submitForm();
    }
  }, [formik.dirty, formik.isValid, formik.values]);

  if (account?.__typename !== 'Spot') {
    return null;
  }

  return (
    <Stack width="100%" spacing={2}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack>
          <Typography fontWeight="bold">{t('spotPricing.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotPricing.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>
      <FormControl fullWidth>
        <InputLabel id="pricing-select-label">{t('spotPricing.label', { ns: 'field' })}</InputLabel>
        <Select
          {...formik.getFieldProps('pricing')}
          id="pricing-select"
          labelId="pricing-select-label"
          label={t('spotPricing.label', { ns: 'field' })}
          autoWidth
          value={formik.values.pricing}
          onChange={formik.handleChange}
        >
          <MenuItem value="">
            <em>{t(`word.null`, { ns: 'common' })}</em>
          </MenuItem>
          <MenuItem value={SpotPricingEnum.Eco}>{t(`pricing.${SpotPricingEnum.Eco}`)}</MenuItem>
          <MenuItem value={SpotPricingEnum.Standard}>{t(`pricing.${SpotPricingEnum.Standard}`)}</MenuItem>
          <MenuItem value={SpotPricingEnum.High}>{t(`pricing.${SpotPricingEnum.High}`)}</MenuItem>
          <MenuItem value={SpotPricingEnum.Premium}>{t(`pricing.${SpotPricingEnum.Premium}`)}</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}
