import * as React from 'react';
import { Theme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useToastError } from '@hooks';
import { Account, useSupdateAccountStatusesMutation, AccountStatusesInput } from '@graphql';

interface AccountStatusesProps {
  account: Account;
}

export function AccountStatuses(props: AccountStatusesProps) {
  const { account } = props;
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateStatuses] = useSupdateAccountStatusesMutation();

  const initialValues = {
    isActive: account.isActive,
    isVerified: account.isVerified,
    isPrivate: account.__typename === 'Consumer' ? account.isPrivate : false,
  };

  const validationSchema = yup.object().shape({
    isActive: yup.boolean().required(),
    isVerified: yup.boolean().required(),
    isPrivate: yup.boolean().nullable().defined(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (formik.dirty) {
        updateStatuses({
          variables: { id: account.id, input: values },
          onCompleted: ({ supdateAccountStatuses }) => {
            setTimeoutId(null);
            formik.resetForm({ values });
            toast(t('accountStatuses.toast.success', { ns: 'field' }), { type: 'success' });
          },
          onError: toastError,
        });
      }
    },
  });

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    if (formik.dirty && formik.isValid) {
      const newTmoutId = setTimeout(formik.submitForm, 1000);
      setTimeoutId(newTmoutId);
    }
  }, [formik.dirty, formik.isValid, formik.values]);

  const handleClick = (key: string) => {
    formik.setFieldValue(key, !Boolean(formik.values[key as keyof AccountStatusesInput]));
  };

  if (!account) {
    return null;
  }

  return (
    <Stack
      width="100%"
      spacing={2}
      divider={<Divider sx={{ backgroundColor: (theme: Theme) => theme.palette.grey[800] }} />}
    >
      <ListItem disablePadding>
        <ListItemButton role={undefined} onClick={() => handleClick('isActive')} dense>
          <ListItemText id={`switch-list-label-isActive`} primary="actif" />
          <ListItemIcon>
            <Switch name="isActive" checked={Boolean(formik.values.isActive)} color="success" />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton role={undefined} onClick={() => handleClick('isVerified')} dense>
          <ListItemText id={`switch-list-label-isVerified`} primary="vérifié" />
          <ListItemIcon>
            <Switch name="isVerified" checked={Boolean(formik.values.isVerified)} color="success" />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          role={undefined}
          onClick={() => handleClick('isPrivate')}
          dense
          disabled={account.__typename !== 'Consumer'}
        >
          <ListItemText id={`switch-list-label-isPrivate`} primary="privé" />
          <ListItemIcon>
            <Switch name="isPrivate" checked={Boolean(formik.values.isPrivate)} color="success" />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </Stack>
  );
}
