import { useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useSession } from '@hooks';
import { truncateText } from '@utils';

export default function AccountMenu() {
  const theme = useTheme();
  const { account } = useSession();
  const { pathname } = useLocation();

  if (!account) {
    return null;
  }

  const path = `${pathname}?${account.__typename.toLowerCase()[0]}=${account.slug}`;

  return (
    <Link to={path} preventScrollReset={true} style={{ textDecoration: 'none' }}>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Avatar src={account.avatar?.url} sx={{ width: 30, height: 30 }}>
              {account.name[0]}
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={truncateText(account.name, 20)}
            secondary={'@' + truncateText(account.slug, 20)}
            primaryTypographyProps={{
              fontWeight: 'bold',
              color: theme.palette.text.primary,
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
