import * as React from 'react';
import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router-dom';

import { useListHomeSpotsLazyQuery, ListHomeSpotsQuery } from '@graphql';
import { Sliders, Highlight } from '@components';

const SpotsPage = () => {
  const [homeSpots, setHomeSpots] = React.useState<ListHomeSpotsQuery['homeSpots'] | null>(null);
  const location = useLocation();

  const [getSpots] = useListHomeSpotsLazyQuery({
    onCompleted: ({ homeSpots }) => {
      setHomeSpots(homeSpots ?? null);
    },
  });

  React.useEffect(() => {
    const isAlreadyLoaded = ['dialog', 'slide'].includes(location.state.from);

    if (!isAlreadyLoaded) {
      getSpots({ fetchPolicy: 'network-only' });
    }
  }, [location.state?.from]);

  if (!homeSpots) {
    return null;
  }

  return (
    <Stack pb={8}>
      <Highlight type="spot" />
      <Sliders
        categories={homeSpots.reduce((categories, category) => {
          if (category.spots.length) {
            categories.push(category);
          }

          return categories;
        }, [] as ListHomeSpotsQuery['homeSpots'])}
      />
    </Stack>
  );
};

export default SpotsPage;
