import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { SpotAtmosphere, SpotType, GetEventDetailedQuery, GetSpotDetailedQuery } from '@graphql';
import { getTrueKeys } from '@utils';

interface TileProps extends StackProps {
  children: React.ReactNode;
}

interface DateTileProps extends StackProps {
  from: string;
  to: string;
}
interface SchedulesTileProps extends StackProps {
  opening: GetSpotDetailedQuery['spot']['opening'];
  closing: GetSpotDetailedQuery['spot']['closing'];
}

interface ScheduleTileProps extends StackProps {
  from: string;
  to: string;
}

interface AddressTileProps extends StackProps {
  label?: string;
}

interface SpotTypeTileProps extends StackProps {
  types: SpotType;
}

interface EventPriceTileProps extends StackProps {
  tickets: GetEventDetailedQuery['event']['tickets'];
}

interface SpotPricingTileProps extends StackProps {
  pricing: GetSpotDetailedQuery['spot']['configuration']['pricing'];
}

interface SpotTileProps extends StackProps {
  avatar?: string;
  name: string;
  slug: string;
}

interface CapacityTileProps extends StackProps {
  max?: number | null;
}

interface CloseSpaceTileProps extends StackProps {
  value?: number;
}
interface AtmosphereTileProps extends StackProps {
  atmosphere?: Partial<SpotAtmosphere>;
}
interface MusicalGenreTileProps extends StackProps {
  type?: string | null;
}

const Tile = ({ children, ...rest }: TileProps) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      width="auto"
      p={1}
      borderRadius={theme.spacing(1)}
      spacing={1}
      flex={1}
      alignItems="center"
      bgcolor={theme.palette.background.paper}
      {...rest}
    >
      {children}
    </Stack>
  );
};

const Title = ({ text }: { text: string }) => {
  const { t } = useTranslation('spot');

  return (
    <Typography variant="body1" color="GrayText" fontSize=".8rem" fontWeight={600}>
      {t('title.' + text)}
    </Typography>
  );
};

export const DateTile = (props: DateTileProps) => {
  const theme = useTheme();
  const { from, to } = props;

  return (
    <Tile>
      <theme.icons.event />
      <Stack>
        <Title text="Date" />
        <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
          {moment(from).format('dddd DD MMMM YYYY')}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const ScheduleTile = (props: ScheduleTileProps) => {
  const theme = useTheme();
  const { from, to } = props;

  return (
    <Tile>
      <theme.icons.timelapse />
      <Stack>
        <Title text="Schedules" />
        <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
          {moment(from).format('HH:mm') + ' - ' + moment(to).format('HH:mm')}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const SpotTypeTile = (props: SpotTypeTileProps) => {
  const { types } = props;
  const theme = useTheme();
  const { t } = useTranslation('spot');

  const MAX_TYPES = 3;

  let active = Object.keys(types)
    .filter((key) => types[key as keyof SpotType] === true)
    .map((type) => t('type.' + type));

  if (active.length > MAX_TYPES) {
    active.splice(
      MAX_TYPES,
      active.length - MAX_TYPES,
      t('word.+count other', { ns: 'common', count: active.length - MAX_TYPES })
    );
  }

  return (
    <Tile>
      <theme.icons.store />
      <Stack>
        <Title text="Type" />
        <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
          {active.join(', ')}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const AddressTile = (props: AddressTileProps) => {
  const theme = useTheme();
  const { label } = props;

  return (
    <Tile>
      <theme.icons.location />
      <Stack>
        <Title text="Direction" />
        <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
          {label || 'NC'}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const EventPriceTile = (props: EventPriceTileProps) => {
  const theme = useTheme();
  const { tickets } = props;
  const { t } = useTranslation('event');

  const curMap = {
    EUR: '€',
    USD: '$',
    GBP: '£',
  };

  const [lowestPrice] = tickets;

  return (
    <Tile>
      <theme.icons.price />
      <Stack>
        <Title text="Price" />
        <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
          {lowestPrice?.price
            ? t('price.from', {
                price: lowestPrice.price,
                currency: curMap[lowestPrice.currency as 'EUR' | 'USD' | 'GBP'],
              })
            : t('word.NC', { ns: 'common' })}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const SchedulesTile = (props: SchedulesTileProps) => {
  const theme = useTheme();
  const { opening, closing } = props;
  const { t } = useTranslation('spot');

  const dayOfWeek = new Date().getDay();
  const daySchedules = opening[dayOfWeek];

  const openTime = daySchedules.openTime ? moment(daySchedules.openTime, 'HH:mm:ss') : null;
  const closingTime = daySchedules.closingTime ? moment(daySchedules.closingTime, 'HH:mm:ss') : null;
  const breakInTime = daySchedules.breakIn ? moment(daySchedules.breakIn, 'HH:mm:ss') : null;
  const breakOutTime = daySchedules.breakOut ? moment(daySchedules.breakOut, 'HH:mm:ss') : null;

  if (closingTime && closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  const isClosed = closing.some((period) => moment().isBetween(period.from, period.to));

  let isOpen = false;
  let isClosingSoon = false;
  let isOpeningSoon = false;
  let openingHour = openTime ? openTime.fromNow() : null;
  let closingHour = closingTime ? closingTime.fromNow() : null;

  if (breakInTime && breakOutTime) {
    if (openingHour && closingTime && moment().isBetween(openTime, breakInTime, null, '[)')) {
      // during the first opening period
      isOpen = true;
      isOpeningSoon = false;
      openingHour = breakOutTime.format('HH:mm');
      isClosingSoon = moment().add(1, 'hours').isAfter(breakOutTime);
      closingHour = isClosingSoon ? breakOutTime.fromNow() : closingTime.format('HH:mm');
    } else if (closingTime && moment().isBetween(breakInTime, breakOutTime, null, '[]')) {
      // during break time
      isOpen = false;
      isOpeningSoon = moment().add(1, 'hours').isAfter(breakOutTime);
      openingHour = isOpeningSoon ? breakOutTime.fromNow() : breakOutTime.format('HH:mm');
    } else if (closingTime && moment().isBetween(breakOutTime, closingTime, null, '[)')) {
      // during the second opening period
      isOpen = true;
      isOpeningSoon = !isOpen && moment().add(1, 'hours').isAfter(closingTime);
      openingHour = isOpeningSoon ? closingTime.fromNow() : closingTime.format('HH:mm');
    } else if (openTime && closingTime) {
      // during closing time
      isOpen = false;
      isOpeningSoon = !isOpen && moment().add(1, 'hours').isAfter(openTime);
      openingHour = isOpeningSoon ? openTime.fromNow() : openTime.format('HH:mm');
    }
  } else if (openTime && closingTime) {
    // default case with no break time
    isOpen = moment().isBetween(openTime, closingTime, null, '[)');
    isOpeningSoon = !isOpen && moment().add(1, 'hours').isAfter(openTime);
    openingHour = isOpeningSoon ? openTime.fromNow() : openTime.format('HH:mm');
    isClosingSoon = isOpen && moment().add(1, 'hours').isAfter(closingTime);
    closingHour = isClosingSoon ? closingTime.fromNow() : closingTime.format('HH:mm');
  }

  let status = t('schedules.closed'),
    detail = '',
    statusColor: 'error' | 'warning' | 'success' | 'inherit' = 'inherit';

  if (isClosed) {
    status = t('schedules.closed');
  } else if (isClosingSoon) {
    status = t('schedules.closes soon');
    detail = closingHour ?? '';
    statusColor = 'warning';
  } else if (isOpeningSoon) {
    status = t('schedules.opens soon');
    detail = openingHour ?? '';
    statusColor = 'warning';
  } else if (isOpen) {
    status = t('schedules.open');
    detail = t('schedules.closes at', { time: closingHour });
    statusColor = 'success';
  }

  const sortedOpening = [...opening].sort((a, b) => {
    // set dayOfWeek = 0 at the end
    if (a.dayOfWeek === 0) return 1;
    if (b.dayOfWeek === 0) return -1;
    return a.dayOfWeek - b.dayOfWeek;
  });

  const allSchedules = sortedOpening.map((day, index) => {
    const { openTime, closingTime, breakIn, breakOut } = day;
    const dayName = moment().day(day.dayOfWeek).format('dddd');
    const isToday = day.dayOfWeek === dayOfWeek;

    let dateSchedules: string | null = null;

    if (openTime && closingTime) {
      if (breakIn && breakOut) {
        dateSchedules = `${openTime.slice(0, -3)} - ${breakIn.slice(0, -3)}, ${breakOut.slice(
          0,
          -3
        )} - ${closingTime.slice(0, -3)}`;
      } else {
        dateSchedules = `${openTime.slice(0, -3)} - ${closingTime.slice(0, -3)}`;
      }
    }

    return (
      <Stack key={index} spacing={1} direction="row" justifyContent="space-between">
        <Typography
          variant="body2"
          fontSize=".8rem"
          fontWeight={isToday ? 'bold' : 'normal'}
          color={isToday ? 'textPrimary' : 'textSecondary'}
          width="75px"
        >
          {dayName.charAt(0).toUpperCase() + dayName.slice(1)}
        </Typography>
        <Typography
          variant="body2"
          fontSize=".8rem"
          fontWeight={isToday ? 'bold' : 'normal'}
          color={isToday ? 'textPrimary' : 'textSecondary'}
        >
          {dateSchedules || t('schedules.closed')}
        </Typography>
      </Stack>
    );
  });

  return (
    <Tile>
      <theme.icons.timelapse />
      <Stack width="100%">
        <Title text="Schedules" />
        <Stack direction="row" alignItems="center" width="100%">
          <Typography variant="body1" color={statusColor} fontSize=".8rem" fontWeight={600}>
            {status}
          </Typography>
          {detail && (
            <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
              , {detail}
            </Typography>
          )}
          <Tooltip
            enterTouchDelay={0}
            title={<Stack>{allSchedules}</Stack>}
            sx={{ ml: 'auto' }}
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: theme.palette.grey[900],
                  color: theme.palette.common.white,
                  boxShadow: theme.shadows[3],
                  fontSize: '.9rem',
                },
              },
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2" fontSize=".8rem">
                {t('schedules.all schedules')}
              </Typography>
              <theme.icons.infoOutlined sx={{ width: 15, height: 15 }} />
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>
    </Tile>
  );
};

export const SpotPricingTile = (props: SpotPricingTileProps) => {
  const { pricing } = props;
  const theme = useTheme();
  const { t } = useTranslation('spot');

  return (
    <Tile>
      <theme.icons.price />
      <Stack width="100%">
        <Title text="Price range" />
        <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
          {pricing === null ? t(`word.null`, { ns: 'common' }) : t(`pricing.${pricing}`)}
        </Typography>
      </Stack>
      {false && (
        <Stack width="100%">
          <Title text="Entry" />
          <Typography variant="body1" fontSize=".8rem" fontWeight={600}>
            free
          </Typography>
        </Stack>
      )}
    </Tile>
  );
};

export const SpotTile = (props: SpotTileProps) => {
  const { avatar, name, slug, ...rest } = props;
  const theme = useTheme();
  const location = useLocation();

  // mount link
  const isSearch = location.pathname.startsWith('/search/');
  const to = {
    pathname: isSearch ? `/search/spot/${slug}` : location.pathname,
    search: isSearch ? location.search : `?s=${slug}`,
  };

  return (
    <Link to={to} preventScrollReset={true} style={{ width: '100%' }}>
      <Tile sx={{ cursor: 'pointer' }} {...rest}>
        <Avatar src={avatar} sx={{ width: theme.spacing(3), height: theme.spacing(3) }}>
          <theme.icons.store />
        </Avatar>
        <Stack>
          <Title text="Spot" />
          <Typography variant="body1" fontSize=".8rem" fontWeight={600} color="text.primary">
            {name}
          </Typography>
        </Stack>
      </Tile>
    </Link>
  );
};

export const CloseSpaceTile = (props: CloseSpaceTileProps) => {
  const { value } = props;
  const theme = useTheme();
  const { t } = useTranslation('common');

  return (
    <Tile>
      <theme.icons.spaces />
      <Stack>
        <Title text="Closed space" />
        <Typography
          variant="body1"
          fontSize=".8rem"
          fontWeight={600}
          fontStyle={value === undefined ? 'italic' : 'normal'}
        >
          {value || t('word.NC')}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const CapacityTile = (props: CapacityTileProps) => {
  const { max = 'NC' } = props;
  const isEmpty = props.max === undefined || props.max === null;
  const theme = useTheme();
  const { t } = useTranslation('spot');

  return (
    <Tile>
      <theme.icons.people />
      <Stack>
        <Title text="Capacity" />
        <Typography
          variant="body1"
          fontSize=".8rem"
          fontWeight={600}
          fontStyle={isEmpty ? 'italic' : 'normal'}
        >
          {!isEmpty ? t('capacity.up to', { max }) : t('word.NC', { ns: 'common' })}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const AtmosphereTile = (props: AtmosphereTileProps) => {
  const { atmosphere, ...rest } = props;
  const theme = useTheme();
  const { t } = useTranslation('spot');

  const calm = atmosphere?.calm;
  const lively = atmosphere?.lively;
  const dancing = atmosphere?.dancing;
  const isEmpty = calm === undefined && lively === undefined && dancing === undefined;
  const icon = (dancing && <theme.icons.volMax />) ||
    (lively && <theme.icons.volMid />) ||
    (calm && <theme.icons.volMin />) || <theme.icons.volMid />;

  const atmospheres = getTrueKeys(atmosphere || {})
    .map((a) => t('atmosphere.' + a))
    .join(', ');

  return (
    <Tile {...rest}>
      {icon}
      <Stack>
        <Title text="Atmosphere" />
        <Typography
          variant="body1"
          fontSize=".8rem"
          fontWeight={600}
          fontStyle={isEmpty ? 'italic' : 'normal'}
        >
          {isEmpty ? t('word.NC', { ns: 'common' }) : atmospheres}
        </Typography>
      </Stack>
    </Tile>
  );
};

export const MusicalGenreTile = (props: MusicalGenreTileProps) => {
  const { type, ...rest } = props;
  const theme = useTheme();
  const { t } = useTranslation('spot');

  return (
    <Tile {...rest}>
      <theme.icons.music />
      <Stack>
        <Title text="Vibe" />
        <Typography variant="body1" fontSize=".8rem" fontWeight={600} fontStyle={!type ? 'italic' : 'normal'}>
          {type ? t('vibe.' + type) || t('word.NC', { ns: 'common' }) : '-'}
        </Typography>
      </Stack>
    </Tile>
  );
};

export default {
  Date: DateTile,
  SpotType: SpotTypeTile,
  Schedule: ScheduleTile,
  Address: AddressTile,
  EventPrice: EventPriceTile,
  SpotPricing: SpotPricingTile,
  Spot: SpotTile,
  Capacity: CapacityTile,
  CloseSpace: CloseSpaceTile,
  Atmosphere: AtmosphereTile,
  MusicalGenre: MusicalGenreTile,
  Schedules: SchedulesTile,
};
