import * as yup from 'yup';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { TFunction } from 'i18next';

import {
  Account,
  BookingOccasionEnum,
  BookingStatusEnum,
} from '@graphql';
import { Booking } from "./types";

export default function validationSchema(booking: Booking | null, t: TFunction, typename?: Account["__typename"]) {
  const startDate = yup.date().min(moment().startOf('day')).required();
  const startTime = yup.date().min(moment()).required();
  const participants = yup.number().min(1).required();
  const occasion = yup.string().oneOf(['', ...Object.values(BookingOccasionEnum)]);
  const status = yup
    .string()
    .oneOf(['', ...Object.values(BookingStatusEnum)])
    .required();
  const firstname = yup.string().required();
  const lastname = yup.string().nullable();
  const phone = yup
    .string()
    .matches(/^[0-9+\s()-]+$/, t('mobilePhone.validation.matches', { ns: 'field' }))
    .test('is-french-phone', 'Numéro de téléphone invalide', (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, 'FR');
      return phoneNumber ? phoneNumber.isValid() : false;
    })
    .required();
  const email = yup.string().email().nullable();

  if (!['Spot', 'Consumer'].includes(typename ?? '')) {
    return yup.object({});
  } else if (typename === 'Spot' && !booking) {
    return yup.object({
      startDate,
      startTime,
      participants,
      occasion,
      status,
      firstname,
      lastname,
      phone,
      email,
    });
  } else if (typename === 'Spot') {
    return booking?.origin === 'SPOT' ? yup.object({
      startDate,
      startTime,
      participants,
      occasion,
      status,
      firstname,
      lastname,
      phone,
      email,
    }) : yup.object({ status });
  } else {
    return yup.object({
      startDate,
      startTime,
      participants,
      occasion,
      status,
      phone,
    })
  }
}