import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSearchParams } from 'react-router-dom';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTranslation } from 'react-i18next';

import { useSearchSuggestLocationQuery, SearchSuggest } from '@graphql';

interface Option {
  label: string;
  type: 'city' | 'county' | 'postalCode';
  postalCode: string;
  county: string | null;
  city: string | null;
}

export const LocationFormField = () => {
  const [selected, setSelected] = React.useState<Option[]>([]);
  const [options, setOptions] = React.useState<readonly Option[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('common');
  const theme = useTheme();

  const search = searchParams.get('search')?.slice(0, -1);

  useSearchSuggestLocationQuery({
    skip: !search,
    variables: { search: search as SearchSuggest },
    fetchPolicy: 'cache-first',
    onCompleted({ searchSuggestLocation }) {
      const locations = searchParams.get('location')?.split('+') || [];

      const formatted = searchSuggestLocation.reduce(
        (acc, curr) => {
          const { city, county, postalCode } = curr;
          const countyPostalCode = postalCode.slice(0, 2);

          if (!acc.cities.has(city)) {
            acc.cities.add(city);
            const option: Option = { label: city, type: 'city', city, county, postalCode };
            acc.options.push(option);
            if (locations.includes(city)) {
              setSelected([...selected, option]);
            }
          }

          if (!acc.counties.has(county)) {
            acc.counties.add(county);
            const option: Option = {
              label: county,
              type: 'county',
              city: null,
              county,
              postalCode: countyPostalCode,
            };

            acc.options.push(option);
            if (locations.includes(county)) {
              setSelected([...selected, option]);
            }
          }
          // county postal code
          if (!acc.potalCodes.has(countyPostalCode)) {
            acc.potalCodes.add(countyPostalCode);
            const option: Option = {
              label: countyPostalCode,
              type: 'postalCode',
              city: null,
              county,
              postalCode: countyPostalCode,
            };
            acc.options.push(option);
            if (locations.includes(countyPostalCode)) {
              setSelected([...selected, option]);
            }
          }
          // city postal code
          if (!acc.potalCodes.has(postalCode)) {
            acc.potalCodes.add(postalCode);
            const option: Option = { label: postalCode, type: 'postalCode', city: null, county, postalCode };
            acc.options.push(option);
            if (locations.includes(postalCode)) {
              setSelected([...selected, option]);
            }
          }
          return acc;
        },
        {
          cities: new Set(),
          counties: new Set(),
          potalCodes: new Set(),
          options: [] as Option[],
        }
      );

      setOptions(formatted.options);
    },
  });

  const onErrors = (errors: any) => {};

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        const newOption: Option = {
          label: 'Pyrénées-Atlantiques',
          type: 'county',
          city: null,
          county: 'Pyrénées-Atlantiques',
          postalCode: '64',
        };

        setSelected([newOption]);
        searchParams.set('location', newOption.label);
        setSearchParams(searchParams);
      }, onErrors);
    } else {
      // nothing to do
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Option) => `${option.label}`,
    limit: 10,
    trim: true,
    ignoreAccents: true,
    ignoreCase: true,
  });

  return (
    <Autocomplete
      multiple
      fullWidth
      autoHighlight
      size="small"
      limitTags={2}
      id="multiple-location-limit-tags"
      popupIcon={<theme.icons.myLocation />}
      value={selected}
      slotProps={{
        popupIndicator: {
          onClick: handleGetLocation,
        },
      }}
      onChange={(event, newValue) => {
        setSelected(newValue);
        if (newValue.length) {
          const location = newValue.map((option) => option.label.trim()).join('+');
          searchParams.set('location', location);
        } else if (searchParams.get('location')) {
          searchParams.delete('location');
        }
        setSearchParams(searchParams);
      }}
      sx={{
        width: '100%',
      }}
      options={options}
      noOptionsText={t('searchLocation.noOptionsText', { ns: 'field' })}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, state) => {
        if (state.inputValue.trim().length === 0) {
          return [];
        }
        return filterOptions(options, state);
      }}
      renderOption={(props, option, { inputValue }) => {
        const { key, ...optionProps } = props;
        const matches = match(option.label, inputValue, { insideWords: true });
        const parts = parse(option.label, matches);

        return (
          <Box
            {...props}
            component="li"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Box>
            <Typography variant="caption" sx={{ marginLeft: 'auto' }}>
              {t(`word.${option.type}`)}
            </Typography>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={selected.length ? '' : t('searchLocation.placeholder', { ns: 'field' })}
          slotProps={{
            input: {
              style: {
                width: '100%',
              },
              ...params.InputProps,
              autoComplete: 'off',
              type: 'search',
            },
          }}
        />
      )}
    />
  );
};
