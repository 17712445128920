import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@context';
import { Maker } from '@graphql';
import useStyles from './styles';

const MakerHighlight = () => {
  const { state, dispatch } = useAppContext();
  const { makers } = state;
  const maker = makers[0].makers[1];
  const theme = useTheme();
  const styles = useStyles(
    theme,
    maker?.covers?.medias[0]?.originalUrl.replace('1080', '1800') || 'https://picsum.photos/1080/700'
  );
  const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('spot');

  const TopTen = () => {
    const size = isScreenMedium ? theme.spacing(6) : theme.spacing(10);

    return (
      <Stack
        width={size}
        height={size}
        alignItems="center"
        justifyContent="center"
        bgcolor="primary.main"
        borderRadius={theme.spacing(1)}
      >
        <Typography fontSize={isScreenMedium ? '.5rem' : '1rem'} fontWeight={800}>
          Top
        </Typography>
        <Typography fontSize={isScreenMedium ? '.8rem' : '1.5rem'} fontWeight={800}>
          10
        </Typography>
      </Stack>
    );
  };

  const handleOpen = () => {
    dispatch({ type: 'SET_MAKER', payload: maker as Maker });
    setSearchParams({ type: 'maker', q: maker.slug, tab: 'preview' }, { preventScrollReset: true });
  };

  return (
    <Stack pt={0} justifyContent="center" pl={{ xs: 2, sm: 10 }} sx={styles.highlight}>
      <Stack
        width={isScreenMedium ? '100%' : '70%'}
        spacing={isScreenMedium ? 2 : 5}
        // mb={{ xs: 5, sm: 10, md: 15, lg: 40 }}
      >
        <Stack
          p={3}
          spacing={isScreenMedium ? 2 : 5}
          width={isScreenMedium ? '100%' : '50%'}
          sx={{
            borderRadius: theme.borderRadius,
            backgroundColor: alpha(theme.palette.background.default, 0.8),
          }}
        >
          <Typography variant="h3" fontWeight={800} sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}>
            Martin Solveig
            {/* {spot.name} */}
          </Typography>

          <Stack direction="row" spacing={2} alignItems={'center'}>
            <TopTen />
            <Stack>
              <Typography
                fontSize="1.3rem"
                component="h2"
                fontWeight={800}
                sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
              >
                N°7 des DJ
              </Typography>
              <Typography
                fontSize="1.3rem"
                component="h3"
                fontWeight={800}
                color="GrayText"
                sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
              >
                {/* {types} */}
                Electro
              </Typography>
            </Stack>
          </Stack>

          {!isScreenMedium && (
            // <Stack
            //   p={3}
            //   width={isScreenMedium ? '100%' : '50%'}
            //   sx={{
            //     borderRadius: theme.borderRadius,
            //     backgroundColor: alpha(theme.palette.background.default, 0.8),
            //   }}
            // >
            <Typography
              fontSize="1.1rem"
              // fontWeight="bold"
              // fontWeight={isScreenMedium ? 'bold' : 800}
              sx={{ color: 'white', textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
            >
              Martin Solveig est un DJ français de renommée mondiale, connu pour ses sets explosifs mêlant
              house, pop et électro. Son énergie sur scène, alliée à des hits comme "Hello" et des
              collaborations internationales, promet des soirées inoubliables. Prépare-toi à danser sans répit
              !{/* {spot.description?.split(' ').slice(0, 20).join(' ')}... */}
            </Typography>
            // </Stack>
          )}
          <Box width={isScreenMedium ? '100%' : '50%'}>
            <Button
              onClick={handleOpen}
              startIcon={<theme.icons.see />}
              variant="contained"
              color="primary"
              size={isScreenMedium ? 'small' : 'large'}
            >
              {t('see', { ns: 'cta' })}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MakerHighlight;
