import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import {
  BookingOccasionEnum,
  BookingStatusEnum,
  useUpdateBookingMutation,
  GetConsumerBookingQuery,
} from '@graphql';
import { useSession } from '@hooks';
import { shouldDisableBookingDate, shouldDisableBookingTime } from '@utils';
import validationSchema from './validationSchema';
import ConfirmCancel from './ConfirmCancel';
import ConifrmEdit from './ConifrmEdit';

interface EditConsumerFormProps {
  handleEdit: () => void;
  booking: GetConsumerBookingQuery['booking'];
}

export default function EditConsumerFormForm(props: EditConsumerFormProps) {
  const { booking, handleEdit } = props;
  const [isDiabled, setIsDisabled] = React.useState(true);
  const [dateValue, setDateValue] = React.useState<Moment>(moment(booking.startTime));
  const [timeValue, setTimeValue] = React.useState<Moment>(moment(booking.startTime));
  const theme = useTheme();
  const { account } = useSession();
  const { t } = useTranslation('booking');

  const [updateBooking] = useUpdateBookingMutation();

  const defaultValues = {
    startTime: moment(booking.startTime),
    participants: booking.participants.toString(),
    occasion: booking.occasion ?? '',
    status: booking.status,
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema(booking, t, account?.__typename),
    onSubmit: async (values) => {
      // only update the values that are different from the default ones
      const validValues = Object.assign(
        {},
        !moment(defaultValues.startTime).isSame(values.startTime) && {
          startTime: moment(values.startTime).toISOString(),
        },
        values.participants !== defaultValues.participants && { participants: values.participants },
        values.occasion !== defaultValues.occasion && { occasion: values.occasion },
        values.status !== defaultValues.status && { status: values.status }
      );

      console.log(`[{validValues}]:`, validValues);

      // updateBooking({
      //   variables: { input: { id: bookingId, ...validValues } },
      //   onCompleted() {
      //     handleEdit();
      //     toast.success(t('update.success'));
      //   },
      //   onError() {
      //     toast.error(t('update.error'));
      //   },
      // });
    },
  });

  const handleCancelBooking = async () => {
    await formik.setFieldValue('status', BookingStatusEnum.Canceled);
    formik.handleSubmit();
  };

  const handleConfirmEdit = async () => {
    formik.handleSubmit();
  };

  React.useEffect(() => {
    // handle modifications
    const isSameDate = moment(defaultValues.startTime).isSame(dateValue, 'day');
    const isSameHour = moment(defaultValues.startTime).isSame(timeValue, 'hour');
    const isSameMinute = moment(defaultValues.startTime).isSame(timeValue, 'minute');
    const isSameParticipants = defaultValues.participants == formik.values.participants;
    const isSameOccasion = defaultValues.occasion === formik.values.occasion;
    const isDisabled =
      !formik.dirty ||
      !formik.isValid ||
      (isSameDate && isSameHour && isSameMinute && isSameParticipants && isSameOccasion);
    setIsDisabled(isDisabled);
  }, [defaultValues, formik]);

  React.useEffect(() => {
    // set formik new date without changing the time
    formik.setFieldValue(
      'startTime',
      moment(dateValue).set({ hour: timeValue.hour(), minute: timeValue.minute() })
    );
  }, [dateValue]);

  React.useEffect(() => {
    // set formik new time without changing the date
    formik.setFieldValue(
      'startTime',
      moment(timeValue).set({ year: dateValue.year(), month: dateValue.month(), date: dateValue.date() })
    );
  }, [timeValue]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Stack width="100%" height="100%" justifyContent="space-between">
        <Stack
          spacing={2}
          width="100%"
          divider={<Divider sx={{ backgroundColor: theme.palette.grey[600] }} />}
        >
          <ConfirmCancel handleConfirm={handleCancelBooking} />
          <DateCalendar
            disablePast
            timezone="Europe/Paris"
            value={formik.values.startTime}
            onChange={(value) => {
              setDateValue(value);
            }}
            shouldDisableDate={(date) => {
              return shouldDisableBookingDate(date, booking.spot.opening, booking.spot.closing);
            }}
            sx={{ width: '100%' }}
          />
          <DesktopTimePicker
            label="Heure d'arrivée"
            disablePast
            closeOnSelect
            timezone="Europe/Paris"
            value={formik.values.startTime}
            ampm={false}
            onChange={(value) => {
              if (value) {
                setTimeValue(value);
              }
            }}
            shouldDisableTime={(date) => {
              return shouldDisableBookingTime(date, booking.spot.opening, booking.spot.closing);
            }}
          />
          <TextField
            {...formik.getFieldProps('participants')}
            required
            fullWidth
            variant="outlined"
            slotProps={{ input: { min: 1 } }}
            type="number"
            id="book-participants"
            name="participants"
            label={t('participants.label', { ns: 'field' })}
            onChange={(e) => {
              const value = Math.max(1, parseInt(e.target.value, 10));
              formik.setFieldValue('participants', isNaN(value) ? '' : value);
            }}
            error={formik.touched.participants && Boolean(formik.errors.participants)}
            helperText={
              formik.touched.participants && formik.errors.participants
                ? String(formik.errors.participants)
                : null
            }
          />
          <FormControl fullWidth>
            <InputLabel id="occasion-select-label">{t('occasion select.label', { ns: 'field' })}</InputLabel>
            <Select
              {...formik.getFieldProps('occasion')}
              labelId="occasion-select-label"
              name="occasion"
              label={t('occasion select.label', { ns: 'field' })}
              autoComplete="off"
              displayEmpty
              defaultValue=""
            >
              <MenuItem autoFocus value="">
                <em>{t('occasion select.placeholder', { ns: 'field' })}</em>
              </MenuItem>
              {Object.entries(BookingOccasionEnum).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {t(`suitable.${key.charAt(0).toLowerCase() + key.slice(1)}`, {
                    ns: 'spot',
                  })}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id="occasion-helper-text">{t('word.Optional', { ns: 'common' })}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>
      <Stack spacing={3} direction="row">
        <Button variant="contained" color="uncolored" onClick={handleEdit} fullWidth>
          {t('cancel', { ns: 'cta' })}
        </Button>
        <ConifrmEdit
          handleConfirm={handleConfirmEdit}
          disabled={isDiabled}
          startTime={formik.values.startTime}
          participants={+formik.values.participants}
          occasion={(formik.values.occasion as BookingOccasionEnum) ?? null}
        />
      </Stack>
    </form>
  );
}
