import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import Stack from '@mui/material/Stack';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .2)',
  border: `1px solid ${theme.palette.grey[900]}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .2)',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    color: theme.palette.text.secondary,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: 'rotate(90deg)',
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface FilterAccordionProps {
  handleChangePanel: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  expanded: string | boolean;
  panel: string;
  count: number;
  children?: React.ReactNode;
}

export const FilterAccordion = React.memo((props: FilterAccordionProps) => {
  const { expanded, panel, count, handleChangePanel, children } = props;

  return (
    <Accordion expanded={expanded === panel} onChange={handleChangePanel(panel)}>
      <AccordionSummary aria-controls="type-content" id="type-header">
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography variant="body2" component="span" color="textPrimary">
            {panel}
          </Typography>
          <Typography variant="body2" component="span" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
            {count} sélectionné(s)
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{expanded === panel ? children : null}</AccordionDetails>
    </Accordion>
  );
});
