import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import moment from 'moment';

import { SliderWrapper } from '@components';
import { ListHomeEventsQuery, ListHomeSpotsQuery } from '@graphql';
import { getTrueKeys, truncateText, getScheduler } from '@utils';
import { Slide, SlideProps } from './Slide';

interface SliderProps {
  handleInView: (index: number) => void;
  index: number;
  category: ListHomeEventsQuery['eventCategories'][0] | ListHomeSpotsQuery['homeSpots'][0];
  visibleCarousels: number[];
}

interface OpenChipProps {
  opening: ListHomeSpotsQuery['homeSpots'][0]['spots'][0]['opening'];
  closing: ListHomeSpotsQuery['homeSpots'][0]['spots'][0]['closing'];
}

interface OpenTextProps {
  opening: ListHomeSpotsQuery['homeSpots'][0]['spots'][0]['opening'];
  closing: ListHomeSpotsQuery['homeSpots'][0]['spots'][0]['closing'];
}

const Slider = (props: SliderProps) => {
  const { handleInView, index, category, visibleCarousels } = props;
  const [slides, setSlides] = React.useState<SlideProps[]>([]);
  const { t } = useTranslation('common');

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      handleInView(index);
    }
  }, [inView, index]);

  React.useEffect(() => {
    if (category.__typename === 'HomeSpots') {
      const items = category.spots.map((spot) => {
        return {
          id: spot.id,
          type: 'Spot' as 'Spot',
          to: `?s=${spot.slug}`,
          title: spot.name,
          covers: spot.covers,
          defaultImage: spot.configuration.primaryType ? spot.configuration.primaryType + '.jpg' : null,
          subtitleLeft: [
            spot.configuration.primaryType,
            spot.configuration.secondaryType,
            spot.configuration.tertiaryType,
          ]
            .filter(Boolean)
            .map((type) => t(`types.${type}.label`, { ns: 'spot' }))
            .join(' • '),
          subtitleRight: spot.address.city,
          textBottomLeft: getTrueKeys(spot.atmosphere)
            .map((key) => t(`atmosphere.${key}`, { ns: 'spot' }))
            .join(', '),
          textBottomRight: '',
          subTextBottomLeft: '',
          subTextBottomRight: '',
          SubBottomLeft: <OpenChip opening={spot.opening} closing={spot.closing} />,
          SubBottomRight: <OpenText opening={spot.opening} closing={spot.closing} />,
        };
      });
      setSlides(items);
    } else if (category.__typename === 'EventCategory') {
      const items = category.events.map((event) => ({
        id: event.id,
        type: 'Event' as 'Event',
        to: `?e=${event.id}`,
        covers: event.covers,
        title: event.title,
        subtitleLeft: moment(event.from).format('dd DD MMM'),
        subtitleRight: moment(event.from).format('[à] HH[h]mm'),
        textBottomLeft: event.spot?.name || '',
        textBottomRight: '',
        subTextBottomLeft: t('category.' + event.category?.name, { ns: 'event' }),
        subTextBottomRight: event.spot?.address?.city || event.address?.city || t('word.unknown city'),
      }));
      setSlides(items);
    }
  }, [category]);

  return (
    <Stack ref={ref}>
      {visibleCarousels.includes(index) ? (
        <Stack width="100%" justifyContent="space-between" spacing={1}>
          <Typography
            pl={{ xs: 2, sm: 3, md: 10 }}
            variant="h5"
            fontWeight={'bold'}
            fontSize={{ xs: '1rem', sm: '1.1rem', md: '1.6rem' }}
          >
            {category.__typename === 'HomeSpots'
              ? category.title
              : t('category.' + category.name, { ns: 'event' })}
          </Typography>
          <SliderWrapper>
            {slides.map((slide) => (
              <Slide key={slide.id} {...slide} />
            ))}
          </SliderWrapper>
        </Stack>
      ) : (
        <Stack width="100%" justifyContent="space-between" spacing={1}>
          <Typography
            pl={{ xs: 2, sm: 3, md: 10 }}
            variant="h5"
            fontWeight={'bold'}
            fontSize={{ xs: '.9rem', sm: '1rem', md: '1.2rem' }}
          >
            <Skeleton variant="rounded" width={200} />
          </Typography>
          <SliderWrapper>
            {[...Array(20)].map((_, idx) => (
              <Stack
                key={idx}
                height={{ xs: '150px', sm: '200px', md: '250px', lg: '300px', xl: '350px' }}
                sx={{ aspectRatio: '16/9', flex: 1 }}
              >
                <Skeleton variant="rounded" width="100%" height="100%" />
              </Stack>
            ))}
          </SliderWrapper>
        </Stack>
      )}
    </Stack>
  );
};

const OpenChip = (props: OpenChipProps) => {
  const { opening, closing } = props;
  const { t } = useTranslation('spot');

  const scheduler = getScheduler(opening, closing);

  if (scheduler.isOpen !== null) {
    let label = '';
    let variant = '';
    let color = '';

    if (scheduler.isOpen) {
      label = t(`schedules.${scheduler.isClosingSoon ? 'closes soon' : 'open'}`);
      color = scheduler.isClosingSoon ? 'warning' : 'success';
      variant = scheduler.isClosingSoon ? 'outlined' : 'contained';
    } else {
      label = t(`schedules.${scheduler.isOpeningSoon ? 'opens soon' : 'closed'}`);
      color = scheduler.isOpeningSoon ? 'warning' : 'inherit';
      variant = 'outlined';
    }

    return <Chip size="small" label={label} variant={variant} color={color} />;
  } else {
    return (
      <Typography variant="body2" fontSize=".8rem">
        {truncateText('', 35)}
      </Typography>
    );
  }
};

const OpenText = (props: OpenTextProps) => {
  const { opening, closing } = props;
  const { t } = useTranslation('spot');

  const scheduler = getScheduler(opening, closing);

  let label = '';

  if (scheduler.isOpen !== null) {
    if (scheduler.isOpen) {
      if (scheduler.nextClosing) {
        label = t('schedules.closes') + ' ' + scheduler.nextClosing.fromNow();
      }
    } else {
      if (scheduler.nextOpening) {
        if (scheduler.nextOpening.diff(moment(), 'hours') < 3) {
          label = t(`schedules.opens`) + ' ' + scheduler.nextOpening.fromNow();
        } else if (moment().isSame(scheduler.nextOpening, 'day')) {
          label = t(`schedules.opens at`, { time: scheduler.nextOpening.format('HH:mm') });
        } else {
          label = t(`schedules.opens`) + ' ' + scheduler.nextOpening.fromNow();
        }
      }
    }

    return (
      <Typography variant="body2" fontSize=".8rem">
        {truncateText(label, 35)}
      </Typography>
    );
  } else {
    return <></>;
  }
};

export default Slider;
