import * as React from 'react';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import { useSession } from '@hooks';
import {
  useListMyBookingsLazyQuery,
  useListMyEventsLazyQuery,
  ListMyBookingsQuery,
  ListMyEventsQuery,
} from '@graphql';
import BookingSlot from './BookingSlot';
import EventSlot from './EventSlot';

interface MobileCalendarProps {
  accountId: string;
  variant: 'all' | 'booking' | 'event';
}

type ToDisplay = ListMyBookingsQuery['myBookings'] | ListMyEventsQuery['myEvents'];

export function MobileCalendar(props: MobileCalendarProps) {
  const { accountId, variant } = props;
  const theme = useTheme();
  const { account } = useSession();
  const [value, setValue] = React.useState<Moment | null>(moment());
  const [bookings, setBookings] = React.useState<ListMyBookingsQuery['myBookings']>([]);
  const [events, setEvents] = React.useState<ListMyEventsQuery['myEvents']>([]);
  const [toDisplay, setToDisplay] = React.useState<ToDisplay>([]);
  const [highlightedDays, setHighlightedDays] = React.useState<number[]>([]);
  const { t } = useTranslation('common');

  const [getMyBookings, { loading: bookingsLoading }] = useListMyBookingsLazyQuery({
    onCompleted({ myBookings }) {
      setBookings(myBookings);
      // Highlight the days with bookings
      const bookingDays = myBookings.map((booking) => moment(booking.startTime).get('date'));
      // Display the bookings of the current day
      const matchingBookings = myBookings.filter((b) => moment(b.startTime).isSame(value, 'day'));
      // update the toDisplay state with the matching bookings & events
      // update highlightedDays state with the matching bookings
      if (variant === 'all') {
        setHighlightedDays([...new Set(highlightedDays.concat(bookingDays))]);
        setToDisplay([
          ...toDisplay.filter((td) => td.__typename === 'Event'),
          ...matchingBookings,
        ] as ToDisplay);
      } else {
        setHighlightedDays([...new Set(bookingDays)]);
        setToDisplay(matchingBookings);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getMyEvents, { loading: eventsLoading }] = useListMyEventsLazyQuery({
    onCompleted({ myEvents }) {
      setEvents(myEvents);
      // Highlight the days with bookings
      const eventDays = myEvents.map((event) => moment(event.from).get('date'));
      // Display the bookings of the current day
      const matchingEvents = myEvents.filter((e) => moment(e.from).isSame(value, 'day'));
      // update the toDisplay state with the matching bookings & events
      // update highlightedDays state with the matching events
      if (variant === 'all') {
        setHighlightedDays([...new Set(highlightedDays.concat(eventDays))]);
        setToDisplay([
          ...toDisplay.filter((td) => td.__typename === 'Booking'),
          ...matchingEvents,
        ] as ToDisplay);
      } else {
        setHighlightedDays([...new Set(eventDays)]);
        setToDisplay(matchingEvents);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const RenderDay = (props: PickersDayProps<Moment> & { highlightedDays?: number[] }) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        color="info"
        overlap="circular"
        badgeContent={isSelected ? ' ' : undefined}
        variant={isSelected ? 'dot' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  };

  const handleMonthChange = (date: Moment) => {
    setHighlightedDays([]);

    if (['all', 'booking'].includes(variant) && accountId === account?.id) {
      getMyBookings({
        variables: {
          dateRange: {
            from: date.startOf('month').toISOString(),
            to: date.endOf('month').toISOString(),
          },
        },
      });
    }
    if (['all', 'event'].includes(variant) && accountId === account?.id) {
      getMyEvents({
        variables: {
          dateRange: {
            from: date.startOf('month').toISOString(),
            to: date.endOf('month').toISOString(),
          },
        },
      });
    }
  };

  const handleDateClick = (date: Moment) => {
    setValue(date);

    const matchingBookings = bookings.filter((b) => moment(b.startTime).isSame(date, 'day'));
    const matchingEvents = events.filter((e) => moment(e.from).isSame(date, 'day'));

    if (variant === 'all') {
      setToDisplay([...matchingBookings, ...matchingEvents] as ToDisplay);
    } else if (variant === 'booking') {
      setToDisplay(matchingBookings);
    } else if (variant === 'event') {
      setToDisplay(matchingEvents);
    }
  };

  // initial API calls
  React.useEffect(() => {
    if (['all', 'booking'].includes(variant) && accountId === account?.id) {
      getMyBookings({
        variables: {
          dateRange: {
            from: moment().startOf('month').toISOString(),
            to: moment().endOf('month').toISOString(),
          },
        },
      });
    }
    if (['all', 'event'].includes(variant) && accountId === account?.id) {
      getMyEvents({
        variables: {
          dateRange: {
            from: moment().startOf('month').toISOString(),
            to: moment().endOf('month').toISOString(),
          },
        },
      });
    }
  }, [variant]);

  return (
    <Stack spacing={3} p={0} m={0}>
      <DateCalendar
        loading={bookingsLoading || eventsLoading}
        renderLoading={() => <CircularProgress />}
        showDaysOutsideCurrentMonth
        displayWeekNumber
        onMonthChange={handleMonthChange}
        value={value}
        onChange={handleDateClick}
        sx={{ width: '100%' }}
        slots={{
          day: RenderDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
      />
      <Divider sx={{ backgroundColor: theme.palette.grey[600] }} />
      {toDisplay.length ? (
        <Stack spacing={1} id="selected-account-event-date">
          {toDisplay.map((data) => {
            if (data.__typename === 'Booking') {
              return <BookingSlot key={data.id} booking={data} />;
            } else if (data.__typename === 'Event') {
              return <EventSlot key={data.id} event={data} />;
            }
          })}
        </Stack>
      ) : (
        <Stack
          p={3}
          sx={{ backgroundColor: theme.palette.background.paper, borderRadius: theme.borderRadius }}
        >
          <Typography fontSize={{ xs: '.7rem', sm: '.8rem' }}>{t('calendar.Nothing this day')}</Typography>
        </Stack>
      )}
    </Stack>
  );
}
