import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as Device from 'react-device-detect';
import Divider from '@mui/material/Divider';
import { Outlet } from 'react-router-dom';

import { TriStateCheckbox, SortSelect, AccountTypeSelect } from './Search';
import { ResultAutocomplete } from './Autocomplete';
import { SupportAccountOverview } from './display';
import { Selected } from './types';

export function SupportAccountsdPage() {
  const theme = useTheme();
  const [selected, setSelected] = React.useState<Selected | null>(null);

  if (Device.isMobile) {
    return (
      <Stack spacing={1} maxWidth="100%" p={2}>
        {/* <AccountTypeCounts />
        <LastTen /> */}
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={2} p={2} height="100%">
      <Stack spacing={2} width="35%" height="calc(100vh - 100px)" sx={{ overflowY: 'auto' }}>
        {/* SEARCH FILTERS */}
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" sx={{ backgroundColor: theme.palette.grey[800] }} />}
          spacing={2}
        >
          <TriStateCheckbox searchKey="active" label="actif" />
          <TriStateCheckbox searchKey="verified" label="vérifié" />
          <TriStateCheckbox searchKey="private" label="privé" />
          <TriStateCheckbox searchKey="delete" label="supprimé" />
        </Stack>
        <Stack spacing={2}>
          <ResultAutocomplete />
        </Stack>
        <Stack direction="row" spacing={2}>
          <AccountTypeSelect />
          <SortSelect />
        </Stack>

        <SupportAccountOverview />
      </Stack>

      {/* ACCOUNT DISPLAY */}
      <Stack
        width="65%"
        height="calc(100vh - 100px)"
        sx={{ backgroundColor: theme.palette.background.paper, overflowY: 'auto' }}
      >
        <Outlet />
      </Stack>
    </Stack>
  );
}
