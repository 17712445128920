import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';

import { useSession } from '@hooks';
import { useReSendActivationMailMutation } from '@graphql';
import { truncateText, anonymizeEmail } from '@utils';
import { useTranslation } from 'react-i18next';

export default function ActivateReminder() {
  const { user } = useSession();
  const { t } = useTranslation('cta');

  if (!user || user?.isVerified === true) {
    return null;
  }

  // disable resend email button for 5 minutes
  const isResendable = moment().diff(moment(user?.emailTokenSentAt), 'minutes') >= 5;

  const [resendActivationMail] = useReSendActivationMailMutation();

  return (
    <>
      <ListItem>
        <Alert icon={false} severity="warning">
          <Typography variant="caption">
            {t('activation reminder', {
              ns: 'authentication',
              time: moment(user?.emailTokenSentAt).fromNow(),
            })}
          </Typography>
          <Typography variant="caption" fontWeight="bold">
            {truncateText(anonymizeEmail(user?.email), 30)}{' '}
          </Typography>
        </Alert>
      </ListItem>
      {isResendable && (
        <ListItem>
          <Button fullWidth variant="contained" color="uncolored" size="small" onClick={resendActivationMail}>
            {t('send back the email')}
          </Button>
        </ListItem>
      )}
    </>
  );
}
