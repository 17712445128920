import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetEventDetailedQuery } from '@graphql';
import EventSkeleton from './EventSkeleton';
import { Description, FAQ, FindUs, Tags } from '../common';
import Header from './Header';
import Preview from './preview';
import Tickets from './Tickets';

interface EventDialogProps {
  handleBack: (e: React.SyntheticEvent, reason: string) => void;
}

const EventDialog = (props: EventDialogProps) => {
  const ticketsRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { t } = useTranslation('event');

  const padding = isMobile ? 1 : 3;

  const { data, loading } = useGetEventDetailedQuery({
    skip: !searchParams.get('e') && !params.id,
    fetchPolicy: 'cache-and-network',
    variables: { id: searchParams.get('e') || params.id || '' },
  });

  const scrollToTickets = () => {
    if (ticketsRef.current) {
      ticketsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (loading) {
    return <EventSkeleton />;
  }

  if (!data?.event) {
    return (
      <Stack p={5} spacing={3} justifyContent="center" alignItems="center">
        <Typography variant="h6">{t('text.Unable to find Event')}</Typography>
        <Button variant="contained" color="primary" onClick={props.handleBack}>
          {t('back', { ns: 'cta' })}
        </Button>
      </Stack>
    );
  }

  const { event } = data;

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack sx={{ position: 'relative' }}>
        <Header handleBack={props.handleBack} covers={event.covers} />
      </Stack>
      <Stack spacing={5} p={padding}>
        <Preview event={event} scrollToTickets={scrollToTickets} />
      </Stack>
      <Stack spacing={5} p={padding}>
        <Description description={event.description} />
        <Tickets tickets={event.tickets} ticketsRef={ticketsRef} />
        <FindUs placeId={event.address?.placeId} />
        <FAQ FAQs={event.FAQs} />
        <Tags tags={event.tags} />
      </Stack>
    </Stack>
  );
};

export default React.memo(EventDialog);
