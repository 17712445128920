import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { BookingStatusEnum, ListMyBookingsQuery } from '@graphql';
import { useSession } from '@hooks';

interface BookingSlotProps {
  booking: ListMyBookingsQuery['myBookings'][0];
}

function BookingSlot(props: BookingSlotProps) {
  const { booking } = props;
  const { account } = useSession();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation('booking');

  const statusColor = {
    [BookingStatusEnum.Accepted]: 'success',
    [BookingStatusEnum.Canceled]: 'error',
    [BookingStatusEnum.NoShow]: 'error',
    [BookingStatusEnum.Pending]: 'warning',
    [BookingStatusEnum.Rejected]: 'error',
  };

  let name = '';
  let avatarUrl = '';

  if (account?.__typename === 'Spot') {
    if (booking.client.__typename === 'BookingClient') {
      const { firstname, lastname } = booking.client;
      name = `${firstname} ${lastname ?? ''}`.trim();
    } else if (booking.client.__typename === 'Consumer') {
      const { firstname, lastname } = booking.client.user;
      name = `${firstname} ${lastname}.`;
      avatarUrl = booking.client.avatar?.url ?? '';
    }
  } else {
    name = booking.spot.name;
    avatarUrl = booking.spot.avatar?.url ?? '';
  }

  const handleClick = () => {
    navigate(`/me/calendar/booking/${booking.id}`);
  };

  return (
    <Stack
      onClick={handleClick}
      spacing={2}
      p={2}
      justifyContent="space-between"
      divider={<Divider sx={{ backgroundColor: theme.palette.grey[600] }} />}
      flex={1}
      sx={{
        borderRadius: theme.borderRadius,
        backgroundColor: theme.palette.grey[900],
      }}
    >
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar src={avatarUrl} sx={{ width: 30, height: 30 }}>
            {name[0]}
          </Avatar>
          <Typography variant="body2" fontWeight="bolder">
            {name}
          </Typography>
        </Stack>
        <Chip color={statusColor[booking.status]} label={t(`status.${booking.status}`)} size="small" />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Typography variant="body2">Heure: {moment(booking.startTime).format('hh:mm')}</Typography>
        <Typography variant="body2">x{booking.participants}pers</Typography>
      </Stack>
    </Stack>
  );
}

export default React.memo(BookingSlot);
