import * as React from 'react';
import { useTheme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  useListNotificationsQuery,
  NotificationActionEnum,
  NotificationTypeEnum,
  useMarkNotificationAsReadMutation,
} from '@graphql';
import { truncateText } from '@utils';
import { useSession } from '@hooks';
import { previewBookingVar } from '@context';

interface NotificationDrawerProps {
  handleOpenDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

export function NotificationDrawer(props: NotificationDrawerProps) {
  const { handleOpenDialog } = props;
  const theme = useTheme();
  const { account } = useSession();
  const { t } = useTranslation('notification');
  const navigate = useNavigate();

  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();

  const { data } = useListNotificationsQuery({
    skip: !account,
  });

  if (!account) {
    return null;
  }

  const iconByType = {
    [NotificationTypeEnum.Booking]: <theme.icons.booking />,
    EVENT: <theme.icons.event />,
  };
  const messageByTypeAction = {
    [NotificationTypeEnum.Booking]: {
      [NotificationActionEnum.Request]: 'Nouvelle demande de réservation',
      [NotificationActionEnum.Create]: '',
      [NotificationActionEnum.Update]: '',
      [NotificationActionEnum.Delete]: '',
    },
  };

  const redirectLinkByTypeAction = {
    [NotificationTypeEnum.Booking]: {
      [NotificationActionEnum.Request]: '/spot/booking/calendar/',
      [NotificationActionEnum.Create]: '',
      [NotificationActionEnum.Update]: '',
      [NotificationActionEnum.Delete]: '',
    },
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const notificationId = event.currentTarget.getAttribute('data-id');

    if (notificationId) {
      const notification = data?.notifications.find((n) => n.id === notificationId);

      if (notification) {
        if (notification.type === NotificationTypeEnum.Booking) {
          previewBookingVar(notification.booking.id);
        }
      }

      markNotificationAsRead({ variables: { notificationId } });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: 300,
          color: theme.palette.text.secondary,
          '& .MuiListItemButton-root': {
            color: theme.palette.text.secondary,
            '& .MuiListItemIcon-root, & .MuiListItemText-root': {
              color: theme.palette.text.secondary,
            },
            '&:hover': {
              color: theme.palette.text.primary,
              '& .MuiListItemIcon-root, & .MuiListItemText-root': {
                color: theme.palette.text.primary,
              },
            },
          },
          '& .MuiDivider-root': {
            backgroundColor: theme.palette.grey[700],
          },
        }}
        role="presentation"
      >
        <List
          subheader={
            <ListSubheader component="div" id="messages-subheader">
              <Typography variant="h6" fontWeight="bold" mt={2}>
                {t('Notifications')}
              </Typography>
            </ListSubheader>
          }
        >
          <Stack spacing={0} divider={<Divider sx={{ backgroundColor: theme.palette.grey[800] }} flexItem />}>
            {data?.notifications.length ? (
              data?.notifications.map((notification) => (
                <Stack
                  key={notification.id}
                  spacing={1}
                  data-id="notification"
                  notification-id={notification.id}
                >
                  <ListItem
                    disablePadding
                    sx={{ backgroundColor: notification.isRead ? 'inherit' : alpha('#faff00', 0.1) }}
                  >
                    <ListItemButton width="100%" onClick={handleClick} data-id={notification.id}>
                      <Stack direction="row" spacing={1} alignItems="center" width="100%">
                        {iconByType[notification.type] || <theme.icons.notification />}
                        <Stack width="100%" spacing={0.5}>
                          <Typography variant="body2" fontWeight={notification.isRead ? 'normal' : 'bold'}>
                            {messageByTypeAction[notification.type][notification.action]}
                          </Typography>
                          {notification.source && (
                            <Stack direction="row" spacing={1}>
                              <Avatar
                                src={notification.source.avatar?.url}
                                alt="avatar of the source"
                                sx={{ width: 20, height: 20 }}
                              >
                                {notification.source.name[0] || ''}
                              </Avatar>
                              <Typography variant="subtitle2">{notification.source.name}</Typography>
                            </Stack>
                          )}
                          <Typography variant="caption">
                            {moment(notification.createdAt.createdAt).fromNow()}
                          </Typography>
                        </Stack>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                </Stack>
              ))
            ) : (
              <Stack>
                <ListItem disablePadding>
                  <ListItemButton width="100%" disabled>
                    <Stack direction="row" spacing={1} alignItems="center" width="100%">
                      <Typography variant="body2" fontWeight="bold">
                        {t('No notification')}
                      </Typography>
                    </Stack>
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" flexItem />
              </Stack>
            )}
          </Stack>
        </List>
      </Box>
    </>
  );
}

function formatLastMessage(sender: string, message: string, isMe = true) {
  const senderText = isMe ? 'moi' : truncateText(sender, 10);
  return (
    <>
      <span style={{ fontWeight: isMe ? 'normal' : '500' }}>{senderText}</span>
      {` : ${truncateText(message, 50)}`}
    </>
  );
}