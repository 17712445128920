import * as React from 'react';
import { useParams } from 'react-router-dom';

import { previewBookingVar } from '@context';

export function MyEventDetailPage() {
  const { eventId = null } = useParams();

  React.useEffect(() => {
    previewBookingVar(eventId);
  }, [eventId]);

  return null;
}
