import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import MuiAvatar from '@mui/material/Avatar';

import { AvatarUploadButton } from '@components';
import { Account } from '@graphql';

interface AvatarProps {
  account: Account;
}

export function Avatar(props: AvatarProps) {
  const { account } = props;
  const theme = useTheme();

  if (!account) {
    return null;
  }

  return (
    <Stack alignItems="center">
      <MuiAvatar
        src={account.avatar?.url}
        sx={{
          width: 100,
          height: 100,
          border: `1px solid ${theme.palette.grey[800]}`,
          padding: 0.3,
        }}
      />
      <AvatarUploadButton />
    </Stack>
  );
}
