import * as React from 'react';
import { Theme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  useSupdateSpotTypeMutation,
  UpdateSpotTypeInput,
  useSupdateSpotTypesMutation,
  Spot,
  SpotTypeEnum,
} from '@graphql';
import { useToastError } from '@hooks';
import { TriStateCheckbox } from '../common';

interface SpotTypeProps {
  account: Spot;
}
interface SpotTypesProps {
  account: Spot;
}

export function SpotType(props: SpotTypeProps) {
  const { account } = props;
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateSpotTypeMutation] = useSupdateSpotTypeMutation();

  const initialValues = React.useMemo(() => {
    return Object.entries(account.type)
      .filter(([key, value]) => !['__typename', 'id', 'updatedAt'].includes(key))
      .reduce((acc, [key, value]) => {
        acc[key as keyof UpdateSpotTypeInput] = value;
        return acc;
      }, {} as Record<keyof UpdateSpotTypeInput, boolean | null>);
  }, [account.type]);

  const validationSchema = React.useMemo(() => {
    return yup.object(
      Object.keys(account.type)
        .filter((key) => !['__typename', 'id', 'updatedAt'].includes(key))
        .reduce((acc, key) => {
          acc[key as keyof UpdateSpotTypeInput] = yup.boolean().nullable().defined();
          return acc;
        }, {} as Record<keyof UpdateSpotTypeInput, yup.BooleanSchema<boolean | null>>)
    );
  }, [account.type]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      if (formik.dirty) {
        updateSpotTypeMutation({
          variables: { id: account.id, input: values },
          onCompleted: ({ supdateSpotType }) => {
            setTimeoutId(null);
            formik.resetForm({ values });
            toast(t('spotType.toast.success', { ns: 'field' }), { type: 'success' });
          },
          onError: toastError,
        });
      }
    },
  });

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    if (formik.dirty && formik.isValid) {
      const newTmoutId = setTimeout(formik.submitForm, 1000);
      setTimeoutId(newTmoutId);
    }
  }, [formik.dirty, formik.isValid, formik.values]);

  if (account?.__typename !== 'Spot') {
    return null;
  }

  return (
    <Stack width="100%" spacing={3}>
      <Stack
        direction="row"
        // width="100%"
        justifyContent="space-between"
        sx={{
          p: 3,
          borderRadius: (theme: Theme) => theme.borderRadius,
          backgroundColor: alpha('#fff', 0.1),
        }}
      >
        <Stack>
          <Typography fontWeight="bold">{t('spotType.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotType.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        divider={<Divider sx={{ backgroundColor: (theme: Theme) => theme.palette.grey[800] }} />}
      >
        {Object.keys(account.type)
          .filter((key) => !['__typename', 'id', 'updatedAt'].includes(key))
          .map((key) => (
            <TriStateCheckbox
              key={key}
              label={t(`type.${key}`, { ns: 'spot' })}
              dataKey={key}
              formik={formik}
            />
          ))}
      </Stack>
      <Divider />
      <SpotTypes account={account} />
    </Stack>
  );
}

export function SpotTypes(props: SpotTypesProps) {
  const { account } = props;
  const toastError = useToastError();
  const { t } = useTranslation('field');

  const [updateSpotTypesMutation] = useSupdateSpotTypesMutation();

  const initialValues = {
    primaryType: account.configuration.primaryType ?? '',
    secondaryType: account.configuration.secondaryType ?? '',
    tertiaryType: account.configuration.tertiaryType ?? '',
  };

  const validationSchema = yup.object({
    primaryType: yup
      .string()
      .oneOf([...Object.values(SpotTypeEnum), ''])
      .nullable(),
    secondaryType: yup
      .string()
      .oneOf([...Object.values(SpotTypeEnum), ''])
      .nullable(),
    tertiaryType: yup
      .string()
      .oneOf([...Object.values(SpotTypeEnum), ''])
      .nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      if (formik.dirty && formik.isValid) {
        updateSpotTypesMutation({
          variables: {
            id: account.id,
            input: {
              primaryType: values.primaryType.length ? (values.primaryType as SpotTypeEnum) : null,
              secondaryType: values.secondaryType.length ? (values.secondaryType as SpotTypeEnum) : null,
              tertiaryType: values.tertiaryType.length ? (values.tertiaryType as SpotTypeEnum) : null,
            },
          },
          onCompleted: ({ supdateSpotTypes }) => {
            formik.resetForm({ values });
            toast(t('spotType.toast.success'), { type: 'success' });
          },
          onError: toastError,
        });
      }
    },
  });

  if (account?.__typename !== 'Spot') {
    return null;
  }

  // Group the types dynamically
  const groupedTypes = Object.values(SpotTypeEnum).reduce((groups, type) => {
    const [prefix] = type.split('_'); // Extract the prefix
    if (!groups[prefix]) {
      groups[prefix] = [];
    }
    groups[prefix].push(type);
    return groups;
  }, {} as Record<string, string[]>);

  const handleChangePrimary = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    const oldValue = formik.values.primaryType;
    formik.setFieldValue('primaryType', newValue);

    if (newValue === formik.values.secondaryType) {
      formik.setFieldValue('secondaryType', oldValue);
    } else if (newValue === formik.values.tertiaryType) {
      formik.setFieldValue('tertiaryType', oldValue);
    }

    formik.submitForm();
  };

  const handleChangeSecondary = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    const oldValue = formik.values.secondaryType;
    formik.setFieldValue('secondaryType', newValue);

    if (newValue === formik.values.primaryType) {
      formik.setFieldValue('primaryType', oldValue);
    } else if (newValue === formik.values.tertiaryType) {
      formik.setFieldValue('tertiaryType', oldValue);
    }

    formik.submitForm();
  };

  const handleChangeTertiary = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    const oldValue = formik.values.tertiaryType;
    formik.setFieldValue('tertiaryType', newValue);

    if (newValue === formik.values.primaryType) {
      formik.setFieldValue('primaryType', oldValue);
    } else if (newValue === formik.values.secondaryType) {
      formik.setFieldValue('secondaryType', oldValue);
    }

    formik.submitForm();
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack
        width="100%"
        spacing={3}
        divider={<Divider sx={{ backgroundColor: (theme: Theme) => theme.palette.grey[800] }} />}
      >
        {/* PRIMARY TYPE */}
        <FormControl fullWidth>
          <InputLabel id="primary-type-select-label">Type primaire</InputLabel>
          <Select
            id="primary-type-select"
            labelId="primary-type-select-label"
            label="Type primaire"
            autoWidth
            value={formik.values.primaryType}
            onChange={handleChangePrimary}
          >
            {!formik.values.secondaryType?.length && (
              <MenuItem value="">
                <em>Aucun</em>
              </MenuItem>
            )}
            {Object.entries(groupedTypes).flatMap(([group, types]) => [
              <ListSubheader
                key={`header-${group}`}
                sx={{
                  backgroundColor: (theme: Theme) => theme.palette.uncolored.dark,
                  color: (theme: Theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {group}
              </ListSubheader>,
              ...types.map((type) => (
                <MenuItem key={type} value={type}>
                  <ListItemText
                    primary={t(`types.${type}.label`, { ns: 'spot' })}
                    secondary={t(`types.${type}.description`, { ns: 'spot' })}
                  />
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>

        {/* SECONDARY TYPE */}
        <FormControl fullWidth>
          <InputLabel id="secondary-type-select-label">Type secondaire</InputLabel>
          <Select
            id="secondary-type-select"
            labelId="secondary-type-select-label"
            label="Type secondaire"
            autoWidth
            value={formik.values.secondaryType}
            disabled={!formik.values.primaryType?.length}
            onChange={handleChangeSecondary}
          >
            {!formik.values.tertiaryType?.length && (
              <MenuItem value="">
                <em>Aucun</em>
              </MenuItem>
            )}
            {Object.entries(groupedTypes).flatMap(([group, types]) => [
              <ListSubheader
                key={`header-${group}`}
                sx={{
                  backgroundColor: (theme: Theme) => theme.palette.uncolored.dark,
                  color: (theme: Theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {group}
              </ListSubheader>,
              ...types.map((type) => (
                <MenuItem key={type} value={type}>
                  <ListItemText
                    primary={t(`types.${type}.label`, { ns: 'spot' })}
                    secondary={t(`types.${type}.description`, { ns: 'spot' })}
                  />
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>

        {/* TERTIARY TYPE */}
        <FormControl fullWidth>
          <InputLabel id="tertiary-type-select-label">Type tertiaire</InputLabel>
          <Select
            id="tertiary-type-select"
            labelId="tertiary-type-select-label"
            label="Type tertiaire"
            autoWidth
            value={formik.values.tertiaryType}
            disabled={!formik.values.primaryType?.length || !formik.values.secondaryType?.length}
            onChange={handleChangeTertiary}
          >
            <MenuItem value="">
              <em>Aucun</em>
            </MenuItem>
            {Object.entries(groupedTypes).flatMap(([group, types]) => [
              <ListSubheader
                key={`header-${group}`}
                sx={{
                  backgroundColor: (theme: Theme) => theme.palette.uncolored.dark,
                  color: (theme: Theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {group}
              </ListSubheader>,
              ...types.map((type) => (
                <MenuItem key={type} value={type}>
                  <ListItemText
                    primary={t(`types.${type}.label`, { ns: 'spot' })}
                    secondary={t(`types.${type}.description`, { ns: 'spot' })}
                  />
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>
      </Stack>
    </form>
  );
}