import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useToastError } from '@hooks';
import { Account, useRemoveImageMutation, ImageEntityTypeEnum } from '@graphql';

interface RemoveAvatarProps {
  account: Account;
  imageId?: string | null;
}

export function RemoveAvatar(props: RemoveAvatarProps) {
  const { account, imageId } = props;
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [removeImage] = useRemoveImageMutation({
    onCompleted() {
      toast(t('removeImage.toast.success', { ns: 'field' }), { type: 'success' });
    },
    onError: toastError,
    update(cache) {
      cache.evict({ id: `AccountImage:${imageId}` });
      cache.modify({
        id: `${account.__typename}:${account.id}`,
        fields: {
          avatar(existingImageRefs, { readField }) {
            return null;
          },
        },
      });
    },
  });

  const handleClick = (key: string) => {
    if (account.id && imageId) {
      removeImage({
        variables: {
          id: account.id,
          input: {
            id: imageId,
            type: ImageEntityTypeEnum.Account,
          },
        },
      });
    }
  };

  if (!imageId) {
    return null;
  }

  return (
    <Button variant="outlined" color="error" onClick={handleClick}>
      {t('remove')}
    </Button>
  );
}

export function RemoveCover(props: RemoveAvatarProps) {
  const { account, imageId } = props;
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [removeImage] = useRemoveImageMutation({
    onCompleted() {
      toast(t('removeImage.toast.success', { ns: 'field' }), { type: 'success' });
    },
    onError: toastError,
    update(cache) {
      cache.evict({ id: `AccountImage:${imageId}` });
      cache.modify({
        id: `${account.__typename}:${account.id}`,
        fields: {
          covers(existingImageRefs, { readField }) {
            return existingImageRefs.filter((ref: any) => imageId !== readField('id', ref));
          },
        },
      });
    },
  });

  const handleClick = (key: string) => {
    if (account.id && imageId) {
      removeImage({
        variables: {
          id: account.id,
          input: {
            id: imageId,
            type: ImageEntityTypeEnum.Account,
          },
        },
      });
    }
  };

  if (!imageId) {
    return null;
  }

  return (
    <Button variant="outlined" color="error" onClick={handleClick}>
      {t('remove')}
    </Button>
  );
}
