import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { StatCardsProps } from './types';

export default function StatCards(props: StatCardsProps) {
  const { events = [], following = [], friendships } = props.consumer;
  const theme = useTheme();
  const { account } = useSession();
  const { t } = useTranslation('cta');

  if (!props.consumer) {
    return null;
  }

  return (
    <Stack spacing={3} px={2} width="100%">
      <Stack spacing={1} direction="row" width="100%" justifyContent="space-between">
        <Stack
          alignItems="center"
          width="100%"
          py={2}
          sx={{ backgroundColor: theme.palette.background.paper, borderRadius: theme.borderRadius }}
        >
          <Typography variant="body1" color="textPrimary" fontWeight="bold">
            {getCount(events.length)}
          </Typography>
          <Typography variant="body1" color="textSecondary" fontSize={{ xs: '.7rem', sm: '.8rem' }}>
            {`event${events.length > 1 ? 's' : ''}`}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          width="100%"
          py={2}
          sx={{ backgroundColor: theme.palette.background.paper, borderRadius: theme.borderRadius }}
        >
          <Typography variant="body1" color="textPrimary" fontWeight="bold">
            {getCount(following.length)}
          </Typography>
          <Typography variant="body1" color="textSecondary" fontSize={{ xs: '.7rem', sm: '.8rem' }}>
            {`spot${following.length > 1 ? 's' : ''}`}
          </Typography>
        </Stack>
        {/* <Stack
          alignItems="center"
          width="100%"
          py={2}
          sx={{ backgroundColor: theme.palette.background.paper, borderRadius: theme.borderRadius }}
        >
          <Typography variant="body1" color="textPrimary" fontWeight="bold">
            {getCount(following.length)}
          </Typography>
          <Typography variant="body1" color="textSecondary" fontSize={{ xs: '.7rem', sm: '.8rem' }}>
            {`idole${following.length > 1 ? 's' : ''}`}
          </Typography>
        </Stack> */}
        <Stack
          alignItems="center"
          width="100%"
          py={2}
          sx={{ backgroundColor: theme.palette.background.paper, borderRadius: theme.borderRadius }}
        >
          <Typography variant="body1" color="textPrimary" fontWeight="bold">
            {getCount(friendships.count)}
          </Typography>
          <Typography variant="body1" color="textSecondary" fontSize={{ xs: '.7rem', sm: '.8rem' }}>
            {t('word.friend', { count: friendships.count, ns: 'common' })}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function getCount(number: number) {
  if (number < 1000) {
    return number;
  } else if (number < 1_000_000) {
    return `${Math.floor(number / 1000)}K`;
  } else if (number < 1_000_000_000) {
    return `${Math.floor(number / 1_000_000)}M`;
  } else if (number < 1_000_000_000_000) {
    return `${Math.floor(number / 1_000_000_000)}B`;
  }
}
