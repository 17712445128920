import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

import { useGetAccountQuery } from '@graphql';
import { SearchAccountDisplaySpot } from './spot';

interface SupportAccountDetailProps {}

export function SupportAccountDetail(props: SupportAccountDetailProps) {
  const { slug } = useParams();

  const { data, loading } = useGetAccountQuery({
    skip: !slug,
    variables: { slug: slug! },
  });

  if (loading) {
    return (
      <Stack p={2} justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (!data?.account) {
    return (
      <Stack p={2} justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body2" fontStyle="italic">
          Compte introuvable
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack p={2} spacing={2}>
      <Stack>{data.account.__typename === 'Spot' && <SearchAccountDisplaySpot />}</Stack>
    </Stack>
  );
}
