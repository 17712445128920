import _ from "lodash";

const typePolicies = {
  typePolicies: {
    Query: {
      fields: {
        events: {
          merge(existing: any = [], incoming: any = []) {
            const merged = [...existing];

            incoming.forEach((section: any) => {
              const index = merged.findIndex((existingEvent) => existingEvent.__ref === section.__ref);
              if (index === -1) {
                merged.push(section);
              }
            });

            return merged;
          },
        },
      },
    },
    Account: {
      fields: {
        features: {
          merge: true,
        },
      },
    },
  },
};

export default typePolicies;
