import * as React from 'react';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { GetEventDetailedQuery } from '@graphql';
import { Tiles } from '../../common';
import IDCard from './IDCard';

interface EventPreviewProps {
  event: GetEventDetailedQuery['event'];
  scrollToTickets: () => void;
}

function EventPreview(props: EventPreviewProps) {
  const { event, scrollToTickets } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!event) {
    return null;
  }

  const { tickets, address, from, to, spot } = event;

  return (
    <Stack spacing={isMobile ? 2 : 5}>
      <IDCard event={event} scrollToTickets={scrollToTickets} />
      <Stack spacing={1}>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.Date from={from} to={to} />
          <Tiles.EventPrice tickets={tickets} />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.Schedule from={from} to={to} />
          <Tiles.Address label={spot?.address.label || address?.label} />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          {spot && <Tiles.Spot avatar={spot.avatar?.url} name={spot.name} slug={spot.slug} />}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default React.memo(EventPreview);
