import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useLocation, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import * as Device from 'react-device-detect';

import Spot from './spot';
import Event from './event';
import Consumer from './consumer';

interface EntityDialogProps {
  type: 'consumer' | 'event' | 'spot';
  navigation: 'param' | 'query';
}

export function EntityDialog(props: EntityDialogProps) {
  const { type, navigation } = props;
  const [idOrSlug, setIdOrSlug] = React.useState<string | null>(null);
  const [startY, setStartY] = React.useState<number | null>(null);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const getToQuery = () => {
    const { id = null, slug = null } = params;
    let found: string | null = null;

    if (navigation === 'param') {
      found = type === 'event' ? id : slug;
    } else {
      const [searchParam] = type;
      found = searchParams.get(searchParam);
    }

    return found;
  };

  const handleBack = (toRoot = false) => {
    // prevent user to quit app if he went to the dialog from a direct link
    if (toRoot || window.history.state.idx <= 1) {
      // find out if first dialog was opened from search or not
      let rootPath = searchParams.has('search') ? '/search' : location.pathname;

      if (searchParams.has('search')) {
        // if search, keep any existing search params
        rootPath = rootPath += `?${searchParams.toString()}`;
      }

      navigate(rootPath, { preventScrollReset: true, state: { from: 'dialog' } });
    } else {
      navigate(-1);
    }
  };

  const handleClose = (e: React.SyntheticEvent, reason: string) => {
    // if backdropClick or escapeKeyDown, allow to close dialog without navigating to previous dialog
    handleBack(['backdropClick', 'escapeKeyDown'].includes(reason));
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    // save initial vertical position
    setStartY(e.touches[0].clientY);
  };

  // if user swipes down, close dialog
  const handleTouchMove = (e: React.TouchEvent) => {
    if (!startY) return;

    const currentY = e.touches[0].clientY;
    const distance = currentY - startY;

    if (distance > 300) {
      handleBack(true);
    }
  };

  const handleTouchEnd = () => {
    // reset initial position
    setStartY(null);
  };

  React.useEffect(() => {
    const found = getToQuery();

    if (found && found !== idOrSlug) {
      setIdOrSlug(found);
    }

    return () => {
      // close dialog on unmount
      setIdOrSlug(null);
    };
  }, [searchParams, params]);

  return (
    <MuiDialog
      TransitionComponent={Transition}
      open={Boolean(idOrSlug)}
      onClose={handleClose}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      fullWidth
      keepMounted
      fullScreen={Device.isMobile}
      maxWidth="md"
      scroll="body"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, .7)',
          },
        },
      }}
    >
      {type === 'consumer' && <Consumer handleBack={handleClose} />}
      {type === 'event' && <Event handleBack={handleClose} />}
      {type === 'spot' && <Spot handleBack={handleClose} />}
    </MuiDialog>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
