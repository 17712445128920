
import moment, { Moment } from 'moment-timezone';

export * from "./constants";
export * from "./image";
export * from "./booking";

import {
  GetConversationsQuery, GetConversationQuery, SpotSchedulesOpening
  , SpotSchedulesClosing
} from '@graphql';

export function debounce(fn: (...args: any[]) => any, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: any[]): Promise<any> => {
    return new Promise((resolve) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        const result = await fn(...args);
        resolve(result);
      }, delay);
    });
  };
}

export function truncateText(text: string, maxLength: number = 40): string {
  if (text.length > maxLength) {
    return text.slice(0, maxLength).trim() + '...';
  }
  return text;
}

export function getConversationTitle(
  accountId: string,
  conversation: GetConversationsQuery['conversations'][0] | GetConversationQuery['conversation'],
  truncateTextLength = 25
) {
  let name = '';

  if (conversation.name) {
    name = conversation.name;
  } else {
    name =
      conversation.members.length > 2
        ? conversation.members
            .filter((m) => m.id !== accountId)
            .map((m) => m.name)
            .join(', ')
        : conversation.members.find((m) => m.id !== accountId)?.name || '';
  }

  return truncateText(name, truncateTextLength);
}

export function formatPriceRange(averageTicket?: number | null) {
  if (!averageTicket) {
    return 'NC';
  } else if (averageTicket < 20) {
    return 'cheap';
  } else if (averageTicket < 50) {
    return 'accessible';
  } else if (averageTicket < 200) {
    return 'high';
  } else {
    return 'premium';
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getTrueKeys(obj: Record<string, boolean | null | string>): string[] {
  return Object.keys(obj)
    .filter((key) => obj[key] === true)
    .filter((_, idx) => idx < 3)
}

export function getSpotTypes(obj: Record<string, boolean | null | string>) {
  return Object.keys(obj)
    .filter((key) => obj[key] === true)
    .filter(key => !["__typename", "id", "spotId", "accountId", "updatedAt"].includes(key))
}

export function anonymizeEmail(email: string) {
  if (!email.includes('@')) {
    throw new Error('Invalid email');
  }

  // split the email into username and domain
  const [username, domain] = email.split('@');

  // hide part of the username and domain
  const anonymizedUsername = username.length > 2
    ? username[0] + '*'.repeat(username.length - 2) + username.slice(-1)
    : username[0] + '*';

  const [domainName, domainExtension] = domain.split('.');
  const anonymizedDomain = domainName[0] + '*'.repeat(domainName.length - 1) + '.' + domainExtension;

  return `${anonymizedUsername}@${anonymizedDomain}`;
}

export function isOpen(opening: Array<Partial<SpotSchedulesOpening>>, closing: Array<Partial<SpotSchedulesClosing>>) {
  if (closing.length) {
    const isClosed = closing.some((close) => moment().isBetween(close.from, close.to));

    if (isClosed) {
      return false;
    }
  }

  if (opening.length) {
    const schedules = opening.find((open) => open.dayOfWeek === moment().day());

    if (schedules) {
      const openTime = moment(schedules.openTime, 'HH:mm:ss');
      let closingTime = moment(schedules.closingTime, 'HH:mm:ss');
      const breakIn = schedules.breakIn ? moment(schedules.breakIn, 'HH:mm:ss') : null;
      let breakOut = schedules.breakOut ? moment(schedules.breakOut, 'HH:mm:ss') : null;

      // handle closing time after midnight
      if (moment(closingTime).isBefore(openTime)) {
        closingTime = moment(closingTime).add(1, 'day');
      }

      // handle break time after midnight
      if (breakIn && breakOut) {
        if (moment(breakOut).isBefore(breakIn)) {
          breakOut = moment(breakOut).add(1, 'day');
        }

        return moment().isBetween(openTime, breakIn) || moment().isBetween(breakOut, closingTime);
      } else {
        return moment().isBetween(openTime, closingTime);
      }
    }

    return null;
  }

  return null;
}

interface Scheduler {
  isOpen: boolean | null;
  isClosingSoon: boolean;
  isOpeningSoon: boolean;
  nextOpening: Moment | null;
  nextClosing: Moment | null;
  openTime: Moment | null;
  breakIn: Moment | null;
  breakOut: Moment | null;
  closingTime: Moment | null;

}

export function getScheduler(opening: Array<Partial<SpotSchedulesOpening>>, closing: Array<Partial<SpotSchedulesClosing>>) {
  const result: Scheduler = {
    isOpen: null,
    isClosingSoon: false,
    isOpeningSoon: false,
    nextOpening: null,
    nextClosing: null,
    openTime: null,
    breakIn: null,
    breakOut: null,
    closingTime: null,
  }

  const schedules = opening.find((open) => open.dayOfWeek === moment().day());

  let isOpen: Scheduler['isOpen'] = null;
  let isClosingSoon: Scheduler['isClosingSoon'] = false;
  let isOpeningSoon: Scheduler['isOpeningSoon'] = false;
  let openTime: Scheduler['openTime'] = null;
  let breakIn: Scheduler['breakIn'] = null;
  let breakOut: Scheduler['breakOut'] = null;
  let closingTime: Scheduler['closingTime'] = null;
  let nextOpening: Scheduler['nextOpening'] = null;
  let nextClosing: Scheduler['nextClosing'] = null;

  if (schedules) {
    if (schedules.openTime && schedules.closingTime) {
      openTime = moment(schedules.openTime, 'HH:mm:ss');
      closingTime = moment(schedules.closingTime, 'HH:mm:ss');

      // handle closing time after midnight
      if (closingTime.isBefore(openTime)) {
        closingTime = moment(closingTime).add(1, 'day');
      }

      if (schedules.breakIn && schedules.breakOut) {
        // set open times for case with breaks
        breakIn = moment(schedules.breakIn, 'HH:mm:ss');
        breakOut = moment(schedules.breakOut, 'HH:mm:ss');

        // handle break time after midnight
        if (breakOut.isBefore(breakIn)) {
          breakOut = moment(breakOut).add(1, 'day');
        }

        // handle closing before break
        if (closingTime.isBefore(breakOut)) {
          closingTime = moment(closingTime).add(1, 'day');
        }

        isOpen = moment().isBetween(openTime, breakIn) || moment().isBetween(breakOut, closingTime);

        const isBreak = moment().isBetween(breakIn, breakOut);
        const isFirstOpening = moment().isBetween(openTime, breakIn);

        if (isOpen) {
          nextOpening = isFirstOpening ? breakOut : getNextOpenDay(opening, closing)
          isOpeningSoon = false;
          nextClosing = isFirstOpening ? breakIn : closingTime;
          isClosingSoon = nextClosing.diff(moment(), 'minutes') < 60;
        } else {
          // check if re-opening soon
          nextOpening = isBreak ? breakOut : getNextOpenDay(opening, closing);
          isOpeningSoon = nextOpening ? nextOpening.diff(moment(), 'minutes') < 60 : false;
          isClosingSoon = false;
          nextClosing = isFirstOpening ? breakIn : closingTime;
        }

      } else {
        isOpen = moment().isBetween(openTime, closingTime);
        nextClosing = isOpen ? closingTime : null;
        nextOpening = getNextOpenDay(opening, closing);
        isClosingSoon = isOpen ? closingTime.diff(moment(), 'minutes') < 60 : false;
        isOpeningSoon = isOpen || !nextOpening ? false : nextOpening.diff(moment(), 'minutes') < 60;
      }
    } else {
      const nextDay = getNextOpenDay(opening, closing);

      if (nextDay) {
        isOpen = false;
        nextOpening = nextDay;
        isOpeningSoon = nextOpening.diff(moment(), 'minutes') < 60;
      }
    }

    // set result
    Object.assign(result, {
      isOpen,
      isClosingSoon,
      isOpeningSoon,
      nextOpening,
      nextClosing,
      openTime,
      breakIn,
      breakOut,
      closingTime,
    });
  }

  // handle any special closings
  if (closing.length) {
    const isClosed = closing.some((close) => moment().isBetween(close.from, close.to));

    if (isClosed) {
      result.isOpen = false;
      result.isClosingSoon = false;
      result.isOpeningSoon = false;
      result.closingTime = null;
      result.nextOpening = null;
      result.nextClosing = null;
    }
  }


  return result;
}

export function getNextOpenDay(opening: Array<Partial<SpotSchedulesOpening>>, closing: Array<Partial<SpotSchedulesClosing>>) {
  const now = moment();
  let nextOpeningTime: moment.Moment | null = null;

  // Function to check if a period is closed
  const isInClosingPeriod = (timestamp: moment.Moment): boolean => {
    return closing.some((close) =>
      timestamp.isBetween(moment(close.from), moment(close.to), null, '[)')
    );
  };

  // Search for the next opening day
  for (let i = 0; i < 7; i++) {
    const dayIndex = (now.day() + i) % 7; // Calculate the next day (0 = Sunday, 6 = Saturday)
    const daySchedules = opening.find((open) => open.dayOfWeek === dayIndex);

    if (daySchedules?.openTime) {
      const openTime = moment(daySchedules.openTime, 'HH:mm:ss').day(dayIndex);

      // if it's today, make sure the opening time is after now
      if (i === 0 && openTime.isBefore(now)) continue;

      // if opening period is not affected by closings
      if (!isInClosingPeriod(openTime)) {
        nextOpeningTime = openTime;
        break;
      }
    }
  }

  return nextOpeningTime;
}