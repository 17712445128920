import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Device from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useParams } from 'react-router-dom';

import { useGetSpotDetailedQuery, GetSpotDetailedQuery } from '@graphql';
import SpotSkeleton from './SpotSkeleton';
import Header from './header';
import Preview from './preview';
import Agenda from './agenda';
import Rex from './rex';

interface SpotDialogProps {
  handleBack: (e: React.SyntheticEvent, reason: string) => void;
}

function SpotDialog(props: SpotDialogProps) {
  const [activeTab, setActiveTab] = React.useState(0);
  const [spot, setSpot] = React.useState<GetSpotDetailedQuery['spot'] | null>(null);
  const swiperRef = React.useRef<SwiperClass>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { t } = useTranslation('spot');

  const padding = isMobile ? 1 : 3;

  const { data, loading, called } = useGetSpotDetailedQuery({
    skip: !searchParams.get('s') && !params.slug,
    variables: { slug: searchParams.get('s') || params.slug || '' },
    onCompleted(data) {
      setSpot(data.spot);
    },
  });

  React.useEffect(() => {
    return () => {
      setActiveTab(0);
      swiperRef.current?.slideReset();
    };
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);

    if (swiperRef.current) {
      swiperRef.current.slideTo(newTab);
    }
  };

  const handleSwiperSlideChange = (swiper: any) => {
    setActiveTab(swiper.activeIndex);
  };

  if (loading) {
    return <SpotSkeleton />;
  }

  if (called && !data?.spot) {
    return (
      <Stack p={5} spacing={3} justifyContent="center" alignItems="center">
        <Typography variant="h6">
          {t('text.Unable to find Spot', { spot: searchParams.get('s') || params.slug })}
        </Typography>
        <Button variant="contained" color="primary" onClick={props.handleBack}>
          {t('back', { ns: 'cta' })}
        </Button>
      </Stack>
    );
  }

  if (spot) {
    const { covers, configuration } = spot;

    return (
      <Stack sx={{ position: 'relative', width: '100%' }}>
        <Stack sx={{ position: 'relative' }}>
          <Header
            handleBack={props.handleBack}
            covers={covers}
            configuration={configuration}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
          />
        </Stack>
        <DialogContent sx={{ p: 0, width: '100%' }}>
          <Stack spacing={5} p={padding}>
            <Swiper
              onSlideChange={handleSwiperSlideChange}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              slidesPerView={1}
              style={{ marginTop: 0, padding: Device.isDesktop ? 10 : 5 }}
              initialSlide={activeTab}
            >
              <SwiperSlide>{activeTab === 0 && <Preview spot={spot} />}</SwiperSlide>
              <SwiperSlide>{activeTab === 1 && <Agenda slug={spot.slug} />}</SwiperSlide>
              {/* <SwiperSlide>{activeTab === 1 && <>Menu</>}</SwiperSlide> */}
              {/* <SwiperSlide>{activeTab === 3 && <Rex spot={data.spot} />}</SwiperSlide> */}
            </Swiper>
          </Stack>
        </DialogContent>
      </Stack>
    );
  }

  return null;
}

export default React.memo(SpotDialog);
