import { Navigate, createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';

import { EntityDialog as AccountEventDialog } from '@components';

import Layout from '../layout';

import LostPage from 'pages/404/LostPage';

import EventsPage from 'pages/events/EventsPage';
import EventsErrorBoundary from 'pages/events/ErrorBoudary';

import MakersPage from 'pages/makers/MakersPage';
import MakersErrorBoundary from 'pages/makers/ErrorBoudary';

import SpotsPage from 'pages/spots/SpotsPage';
import SpotsErrorBoundary from 'pages/spots/ErrorBoudary';

import { useSession } from '@hooks';
import { RequireMeRole, RequireRole } from '.';
import ErrorBoundary from './ErrorBoudary';

import { MePage, Table } from 'pages/me';

import {
  OAuthPage,
  ActivatePage,
  MyCalendarPage,
  MyBookingDetailPage,
  MyEventDetailPage,
  SupportPage,
  SupportDashboardPage,
  SupportAccountsdPage,
  SupportAccountDetail,
  SearchPage,
} from '../pages';

export const buildRouter = (session: ReturnType<typeof useSession>) => {
  const { account } = session;
  const role = account?.__typename ?? 'Guest';

  const meRoleRoutesMap: Record<string, RouteObject[]> = {
    Guest: [],
    Consumer: [
      {
        id: 'me-calendar',
        path: 'calendar',
        element: (
          <RequireMeRole role={role} allowedRoles={['Consumer']}>
            <MyCalendarPage />
          </RequireMeRole>
        ),
        children: [
          {
            id: 'me-calendar-booking-exact',
            path: 'booking', // Exact path
            element: <Navigate to="/me/calendar" replace />,
          },
          {
            id: 'me-calendar-booking-detail',
            path: 'booking/:bookingId', // Path with :bookingId
            element: <MyBookingDetailPage />,
          },
          {
            id: 'me-calendar-event-exact',
            path: 'event', // Exact path
            element: <Navigate to="/me/calendar" replace />,
          },
          {
            id: 'me-calendar-event-detail',
            path: 'event/:eventId',
            element: <MyEventDetailPage />,
          },
        ],
      },
      {
        id: 'me-table',
        path: 'table',
        element: <Table />,
        children: [
          {
            id: 'me-table-booking-exact',
            path: 'booking', // Exact path
            element: <Navigate to="/me/table" replace />,
          },
          {
            id: 'me-table-booking-detail',
            path: 'booking/:bookingId',
            element: <MyBookingDetailPage />,
          },
          {
            id: 'me-table-event-exact',
            path: 'event', // Exact path
            element: <Navigate to="/me/table" replace />,
          },
          {
            id: 'me-table-event-detail',
            path: 'booking/:eventId',
            element: <MyEventDetailPage />,
          },
        ],
      },
    ],
    Spot: [
      {
        id: 'me-dashboard',
        path: 'dashboard',
        element: <Outlet />,
        children: [
          {
            id: 'me-dashboard-preview',
            path: 'preview',
            element: <>Dashboard</>,
          },
        ],
      },
      {
        id: 'me-calendar',
        path: 'calendar',
        element: (
          <RequireMeRole role={role} allowedRoles={['Spot']}>
            <Outlet />
          </RequireMeRole>
        ),
        children: [
          {
            id: 'me-calendar-booking',
            path: 'booking',
            element: <MyCalendarPage />,
            children: [
              {
                id: 'me-calendar-booking-detail',
                path: ':bookingId',
                element: <MyBookingDetailPage />,
              },
            ],
          },
          {
            id: 'me-calendar-event',
            path: 'event',
            element: <MyCalendarPage />,
            children: [
              {
                id: 'me-calendar-event-detail',
                path: ':eventId',
                element: <MyEventDetailPage />,
              },
            ],
          },
        ],
      },
    ],
  };

  return createBrowserRouter([
    {
      id: 'app',
      path: '/',
      element: <Layout />,
      ErrorBoundary,
      children: [
        // UNLLOGGED USER OR CONSUMER PAGES
        {
          index: true,
          element: (
            <>
              {role === 'Guest' && <Navigate to="/spots" />}
              {role === 'Consumer' && <Navigate to="/spots" />}
              {role === 'Spot' && <Navigate to="/me" />}
              {role === 'Support' && <Navigate to="/support" />}
            </>
          ),
          // element: (role === 'Spot' ? <Navigate to="/me" /> : <Navigate to="/spots" />),
        },
        {
          id: 'events',
          path: 'events',
          element: (
            <RequireRole role={role} allowedRoles={['Guest', 'Consumer']}>
              <EventsPage />
            </RequireRole>
          ),
          ErrorBoundary: EventsErrorBoundary,
        },
        {
          id: 'spots',
          path: 'spots',
          element: (
            <RequireRole role={role} allowedRoles={['Guest', 'Consumer']}>
              <SpotsPage />
            </RequireRole>
          ),
          ErrorBoundary: SpotsErrorBoundary,
        },
        {
          id: 'artists',
          path: 'artists',
          element: (
            <RequireRole role={role} allowedRoles={['Guest', 'Consumer']}>
              <MakersPage />
            </RequireRole>
          ),
          ErrorBoundary: MakersErrorBoundary,
        },
        {
          id: 'search',
          path: 'search',
          element: (
            <RequireRole role={role} allowedRoles={['Guest', 'Consumer']}>
              <SearchPage />
            </RequireRole>
          ),
          children: [
            {
              id: 'search-spot',
              path: 'spot',
              element: <Navigate to="/search?search=spots" replace />,
            },
            {
              id: 'search-spot-open',
              path: 'spot/:slug',
              element: <AccountEventDialog type="spot" navigation="param" />,
            },
            {
              id: 'search-event',
              path: 'event',
              element: <Navigate to="/search?search=events" replace />,
            },
            {
              id: 'search-event-open',
              path: 'event/:id',
              element: <AccountEventDialog type="event" navigation="param" />,
            },
          ],
        },
        // LOGGED USER
        {
          id: 'me',
          path: 'me',
          element: (
            <RequireMeRole role={role} allowedRoles={['Consumer', 'Spot']}>
              {/*
                 MePage is responsible of :
                - redirecting "me" exact match route depending on user role 
                - redirecting desktop views to mobile views if device is mobile
              */}
              <MePage />
            </RequireMeRole>
          ),
          children: meRoleRoutesMap[role],
        },
        // OAUTH FINALIZATION
        {
          id: 'oauth',
          path: 'oauth',
          element: <OAuthPage />,
        },
        // EMAIL ACTIVATION
        {
          id: 'activate',
          path: 'activate',
          element: <ActivatePage />,
        },
        // SUPPORT
        {
          id: 'support',
          path: 'support',
          children: [
            {
              id: 'support-dashboard',
              path: 'dashboard',
              element: (
                <RequireRole role={role} allowedRoles={['Support']}>
                  <SupportDashboardPage />
                </RequireRole>
              ),
            },
            {
              id: 'support-users',
              path: 'users',
              element: (
                <RequireRole role={role} allowedRoles={['Support']}>
                  <SupportPage page="users" />
                </RequireRole>
              ),
            },
            {
              id: 'support-accounts',
              path: 'accounts',
              element: (
                <RequireRole role={role} allowedRoles={['Support']}>
                  <SupportAccountsdPage />
                </RequireRole>
              ),
              children: [
                {
                  id: 'support-accounts-overview',
                  path: ':slug',
                  element: (
                    <RequireRole role={role} allowedRoles={['Support']}>
                      <SupportAccountDetail />
                    </RequireRole>
                  ),
                  children: [
                    {
                      id: 'support-accounts-detail',
                      path: ':tab',
                      element: (
                        <RequireRole role={role} allowedRoles={['Support']}>
                          <SupportAccountDetail />
                        </RequireRole>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              id: 'support-events',
              path: 'events',
              element: (
                <RequireRole role={role} allowedRoles={['Support']}>
                  <SupportPage page="events" />
                </RequireRole>
              ),
            },
            {
              id: 'support-report',
              path: 'reports',
              element: (
                <RequireRole role={role} allowedRoles={['Support']}>
                  <SupportPage page="report" />
                </RequireRole>
              ),
            },
          ],
        },
        {
          id: '404',
          path: '*',
          element: <LostPage />,
        },
      ],
    },
  ]);
};
