import * as React from 'react';
import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router-dom';

import { useListHomeEventsLazyQuery, ListHomeEventsQuery } from '@graphql';
import { Sliders, Highlight } from '@components';

export default function EventsPage() {
  const [events, setEvents] = React.useState<ListHomeEventsQuery['eventCategories'] | null>(null);
  const location = useLocation();

  const [getEvents] = useListHomeEventsLazyQuery({
    onCompleted: ({ eventCategories }) => {
      setEvents(eventCategories ?? null);
    },
  });

  React.useEffect(() => {
    const isAlreadyLoaded = ['dialog', 'slide'].includes(location.state.from);

    if (!isAlreadyLoaded) {
      getEvents({ fetchPolicy: 'network-only' });
    }
  }, [location.state?.from]);

  if (!events) {
    return null;
  }

  return (
    <Stack pb={8}>
      <Highlight type="event" />
      <Sliders categories={events} />
    </Stack>
  );
}
