import * as React from 'react';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';

interface AccountButtonProps {
  handleOpenDrawer: (event: React.MouseEvent<HTMLElement>) => void;
}

export function AccountButton(props: AccountButtonProps) {
  const { handleOpenDrawer } = props;
  const { account } = useSession();
  const theme = useTheme();

  if (!account) {
    return <CircularProgress size={25} color="uncolored" />;
  }

  return (
    <Tooltip title="Menu">
      <IconButton data-id="account" onClick={handleOpenDrawer}>
        {Device.isMobile ? (
          <theme.icons.burgerMenu sx={{ color: theme.palette.text.secondary }} />
        ) : (
          <Avatar src={account.avatar?.url} sx={{ width: 30, height: 30 }}>
            {account.name[0]}
          </Avatar>
        )}
      </IconButton>
    </Tooltip>
  );
}
