import * as React from 'react';
import { Theme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import * as yup from 'yup';
import { useFormik,  } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useSupdateSpotBringableMutation, Spot, UpdateSpotBringableInput } from '@graphql';
import { useToastError } from '@hooks';
import { TriStateCheckbox } from '../common';

interface SpotBringableProps {
  account: Spot;
}

export function SpotBringable(props: SpotBringableProps) {
  const { account } = props;
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateSpotBringable] = useSupdateSpotBringableMutation();

  const initialValues = React.useMemo(() => {
    return Object.entries(account.bringable)
      .filter(([key, value]) => !['__typename', 'id', 'updatedAt'].includes(key))
      .reduce((acc, [key, value]) => {
        acc[key as keyof UpdateSpotBringableInput] = value;
        return acc;
      }, {} as Record<keyof UpdateSpotBringableInput, boolean | null>);
  }, [account.bringable]);

  const validationSchema = React.useMemo(() => {
    return yup.object(
      Object.keys(account.bringable)
        .filter((key) => !['__typename', 'id', 'updatedAt'].includes(key))
        .reduce((acc, key) => {
          acc[key as keyof UpdateSpotBringableInput] = yup.boolean().nullable().defined();
          return acc;
        }, {} as Record<keyof UpdateSpotBringableInput, yup.BooleanSchema<boolean | null>>)
    );
  }, [account.bringable]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (formik.dirty) {
        updateSpotBringable({
          variables: { id: account.id, input: values },
          onCompleted: ({ supdateSpotBringable }) => {
            setTimeoutId(null);
            formik.resetForm({ values });
            toast(t('spotBringable.toast.success', { ns: 'field' }), { type: 'success' });
          },
          onError: toastError,
        });
      }
    },
  });

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    if (formik.dirty && formik.isValid) {
      const newTmoutId = setTimeout(formik.submitForm, 1000);
      setTimeoutId(newTmoutId);
    }
  }, [formik.dirty, formik.isValid, formik.values]);

  if (account?.__typename !== 'Spot') {
    return null;
  }

  return (
    <Stack width="100%" spacing={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 3,
          borderRadius: (theme: Theme) => theme.borderRadius,
          backgroundColor: alpha('#fff', 0.1),
        }}
      >
        <Stack>
          <Typography fontWeight="bold">{t('spotBringable.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotBringable.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        divider={<Divider sx={{ backgroundColor: (theme: Theme) => theme.palette.grey[800] }} />}
      >
        {Object.keys(account.bringable)
          .filter((key) => !['__typename', 'id', 'updatedAt'].includes(key))
          .map((key) => (
            <TriStateCheckbox
              key={key}
              label={t(`bringable.${key}`, { ns: 'spot' })}
              dataKey={key}
              formik={formik}
            />
          ))}
      </Stack>
    </Stack>
  );
}