import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Item } from './types';

interface InsightProps {
  type: 'spot' | 'event';
  maxWidth?: string;
  item: Item;
}

interface ExtraProps {
  type: 'spot' | 'event';
  text: string;
  city: string;
}

export function Insight(props: InsightProps) {
  const { type, item, maxWidth = '50%' } = props;
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleOpen = () => {
    const path = type === 'spot' ? `s=${item?.slug}` : `e=${item?.id}`;
    navigate(`${pathname}?${path}`, { preventScrollReset: true });
  };

  return (
    <Stack
      p={2}
      spacing={2}
      width="fit-content"
      maxWidth={maxWidth}
      alignItems="center"
      sx={{
        borderRadius: theme.spacing(0, 2, 2, 0),
        borderTop: `1px solid  ${alpha('#fff', 0.3)}`,
        borderBottom: `1px solid  ${alpha('#fff', 0.3)}`,
        borderRight: `1px solid  ${alpha('#fff', 0.3)}`,
        backgroundColor: alpha(theme.palette.background.default, 0.5),
        backdropFilter: 'blur(15px)',
      }}
    >
      <Typography
        variant="h1"
        textAlign="center"
        fontSize={{ xs: '1.5rem', sm: '2.1rem', md: '2rem', xl: '2.5rem' }}
        fontWeight={800}
      >
        {item.title}
      </Typography>

      <Typography textAlign="center" fontSize={{ xs: '.7rem', sm: '.8rem', md: '.9rem', xl: '1rem' }}>
        {item.description}
      </Typography>
      <Box>
        <Button
          onClick={handleOpen}
          startIcon={<theme.icons.see />}
          variant="contained"
          color="uncolored"
          size={isScreenMedium ? 'small' : 'large'}
        >
          {t(`See the ${type}`, { ns: 'cta' })}
        </Button>
      </Box>
    </Stack>
  );
}

export function Extra(props: ExtraProps) {
  const { type, city, text } = props;
  const theme = useTheme();
  const { t } = useTranslation('common');

  return (
    <Stack
      p={2}
      width="fit-content"
      sx={{
        alignSelf: 'flex-end',
        borderRadius: theme.spacing(2, 0, 0, 2),
        backgroundColor: alpha(theme.palette.background.default, 0.5),
        backdropFilter: 'blur(15px)',
        borderTop: `1px solid  ${alpha('#fff', 0.3)}`,
        borderBottom: `1px solid  ${alpha('#fff', 0.3)}`,
        borderLeft: `1px solid  ${alpha('#fff', 0.3)}`,
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <theme.icons.event
          sx={{
            width: { xs: 25, sm: 35, md: 45, xl: 55 },
            height: { xs: 25, sm: 35, md: 45, xl: 55 },
          }}
        />

        <Stack>
          <Typography
            component="h5"
            fontSize={{ xs: '.9rem', sm: '1rem', md: '1.1rem', xl: '1.2rem' }}
            fontWeight={700}
          >
            {type === 'event' ? 'Save the date' : t('highlight.Spot to discover')}
            {` • ${city}`}
          </Typography>
          <Typography fontSize={{ xs: '.7rem', sm: '.8rem', md: '.9rem', xl: '1rem' }}>{text}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
