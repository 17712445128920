import Stack from '@mui/material/Stack';

import { useListHomeEventsQuery } from '@graphql';
import { Sliders, Highlight } from '@components';

export default function EventsPage() {
  const { data } = useListHomeEventsQuery();

  if (!data?.eventCategories) {
    return null;
  }

  return (
    <Stack pb={8}>
      <Highlight type="event" />
      <Sliders categories={data.eventCategories} />
    </Stack>
  );
}
