import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';

interface CancelDialogProps {
  handleConfirm: () => void;
}

export default function CancelDialog(props: CancelDialogProps) {
  const { handleConfirm } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation('cta');

  const handleClickConfirm = () => {
    setOpen(false);
    handleConfirm();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="error" variant="outlined" fullWidth onClick={handleOpen}>
        {t('cancel booking')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('confirmDialog.title.Cancel booking', { ns: 'booking' })}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {t(
              'confirmDialog.content.Confirmation will cancel the reservation, the linked reserved places will be made available again.',
              { ns: 'booking' }
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClickConfirm}>
            {t('confirm')}
          </Button>
          <Button variant="contained" color="uncolored" onClick={handleClose}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
