import * as React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useReactiveVar } from '@apollo/client';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import moment, { Moment } from 'moment-timezone';

import {
  BookingOccasionEnum,
  useBookByConsumerMutation,
  GetSpotDetailedQuery,
  BookByConsumerMutation,
} from '@graphql';
import { loginStatusVar } from '@context';
import { useSession, useToastError } from '@hooks';
import RegisterMobilePhone from './RegisterMobilePhone';

interface BookingButtonProps {
  spot: GetSpotDetailedQuery['spot'];
}
interface initDateTimeParams {
  opening: GetSpotDetailedQuery['spot']['opening'];
  closing: GetSpotDetailedQuery['spot']['closing'];
}

export default function BookingButton(props: BookingButtonProps) {
  const { spot } = props;
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const loginStatus = useReactiveVar(loginStatusVar);
  const theme = useTheme();
  const { user } = useSession();
  const { t } = useTranslation('cta');
  const toastError = useToastError();
  const [dateSchedules, setDateSchedules] = React.useState<string | null>(null);
  const [isMobilePhoneOpen, setIsMobilePhoneOpen] = React.useState(false);

  const [bookSpot, { data: newBooking }] = useBookByConsumerMutation();

  moment().tz(spot.address.timezone ?? 'Europe/Paris');

  const defaultValues = {
    startTime: spot.opening
      ? roundToNearestFiveMinutes(initDateTime({ opening: spot.opening, closing: spot.closing }))
      : null,
    participants: '1',
    occasion: '',
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: yup.object({
      startTime: yup
        .date()
        .min(moment(), t('bookingStartTime.validation.min', { ns: 'field' }))
        .required(),
      participants: yup.number().min(1).required(),
      occasion: yup.string().oneOf(['', ...Object.values(BookingOccasionEnum)]),
    }),
    onSubmit: async (values) => {
      if (!values.startTime) {
        return;
      }
      const validValues = {
        spotId: spot.id,
        startTime: values.startTime.toISOString(),
        participants: parseInt(values.participants, 10),
        occasion: values.occasion.length ? (values.occasion as BookingOccasionEnum) : null,
        date: values.startTime.format('YYYY-MM-DD'),
        time: values.startTime.format('HH:mm:ss'),
      };

      // return;
      bookSpot({
        variables: { input: validValues },
        onCompleted() {
          setSuccess(true);
        },
        onError: toastError,
      });
    },
  });

  React.useEffect(() => {
    let isClosed = true;

    if (!formik.values.startTime) {
      return;
    }

    if (spot) {
      const dayOfWeek = formik.values.startTime.day();
      const daySchedules = spot.opening[dayOfWeek];

      if (daySchedules && daySchedules.openTime) {
        const openTime = moment(daySchedules.openTime, 'HH:mm:ss').set({
          year: formik.values.startTime.year(),
          month: formik.values.startTime.month(),
          date: formik.values.startTime.date(),
        });

        const closingTime = moment(daySchedules.closingTime, 'HH:mm:ss').set({
          year: formik.values.startTime.year(),
          month: formik.values.startTime.month(),
          date: formik.values.startTime.date(),
        });

        const breakInTime = daySchedules.breakIn
          ? moment(daySchedules.breakIn, 'HH:mm:ss').set({
              year: formik.values.startTime.year(),
              month: formik.values.startTime.month(),
              date: formik.values.startTime.date(),
            })
          : null;

        const breakOutTime = daySchedules.breakOut
          ? moment(daySchedules.breakOut, 'HH:mm:ss').set({
              year: formik.values.startTime.year(),
              month: formik.values.startTime.month(),
              date: formik.values.startTime.date(),
            })
          : null;

        if (closingTime.isBefore(openTime)) {
          closingTime.add(1, 'days');
        }

        let dateSchedules: string | null = null;

        if (breakInTime && breakOutTime) {
          dateSchedules = `
            ${openTime.format('HH:mm')} - ${breakInTime.format('HH:mm')},
            ${breakOutTime.format('HH:mm')} - ${closingTime.format('HH:mm')}
          `;

          isClosed = !(
            formik.values.startTime.isBetween(openTime, breakInTime, null, '[)') ||
            formik.values.startTime.isBetween(breakOutTime, closingTime, null, '[)')
          );
        } else {
          dateSchedules = `${openTime.format('HH:mm')} - ${closingTime.format('HH:mm')}`;
          isClosed = !formik.values.startTime.isBetween(openTime, closingTime, null, '[)');
        }

        formik.setErrors({
          ...formik.errors,
          ...(isClosed && {
            startTime: t('error.closed', {
              ns: 'booking',
              name: spot.name,
            }) as string,
          }),
        });

        setDateSchedules(dateSchedules);
      }
    }
  });

  function BookingSuccess(props: { booking: BookByConsumerMutation['bookByConsumer'] }) {
    const { booking } = props;
    const { t } = useTranslation('cta');

    return (
      <Stack spacing={3}>
        <Alert severity="success">
          {t('alert.Your reservation has been successfully registered.', { ns: 'booking' })}
        </Alert>
        <Stack spacing={1} divider={<Divider sx={{ backgroundColor: theme.palette.grey[800] }} />}>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('word.Place', { ns: 'common' })}</Typography>
            <Typography>{booking.spot.name}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('word.Date', { ns: 'common' })}</Typography>
            <Typography>{moment(booking.startTime).format('dddd D MMMM YYYY')}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('word.Hour', { ns: 'common' })}</Typography>
            <Typography>{moment(booking.startTime).tz('Europe/Paris').format('HH:mm')}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('word.Participants', { ns: 'common' })}</Typography>
            <Typography>{booking.participants}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('word.Occasion', { ns: 'common' })}</Typography>
            <Typography>
              {booking.occasion ? t(`occasion.${booking.occasion}`, { ns: 'booking' }) : '-'}
            </Typography>
          </Stack>
        </Stack>
        <Alert severity="info">
          <Stack spacing={1}>
            <Typography variant="body2">
              {t('alert.Your reservation status is', { ns: 'booking' }) +
                t('status.PENDING', { ns: 'booking' })}
            </Typography>
            <Typography variant="body2">
              {t('alert.It will soon be validated by', { ns: 'booking', spot: spot.name })}
            </Typography>
          </Stack>
        </Alert>
        <Button variant="contained" onClick={handleClose}>
          {t('close')}
        </Button>
      </Stack>
    );
  }

  const handleClickOpen = () => {
    if (!user) {
      loginStatusVar({
        ...loginStatus,
        shouldLogFirst: true,
      });
    } else if (!user.mobilePhone) {
      setIsMobilePhoneOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleCloseRegisterMobilePhone = (success = false) => {
    setIsMobilePhoneOpen(false);
    success && setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
    formik.resetForm({
      values: {
        ...defaultValues,
        startTime: roundToNearestFiveMinutes(initDateTime({ opening: spot.opening, closing: spot.closing })),
      },
    });
  };

  const shouldDisableDate = (date: Moment) => {
    const currentDate = moment();
    const dayOfWeek = moment(date).day();
    const daySchedules = spot.opening[dayOfWeek];

    // if none schedule for this day, disable the date
    if (!daySchedules) {
      return true;
    }

    const openTime = moment(daySchedules.openTime, 'HH:mm:ss');
    const closingTime = moment(daySchedules.closingTime, 'HH:mm:ss');

    // if the closing time is before the opening time, it means the closing is the next day
    if (closingTime.isBefore(openTime)) {
      closingTime.add(1, 'days');
    }

    // Check if the current date falls within a closing range
    const isClosed = spot.closing.some(({ from, to }) => moment(date).isBetween(from, to));
    if (isClosed) {
      return true;
    }

    // Check that the current date is between openTime and closingTime
    if (date.isSame(currentDate, 'day')) {
      const isValidDateRange = currentDate.isBefore(openTime) || currentDate.isBetween(openTime, closingTime);

      return !isValidDateRange;
    }

    return !daySchedules.openTime;
  };

  const shouldDisableTime = (timeValue: Moment, view: 'hours' | 'minutes' | 'seconds') => {
    const dayOfWeek = timeValue.day();
    const daySchedules = spot.opening[dayOfWeek];

    if (!daySchedules) {
      return true;
    }

    const openTime = moment(daySchedules.openTime, 'HH:mm:ss').set({
      year: timeValue.year(),
      month: timeValue.month(),
      date: timeValue.date(),
    });

    const closingTime = moment(daySchedules.closingTime, 'HH:mm:ss').set({
      year: timeValue.year(),
      month: timeValue.month(),
      date: timeValue.date(),
    });

    const breakInTime = daySchedules.breakIn
      ? moment(daySchedules.breakIn, 'HH:mm:ss').set({
          year: timeValue.year(),
          month: timeValue.month(),
          date: timeValue.date(),
        })
      : null;

    const breakOutTime = daySchedules.breakOut
      ? moment(daySchedules.breakOut, 'HH:mm:ss').set({
          year: timeValue.year(),
          month: timeValue.month(),
          date: timeValue.date(),
        })
      : null;

    if (closingTime.isBefore(openTime)) {
      closingTime.add(1, 'days');
    }

    if (view === 'hours') {
      const isOpenTime = timeValue.isBetween(openTime, closingTime, null, '[)');

      // check if the spot is open from the previous day
      const previousDayOfWeek = (dayOfWeek - 1 + 7) % 7; // Find previous day (modulo 7)
      const previousDaySchedule = spot.opening[previousDayOfWeek];
      let isOpenFromPreviousDay = false;

      if (previousDaySchedule) {
        const prevClosingTime = moment(previousDaySchedule.closingTime, 'HH:mm:ss').set({
          year: timeValue.year(),
          month: timeValue.month(),
          date: timeValue.date() - 1, // Passer au jour précédent
        });

        const prevOpenTime = moment(previousDaySchedule.openTime, 'HH:mm:ss').set({
          year: timeValue.year(),
          month: timeValue.month(),
          date: timeValue.date() - 1,
        });

        if (prevClosingTime.isBefore(prevOpenTime)) {
          prevClosingTime.add(1, 'days'); // if after midnight, add 1 day
        }

        // if actual hour is between midnight and previous day closing time
        if (timeValue.isBetween(moment('00:00:00', 'HH:mm:ss'), prevClosingTime, null, '[)')) {
          isOpenFromPreviousDay = true;
        }
      }

      if (!breakInTime && !breakOutTime) {
        return !isOpenTime && !isOpenFromPreviousDay;
      }

      const isDuringBreak = !!(
        breakInTime &&
        breakOutTime &&
        timeValue.isBetween(breakInTime, breakOutTime, null, '[)')
      );

      // Return "true" if outside of opening hours OR if on break
      return (!isOpenTime && !isOpenFromPreviousDay) || isDuringBreak;
    }

    return false;
  };

  if (!spot) {
    return null;
  }

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" fullWidth onClick={handleClickOpen}>
        {t('book')}
      </Button>
      <RegisterMobilePhone open={isMobilePhoneOpen} handleOnClose={handleCloseRegisterMobilePhone} />
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: theme.palette.background.default }}>
          <Toolbar width="100%" sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {t('word.Book', { ns: 'common' })}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
          {success && newBooking ? (
            <BookingSuccess booking={newBooking.bookByConsumer} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={3} minWidth="30%" alignItems="center">
                <Typography variant="h6" color="textPrimary">
                  {spot.name}
                </Typography>
                {user && (
                  <Alert severity="info">
                    <Stack spacing={1} direction="row">
                      <Typography variant="body2">
                        {t('alert.The reservation will be made in the name of', {
                          ns: 'booking',
                        })}
                      </Typography>
                      <Typography variant="body2" fontWeight="bold">
                        {user.firstname + ' ' + user.lastname}
                      </Typography>
                    </Stack>
                    <Typography variant="body2">
                      {t('alert.Schedule for the selected day', {
                        ns: 'booking',
                        dateSchedules: dateSchedules,
                      })}
                    </Typography>
                  </Alert>
                )}
                <DateTimePicker
                  label={t('bookingStartTime.label', { ns: 'field' })}
                  disablePast
                  value={formik.values.startTime}
                  onChange={(value) => {
                    formik.setFieldValue('startTime', value);
                    formik.setFieldTouched('startTime', true);
                  }}
                  shouldDisableDate={shouldDisableDate}
                  shouldDisableTime={shouldDisableTime}
                  slotProps={{
                    textField: {
                      required: true,
                      error: formik.touched.startTime && Boolean(formik.errors.startTime),
                      helperText: Boolean(formik.errors.startTime)
                        ? (formik.errors.startTime as unknown as string)
                        : '',
                    },
                  }}
                  sx={{ width: '100%' }}
                />
                <TextField
                  {...formik.getFieldProps('participants')}
                  required
                  fullWidth
                  variant="outlined"
                  slotProps={{ input: { min: 1 } }}
                  type="number"
                  id="book-participants"
                  name="participants"
                  label={t('participants.label', { ns: 'field' })}
                  onChange={(e) => {
                    const value = Math.max(1, parseInt(e.target.value, 10));
                    formik.setFieldValue('participants', isNaN(value) ? '' : value);
                  }}
                  error={formik.touched.participants && Boolean(formik.errors.participants)}
                  disabled={Boolean(formik.errors.startTime)}
                  helperText={
                    formik.touched.participants && formik.errors.participants
                      ? String(formik.errors.participants)
                      : null
                  }
                />
                <FormControl fullWidth>
                  <InputLabel id="occasion-select-label">
                    {t('occasion select.label', { ns: 'field' })}
                  </InputLabel>
                  <Select
                    {...formik.getFieldProps('occasion')}
                    labelId="occasion-select-label"
                    id="occasion-select"
                    name="occasion"
                    label={t('occasion select.label', { ns: 'field' })}
                    value={formik.values.occasion}
                    disabled={Boolean(formik.errors.startTime)}
                  >
                    <MenuItem value="">
                      <em>{t('occasion select.placeholder', { ns: 'field' })}</em>
                    </MenuItem>
                    {Object.entries(BookingOccasionEnum).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {t(`suitable.${key.charAt(0).toLowerCase() + key.slice(1)}`, {
                          ns: 'spot',
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText id="occasion-helper-text">
                    {t('word.Optional', { ns: 'common' })}
                  </FormHelperText>
                </FormControl>
                <Stack direction="row" justifyContent="space-between" width="100%">
                  <Button variant="contained" color="uncolored" onClick={handleClose}>
                    {t('cancel', { ns: 'cta' })}
                  </Button>
                  <Button
                    autoFocus
                    variant="contained"
                    type="submit"
                    color={
                      !(formik.dirty || formik.isValid || Boolean(formik.errors.startTime))
                        ? 'disabled'
                        : 'success'
                    }
                    disabled={!(formik.dirty || formik.isValid) || Boolean(formik.errors.startTime)}
                    sx={{ ml: 3 }}
                  >
                    {t('send my request', { ns: 'cta' })}
                  </Button>
                </Stack>
              </Stack>
            </form>
          )}
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}

function initDateTime(
  { opening, closing }: initDateTimeParams,
  date: Moment = moment(),
  loop: number = 0
): Moment | null {
  const dayOfWeek = date.day(); // Jour courant (0 = dimanche, 1 = lundi, ..., 6 = samedi)
  const daySchedules = opening[dayOfWeek]; // Récupérer les horaires pour le jour courant

  // break loop, when spot has no one schedule for week
  if (loop >= 6) {
    return null;
  }

  // Si aucun horaire pour aujourd'hui, passer au jour suivant (récursivement)
  if (!daySchedules || !daySchedules.openTime) {
    // Passer au jour suivant
    const nextDay = date.clone().add(1, 'days');
    return initDateTime({ opening, closing }, nextDay, loop + 1);
  }

  const openTime = moment(daySchedules.openTime, 'HH:mm:ss').set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });

  let closingTime = moment(daySchedules.closingTime, 'HH:mm:ss').set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });

  // Ajuster l'heure de fermeture si elle est au jour suivant (fermeture après minuit)
  if (closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  // Vérification des pauses (breakIn et breakOut)
  const breakInTime = daySchedules.breakIn
    ? moment(daySchedules.breakIn, 'HH:mm:ss').set({
        year: date.year(),
        month: date.month(),
        date: date.date(),
      })
    : null;

  const breakOutTime = daySchedules.breakOut
    ? moment(daySchedules.breakOut, 'HH:mm:ss').set({
        year: date.year(),
        month: date.month(),
        date: date.date(),
      })
    : null;

  // Vérification des périodes de fermeture (spot.closing)
  const isClosed = closing.some(({ from, to }) => date.isBetween(moment(from), moment(to)));

  // Si actuellement fermé ou après l'heure de fermeture, passer au jour suivant
  if (isClosed || date.isAfter(closingTime)) {
    // Passer au jour suivant
    const nextDay = date.clone().add(1, 'days');
    return initDateTime({ opening, closing }, nextDay, loop + 1);
  }

  // Si l'heure actuelle est dans la pause (entre breakIn et breakOut)
  if (breakInTime && breakOutTime && date.isBetween(breakInTime, breakOutTime)) {
    // Passer à l'heure de fin de pause (breakOut)
    return roundToNearestFiveMinutes(breakOutTime);
  }

  // Si l'établissement est actuellement ouvert et hors pause, retourner l'heure actuelle
  if (date.isBetween(openTime, closingTime)) {
    return roundToNearestFiveMinutes(date.add(1, 'hour'));
  }

  // Sinon, retourner l'heure d'ouverture du jour actuel
  return roundToNearestFiveMinutes(openTime);
}

function roundToNearestFiveMinutes(startTime: Moment | null): Moment | null {
  if (!startTime) {
    return null;
  }

  const minutes = startTime.minutes();
  const roundedMinutes = Math.ceil(minutes / 10) * 10;
  return startTime.minutes(roundedMinutes).seconds(0);
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
