import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import StarsIcon from '@mui/icons-material/Stars';
import PaymentIcon from '@mui/icons-material/Payment';
import WifiIcon from '@mui/icons-material/Wifi';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import CakeIcon from '@mui/icons-material/Cake';

import { GetSpotDetailedQuery } from '@graphql';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    square={false}
    elevation={0}
    sx={{ backgroundColor: 'transparent' }}
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: 'white' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderLeft: `1px solid ${theme.palette.background.paper}`,
  borderRight: `1px solid ${theme.palette.background.paper}`,
  backgroundColor: '#191919',
}));

function Features(props: { spot: GetSpotDetailedQuery['spot'] }) {
  const { spot } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>('type');
  const { t } = useTranslation('spot');

  if (!spot) {
    return null;
  }

  const { atmosphere, bringable, equipment, service, type, payment, suitable, highlight } = spot;

  const iconByFeature = {
    atmosphere: <theme.icons.volMid />,
    bringable: <CakeIcon />,
    equipment: <MicExternalOnIcon />,
    service: <WifiIcon />,
    configuration: <RoomPreferencesIcon />,
    type: <theme.icons.store />,
    payment: <PaymentIcon />,
    suitable: <StarsIcon />,
    highlight: <theme.icons.flare />,
  };

  const features = { payment, equipment, service, suitable, bringable, highlight };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="h6" mb={1}>
        {t('title.The place')}
      </Typography>
      {Object.keys(features).map((feature, index, allFeatures) => {
        const featureKey = feature as keyof typeof features;
        const isFirst = index === 0;
        const isLast = index === allFeatures.length - 1;

        let borderRadius = theme.spacing(0);

        if (isFirst) {
          borderRadius = theme.spacing(1, 1, 0, 0);
        } else if (isLast) {
          borderRadius = expanded === feature ? theme.spacing(0) : theme.spacing(0, 0, 1, 1);
        }

        return (
          <Accordion key={feature} expanded={expanded === feature} onChange={handleChange(feature)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ borderRadius }}>
              {iconByFeature[featureKey as keyof typeof iconByFeature]}
              <Typography ml={2}>{t(`${featureKey}.title`)}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ borderRadius: isLast && expanded === feature ? theme.spacing(0, 0, 1, 1) : 0 }}
            >
              <Stack
                direction="row"
                flexWrap="wrap"
                alignContent="space-between"
                alignItems="stretch"
                useFlexGap
                sx={{ gap: theme.spacing(2, 0) }}
              >
                {Object.entries(features[feature as keyof typeof features] ?? {}).map(
                  ([key, value]) =>
                    !['__typename', 'updatedAt', 'id'].includes(key) && (
                      <Stack
                        key={key}
                        spacing={2}
                        direction="row"
                        width={{ xs: '50%', sm: '33.33%', md: '20%' }}
                      >
                        {value === true && <theme.icons.check color="success" fontSize="small" />}
                        {value === false && (
                          <theme.icons.close sx={{ color: theme.palette.grey[600] }} fontSize="small" />
                        )}
                        {value === null && (
                          <theme.icons.minus sx={{ color: theme.palette.grey[800] }} fontSize="small" />
                        )}
                        <Typography
                          variant="body2"
                          fontSize={{ xs: '.7rem', sm: '.8rem', md: '.9rem' }}
                          color={value === null ? 'textSecondary' : 'textPrimary'}
                        >
                          {t(`${featureKey}.${key}`)}
                        </Typography>
                      </Stack>
                    )
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

export default React.memo(Features);
