import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { loginStatusVar } from '@context';
import { useSession, useToastError, useConnection } from '@hooks';
import { SignupSSOForm } from './SignupSSOForm';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function OAuthPage() {
  const { refetch } = useSession();
  const { logout } = useConnection();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation('cta');
  const navigate = useNavigate();
  const toastError = useToastError();

  // ensure session is up to date
  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await refetch();

        // early exit if no session
        if (!data.session) {
          navigate('/spots');
        }

        const { ssoProvider, isVerified, birthdate, firstname, lastname, gender } = data?.session || {};
        // redirect to spots if no ssoProvider or already verified or all required fields are set
        if (ssoProvider === null || isVerified || (birthdate && firstname && lastname && gender)) {
          navigate('/spots');
        } else if (!birthdate || !firstname || !lastname || !gender) {
          setOpen(true);
        }
      } catch (error) {
        toastError(error);
        navigate('/spots');
      }
    })();
  }, []);

  const handleCancel = () => {
    handleClose();
    logout();
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/', { replace: true });
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: 'background.default' }}>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Finalisation de l'inscription
            </Typography>
            <Button variant="outlined" color="uncolored" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Toolbar>
        </AppBar>
        <Stack height="100%" p={3} justifyContent="center" alignItems="center">
          <SignupSSOForm handleClose={handleClose} />
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
