// APP TEXT
export const APP_TITLE = "Festayre";
export const FOOTER_TEXT = `${new Date().getFullYear()} Built with ♡ by ${APP_TITLE}`;
// PAGES TITLE
export const PAGE_TITLE_NOT_FOUND = "Lost in space";
// UI CONSTANTS
export const DRAWER_WIDTH = 300;
// LOGIN
export const DEFAULT_PASSWORD = "******";
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 Mo for upload
// NAVIGATION
export const NAVIGATION = {
  Guest: ['/spots', '/events', '/search'],
  Consumer: ['/spots', '/events', '/search', '/me/calendar'],
  Spot: ['/me/dashboard/preview', '/me/calendar/booking', '/me/calendar/event'],
  Support: ['/support/dashboard', '/support/users', '/support/accounts', '/support/events', '/support/reports'],
  Maker: [''],
};