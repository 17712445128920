import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface EmptyStateProps {
  text: string;
}

export function EmptyState(props: EmptyStateProps) {
  const { text } = props;
  const theme = useTheme();

  return (
    <Stack p={3} sx={{ backgroundColor: theme.palette.background.paper, borderRadius: theme.borderRadius }}>
      <Typography fontSize={{ xs: '.7rem', sm: '.8rem' }}>{text}</Typography>
    </Stack>
  );
}
