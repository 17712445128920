import React from 'react';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export const NameSearch = () => {
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: yup.string().required(),
    validateOnMount: true,
    onSubmit: ({ name }) => {
      setTimeoutId(null);

      if (name.length) {
        searchParams.set('name', name);
      } else {
        searchParams.delete('name');
      }

      setSearchParams(searchParams);
    },
  });

  // init values from params at mount
  React.useEffect(() => {
    const name = searchParams.get('name');
    name?.length && formik.setValues({ name });
  }, []);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    formik.setFieldValue('name', e.target.value);
    const newTmoutId = setTimeout(formik.submitForm, 500);
    setTimeoutId(newTmoutId);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        {...formik.getFieldProps('name')}
        onChange={handleChangeName} // override default onChange
        fullWidth
        size="small"
        placeholder="nom"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <theme.icons.search sx={{ color: theme.palette.text.secondary }} />
              </InputAdornment>
            ),
            endAdornment: timeoutId ? (
              <InputAdornment position="end">
                <CircularProgress size={20} sx={{ color: 'white' }} />
              </InputAdornment>
            ) : null,
          },
        }}
      />
    </form>
  );
};

interface TriStateCheckboxProps {
  label: string;
  searchKey: string;
}

export const TriStateCheckbox = React.memo((props: TriStateCheckboxProps) => {
  const { searchKey, label } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState<boolean | null>(
    searchParams.get(searchKey) === null ? null : searchParams.get(searchKey) === 'true'
  );
  const { t } = useTranslation('common');

  const getLabel = () => {
    switch (value) {
      case true:
        return t('word.yes');
      case false:
        return t('word.no');
      default:
        return t('word.never mind');
    }
  };

  const handleClick = () => {
    const value = searchParams.get(searchKey) === null ? null : searchParams.get(searchKey) === 'true';
    const nextValue = value === true ? false : value === false ? null : true;
    setValue(nextValue);
  };

  React.useEffect(() => {
    if (searchKey) {
      if (value === null) {
        searchParams.delete(searchKey);
      } else {
        searchParams.set(searchKey, value.toString());
      }
      setSearchParams(searchParams);
    }
  }, [value]);

  return (
    <Stack width="100%" justifyContent="space-between" alignItems="center" sx={{ cursor: 'pointer' }}>
      <Typography fontSize=".8rem">{label}</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onClick={handleClick}
              checked={value === true} // Checkbox appears checked only for `true`
              indeterminate={value === null} // Show indeterminate state for `null`
              checkedIcon={<CheckBoxIcon color="success" />} // checked icon
              icon={<DisabledByDefaultIcon color="error" />} // unchecked icon
              indeterminateIcon={<CropSquareIcon color="uncolored" />} // indeterminate icon
            />
          }
          label={
            <Typography component="span" variant="body2" color="textSecondary" fontSize=".8rem">
              {getLabel()}
            </Typography>
          }
          labelPlacement="bottom"
        />
      </FormGroup>
    </Stack>
  );
});

export function AccountTypeSelect() {
  const validateTypes = ['consumer', 'artist', 'spot', 'support'];

  let [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };


  React.useEffect(() => {
    const type = searchParams.get('type');

    if (type) {
      if (type.length && !validateTypes.includes(type)) {
        setType('');
      } else {
        setType(type);
      }
    }
  }, []);

  React.useEffect(() => {
    if (type?.length) {
      searchParams.set('type', type);
    } else {
      searchParams.delete('type');
    }
    setSearchParams(searchParams);
  }, [type]);

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="account-type-select-label">Type</InputLabel>
      <Select
        labelId="account-type-select-label"
        id="account-type-select"
        value={type}
        label="Type"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>Tous</em>
        </MenuItem>
        <MenuItem value="consumer">Consumer</MenuItem>
        <MenuItem value="spot">Spot</MenuItem>
        <MenuItem value="artist">Artist</MenuItem>
        <MenuItem value="support">Support</MenuItem>
      </Select>
    </FormControl>
  );
}

export function SortSelect() {
  const validateSorts = [
    'name-desc',
    'name-asc',
    'created-desc',
    'created-asc',
    'updated-desc',
    'updated-asc',
  ];

  const [sort, setSort] = React.useState('');
  let [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event: SelectChangeEvent) => {
    setSort(event.target.value as string);
  };

  React.useEffect(() => {
    const sort = searchParams.get('sort');

    if (sort) {
      if (sort.length && !validateSorts.includes(sort)) {
        setSort('');
      } else {
        setSort(sort);
      }
    }
  }, []);

  React.useEffect(() => {
    if (sort?.length) {
      searchParams.set('sort', sort);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('sort');
    }
    setSearchParams(searchParams);
  }, [sort]);

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="sort-select-label">Tri</InputLabel>
      <Select labelId="sort-select-label" id="sort-select" value={sort} label="Tri" onChange={handleChange}>
        <MenuItem value="">
          <em>Aucun</em>
        </MenuItem>
        <MenuItem value="name-desc">Nom descendant</MenuItem>
        <MenuItem value="name-asc">Nom ascendant</MenuItem>
        <MenuItem value="created-desc">Date de création descendant</MenuItem>
        <MenuItem value="created-asc">Date de création ascendant</MenuItem>
        <MenuItem value="updated-desc">Date de modification descendant</MenuItem>
        <MenuItem value="updated-asc">Date de modification ascendant</MenuItem>
      </Select>
    </FormControl>
  );
}
