import { makeVar } from "@apollo/client";

type loginStatusVar = {
  isLoggedIn: boolean;
  isSessionExpired: boolean;
  shouldLogFirst: boolean;
  redirection: string | null;
};

const defaultLoginStatus: loginStatusVar = {
  isLoggedIn: false,
  isSessionExpired: false,
  shouldLogFirst: false,
  redirection: null,
};

export const loginStatusVar = makeVar<loginStatusVar>(defaultLoginStatus);
export const languageVar = makeVar("fr");
export const showAppLoaderVar = makeVar(false);
export const conversationIdVar = makeVar<string | null>(null);
export const previewBookingVar = makeVar<string | null>(null);