import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useToastError } from '@hooks';
import { useSupdateAccountMutation } from '@graphql';
import { TextField } from '@components';

interface NameDisplayProps {
  id: string;
  name: string;
}

export function NameDisplay(props: NameDisplayProps) {
  const { id, name } = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateAccount] = useSupdateAccountMutation();

  const formik = useFormik({
    initialValues: { name },
    validationSchema: yup.object({
      name: yup.string().min(1).required(),
    }),
    onSubmit: (values) => {
      updateAccount({
        variables: {
          id,
          input: { name: values.name },
        },
        onCompleted() {
          formik.resetForm({ values });
          setIsEditing(false);
        },
        onError: toastError,
      });
    },
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack width="100%" spacing={1}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack>
            <Typography fontWeight="bold">{t('accountName.label', { ns: 'field' })}</Typography>
            <Typography variant="caption" color="text.secondary">
              {t('accountName.caption', { ns: 'field' })}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="flex-start">
            {isEditing ? (
              <>
                <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid}
                  variant="contained"
                  color="success"
                  size="small"
                  type="submit"
                >
                  {t('validate')}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
                {t('edit')}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack>
          {isEditing ? (
            <TextField
              required
              fullWidth
              size="small"
              type="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            ></TextField>
          ) : (
            <Typography color="text.primary">{name}</Typography>
          )}
        </Stack>
      </Stack>
    </form>
  );
}
