import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import BottomNavigation from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { loginStatusVar } from '@context';
import { NAVIGATION } from '@utils';

const defaultNavigation: Record<string, number> = {
  '/': 0,
  '/events': 0,
  '/spots': 1,
  '/search': 2,
};

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: gray;
  min-width: unset;
  max-width: unset;
  &.Mui-selected {
    color: white;
  }
`);

export default function CustomBottomNavigation() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, account } = useSession();
  const loginStatus = useReactiveVar(loginStatusVar);
  const [path = '/'] = pathname.match(/\/\w*/) || [];
  const [value, setValue] = React.useState(defaultNavigation[path]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('common');

  React.useEffect(() => {
    const role = account?.__typename ?? 'Guest';
    let foundValue = NAVIGATION[role].indexOf(pathname);
    if (foundValue === -1 && pathname.startsWith('/search')) {
      foundValue = 2;
    }
    setValue(foundValue);
  }, [pathname, account?.__typename, value]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    const { id } = event.currentTarget;

    if (id === 'account' && !user) {
      event.preventDefault();
    } else {
      setValue(newValue);
    }
  };

  const handleNavigate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { dataset } = event.currentTarget;
    if (dataset.path) {
      navigate(dataset.path, { preventScrollReset: true, flushSync: true, viewTransition: true });
    }
  };

  const handleClickAccount = () => {
    if (account?.__typename) {
      const type = account.__typename.toLowerCase()[0];
      const { slug } = account;
      const link = `?${type}=${slug}`;
      setSearchParams(link, { preventScrollReset: true });
    } else {
      loginStatusVar({ ...loginStatus, shouldLogFirst: true });
    }
  };

  const BottomNavSx = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar,
    backgroundColor: 'rgba(14, 14, 14, .8)',
    backdropFilter: 'blur(10px)',
    borderTop: `1px solid rgba(100, 100, 100, .5)`,
  };

  switch (account?.__typename) {
    case 'Consumer':
      return (
        <BottomNavigation showLabels={false} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction
            id="spot"
            data-path={NAVIGATION.Consumer[0]}
            icon={<theme.icons.explore />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="event"
            data-path={NAVIGATION.Consumer[1]}
            icon={<theme.icons.ticket />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="search"
            data-path={NAVIGATION.Consumer[2]}
            icon={<theme.icons.search />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="calendar"
            data-path={NAVIGATION.Consumer[3]}
            icon={<theme.icons.event />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="account"
            icon={
              <Avatar src={account.avatar?.url} alt="avatar" sx={{ width: 24, height: 24 }}>
                {account.name[0]}
              </Avatar>
            }
            onClick={handleClickAccount}
          />
        </BottomNavigation>
      );
    case 'Spot':
      return (
        <BottomNavigation showLabels={false} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction
            id="dashboard"
            data-path={NAVIGATION.Spot[0]}
            icon={<theme.icons.dashboard />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="booking"
            data-path={NAVIGATION.Spot[1]}
            icon={<theme.icons.booking />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="event"
            data-path={NAVIGATION.Spot[2]}
            icon={<theme.icons.event />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="account"
            icon={
              <Avatar src={account.avatar?.url} alt="avatar" sx={{ width: 24, height: 24 }}>
                {account.name[0]}
              </Avatar>
            }
            onClick={handleClickAccount}
          />
        </BottomNavigation>
      );
    case 'Support':
      return (
        <BottomNavigation showLabels={false} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction
            id="dashboard"
            data-path={NAVIGATION.Support[0]}
            icon={<theme.icons.dashboard />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="users"
            data-path={NAVIGATION.Support[1]}
            icon={<theme.icons.people />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="accounts"
            data-path={NAVIGATION.Support[2]}
            icon={<theme.icons.accounts />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="events"
            data-path={NAVIGATION.Support[3]}
            icon={<theme.icons.event />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            id="report"
            data-path={NAVIGATION.Support[4]}
            icon={<theme.icons.report />}
            onClick={handleNavigate}
          />
        </BottomNavigation>
      );
    default:
      // Guest
      return (
        <BottomNavigation showLabels={true} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction
            label="spots"
            id="spots"
            data-path={NAVIGATION.Guest[0]}
            icon={<theme.icons.explore />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            label="events"
            id="events"
            data-path={NAVIGATION.Guest[1]}
            icon={<theme.icons.ticket />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            label={t('word.Search')}
            id="search"
            data-path={NAVIGATION.Guest[2]}
            icon={<theme.icons.search />}
            onClick={handleNavigate}
          />
          <BottomNavigationAction
            label="compte"
            data-path={NAVIGATION.Guest[3]}
            icon={<theme.icons.account />}
            onClick={handleClickAccount}
          />
        </BottomNavigation>
      );
  }
}
