import moment, { Moment } from 'moment';

import {
  BookingStatusEnum,
  SpotSchedulesQuery,
  SpotSchedulesOpening,
  SpotSchedulesClosing,
} from '@graphql';

export const shouldDisableBookingDate = (
  date: Moment,
  opening: Partial<SpotSchedulesOpening>[],
  closing: Partial<SpotSchedulesClosing>[]
) => {
  const currentDate = moment();
  const dayOfWeek = moment(date).day();
  const daySchedules = opening[dayOfWeek];

  // if none schedule for this day, disable the date
  if (!daySchedules) {
    return true;
  }

  const openTime = moment(daySchedules.openTime, 'HH:mm:ss');
  const closingTime = moment(daySchedules.closingTime, 'HH:mm:ss');

  // if the closing time is before the opening time, it means the closing is the next day
  if (closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  // Check if the current date falls within a closing range
  const isClosed = closing.some(({ from, to }) => moment(date).isBetween(from, to));
  if (isClosed) {
    return true;
  }

  // Check that the current date is between openTime and closingTime
  if (date.isSame(currentDate, 'day')) {
    const isValidDateRange = currentDate.isBefore(openTime) || currentDate.isBetween(openTime, closingTime);

    return !isValidDateRange;
  }

  return !daySchedules.openTime;
};

export const shouldDisableBookingTime = (
  timeValue: Moment,
  opening: Partial<SpotSchedulesOpening>[],
  closing: Partial<SpotSchedulesClosing>[]
) => {
  const dayOfWeek = timeValue.day();
  const daySchedules = opening[dayOfWeek];

  if (!daySchedules) {
    return true;
  }

  const openTime = moment(daySchedules.openTime, 'HH:mm:ss').set({
    year: timeValue.year(),
    month: timeValue.month(),
    date: timeValue.date(),
  });

  const closingTime = moment(daySchedules.closingTime, 'HH:mm:ss').set({
    year: timeValue.year(),
    month: timeValue.month(),
    date: timeValue.date(),
  });

  if (closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  const breakInTime = daySchedules.breakIn
    ? moment(daySchedules.breakIn, 'HH:mm:ss').set({
      year: timeValue.year(),
      month: timeValue.month(),
      date: timeValue.date(),
    })
    : null;

  const breakOutTime = daySchedules.breakOut
    ? moment(daySchedules.breakOut, 'HH:mm:ss').set({
      year: timeValue.year(),
      month: timeValue.month(),
      date: timeValue.date(),
    })
    : null;

  const isOpenTime = timeValue.isBetween(openTime, closingTime, null, '[)');

  // Vérification de la fermeture du jour précédent
  const previousDayOfWeek = (dayOfWeek - 1 + 7) % 7; // Trouver le jour précédent (modulo 7)
  const previousDaySchedule = opening[previousDayOfWeek];
  let isOpenFromPreviousDay = false;

  if (previousDaySchedule) {
    const prevClosingTime = moment(previousDaySchedule.closingTime, 'HH:mm:ss').set({
      year: timeValue.year(),
      month: timeValue.month(),
      date: timeValue.date() - 1, // Passer au jour précédent
    });

    const prevOpenTime = moment(previousDaySchedule.openTime, 'HH:mm:ss').set({
      year: timeValue.year(),
      month: timeValue.month(),
      date: timeValue.date() - 1,
    });

    if (prevClosingTime.isBefore(prevOpenTime)) {
      prevClosingTime.add(1, 'days'); // Si la fermeture est après minuit, ajuster la date
    }

    // Si l'heure actuelle est entre minuit et la fermeture du jour précédent
    if (timeValue.isBetween(moment('00:00:00', 'HH:mm:ss'), prevClosingTime, null, '[)')) {
      isOpenFromPreviousDay = true;
    }
  }

  if (!breakInTime && !breakOutTime) {
    return !isOpenTime && !isOpenFromPreviousDay;
  }

  const isDuringBreak = !!(
    breakInTime &&
    breakOutTime &&
    timeValue.isBetween(breakInTime, breakOutTime, null, '[)')
  );

  // Retourner "true" si en dehors des heures d'ouverture OU si en pause
  return (!isOpenTime && !isOpenFromPreviousDay) || isDuringBreak;
};

interface initDateTimeParams {
  opening: SpotSchedulesQuery['spotSchedules']['opening'];
  closing: SpotSchedulesQuery['spotSchedules']['closing'];
}

export function initBookingDateTime({ opening, closing }: initDateTimeParams, date: Moment = moment()): Moment {
  const dayOfWeek = date.day(); // Jour courant (0 = dimanche, 1 = lundi, ..., 6 = samedi)
  const daySchedules = opening[dayOfWeek]; // Récupérer les horaires pour le jour courant

  // Si aucun horaire pour aujourd'hui, passer au jour suivant (récursivement)
  if (!daySchedules || !daySchedules.openTime) {
    // Passer au jour suivant
    const nextDay = date.clone().add(1, 'days');
    return initBookingDateTime({ opening, closing }, nextDay);
  }

  const openTime = moment(daySchedules.openTime, 'HH:mm:ss').set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });

  let closingTime = moment(daySchedules.closingTime, 'HH:mm:ss').set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });

  // Ajuster l'heure de fermeture si elle est au jour suivant (fermeture après minuit)
  if (closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  // Vérification des pauses (breakIn et breakOut)
  const breakInTime = daySchedules.breakIn
    ? moment(daySchedules.breakIn, 'HH:mm:ss').set({
      year: date.year(),
      month: date.month(),
      date: date.date(),
    })
    : null;

  const breakOutTime = daySchedules.breakOut
    ? moment(daySchedules.breakOut, 'HH:mm:ss').set({
      year: date.year(),
      month: date.month(),
      date: date.date(),
    })
    : null;

  // Vérification des périodes de fermeture (spot.closing)
  const isClosed = closing.some(({ from, to }) => date.isBetween(moment(from), moment(to)));

  // Si actuellement fermé ou après l'heure de fermeture, passer au jour suivant
  if (isClosed || date.isAfter(closingTime)) {
    // Passer au jour suivant
    const nextDay = date.clone().add(1, 'days');
    return initBookingDateTime({ opening, closing }, nextDay);
  }

  // Si l'heure actuelle est dans la pause (entre breakIn et breakOut)
  if (breakInTime && breakOutTime && date.isBetween(breakInTime, breakOutTime)) {
    // Passer à l'heure de fin de pause (breakOut)
    return roundToNearestFiveMinutes(breakOutTime);
  }

  // Si l'établissement est actuellement ouvert et hors pause, retourner l'heure actuelle
  if (date.isBetween(openTime, closingTime)) {
    return roundToNearestFiveMinutes(date.add(1, 'hour'));
  }

  // Sinon, retourner l'heure d'ouverture du jour actuel
  return roundToNearestFiveMinutes(openTime);
}

function roundToNearestFiveMinutes(startTime: Moment): Moment {
  const minutes = startTime.minutes();
  const roundedMinutes = Math.ceil(minutes / 10) * 10;
  return startTime.minutes(roundedMinutes).seconds(0);
}

export function getStatusColor(status: BookingStatusEnum) {
  switch (status) {
    case BookingStatusEnum.Accepted:
      return 'success';
    case BookingStatusEnum.Pending:
      return 'warning';
    case BookingStatusEnum.Rejected:
      return 'error';
    case BookingStatusEnum.Canceled:
      return 'error';
    default:
      return 'uncolored';
  }
}
