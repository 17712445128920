import React, { ReactElement, useState } from 'react';
import { useTheme } from '@mui/material';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { alpha, styled } from '@mui/material/styles';
import * as Device from 'react-device-detect';

import { useValidation } from './validationSchema';
import { useConnection } from '@hooks';
import useStyles from './styles';
import initValues from './initValues';

export default function LoginForm(): ReactElement {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState<string | null>(null);
  const { t, i18n } = useTranslation('authentication');
  const [locale, setLocale] = React.useState(i18n.language);
  const { login, loginLoading } = useConnection();

  const formik = useFormik({
    initialValues: initValues(),
    validationSchema: useValidation(),
    onSubmit: async (input) => {
      login({
        variables: { input },
        onError: (error: any) => {
          // @ts-ignore
          const message = error.networkError?.result?.errors?.[0]?.message;
          if (message === 'INVALID_CREDENTIALS') {
            setAlert(t('alert.Wrong credentials'));
          }
        },
      });
      formik.resetForm();
    },
  });

  React.useEffect(() => {
    if (alert && formik.dirty) {
      setAlert(null);
    }

    if (i18n.language !== locale) {
      setLocale(i18n.language);
      Object.keys(formik.errors).forEach((fieldName) => {
        formik.setFieldTouched(fieldName, true, true);
      });
    }
  }, [alert, formik.dirty, formik.errors, i18n.language]);

  return (
    <form style={styles.root} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack spacing={Device.isMobile ? 2 : 3}>
        <TextField
          required
          fullWidth
          size="small"
          type="email"
          name="email"
          autoComplete="email"
          label={t('field.email.label')}
          placeholder="ex: contact@festayre.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled={loginLoading}
        />
        <TextField
          required
          fullWidth
          name="password"
          size="small"
          type={showPassword ? 'string' : 'password'}
          autoComplete="current-password"
          label={t('field.password.label')}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          disabled={loginLoading}
          InputProps={{
            endAdornment: showPassword ? (
              <theme.icons.see
                fontSize="small"
                sx={styles.visibilityIcon}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <theme.icons.hide
                fontSize="small"
                sx={styles.visibilityIcon}
                onClick={() => setShowPassword(!showPassword)}
              />
            ),
          }}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Link
            href="#"
            color="textPrimary"
            underline="hover"
            variant="body2"
            sx={{ color: 'text.secondary' }}
            fontSize={{ xs: '.7rem', sm: '.8rem' }}
          >
            {t('forgot password?')}
          </Link>
        </Stack>
        {alert && <Alert severity="error">{alert}</Alert>}
        <Button
          disabled={!formik.dirty || !formik.isValid}
          loading={loginLoading}
          variant="contained"
          fullWidth
          size="small"
          color="primary"
          type="submit"
          sx={{
            '&.Mui-disabled': {
              background: alpha(theme.palette.background.default, 0.5),
              color: alpha(theme.palette.text.secondary, 0.5),
            },
          }}
        >
          {t('button.Connection')}
        </Button>
      </Stack>
    </form>
  );
}
