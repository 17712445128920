import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSession } from '@hooks';
import { Outlet } from 'react-router-dom';
import * as Device from 'react-device-detect';

import { NAVIGATION } from '@utils';

const ROUTE_PREFIX = '/me';

export default function MePage() {
  const { account } = useSession();
  const role = account?.__typename ?? 'Guest';
  const navigate = useNavigate();
  const location = useLocation();

  // avoid user to access extact "me" route
  React.useEffect(() => {
    const [basePath = '/'] = NAVIGATION[role];

    if (location.pathname === ROUTE_PREFIX) {
      navigate(basePath, { replace: true });
    } else {
      // Get the authorized paths for the user's role
      const authorizedPaths = NAVIGATION[role] || [];

      // Verify if the current route is authorized
      const isAuthorized = authorizedPaths.some((path) => location.pathname.startsWith(path));

      // Redirection if the user is not on an authorized route
      if (!isAuthorized) {
        navigate(basePath, { replace: true });
      }

      // Redirect to the calendar page if the user is on a mobile device
      if (Device.isMobile && location.pathname.includes('table')) {
        navigate(location.pathname.replace('table', 'calendar'), { replace: true });
      }
    }
  }, [role, location, navigate]);

  return <Outlet />;
}
