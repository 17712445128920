import * as Device from 'react-device-detect';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import TabletHeader from './TabletHeader';
import { HeaderProps } from './types';

function Header(props: HeaderProps) {
  if (Device.isMobileOnly) {
    return <MobileHeader {...props} />;
  } else if (Device.isTablet) {
    return <TabletHeader {...props} />;
  } else {
    return <DesktopHeader {...props} />;
  }
}

export default Header;
