import * as React from 'react';
import { useParams } from 'react-router-dom';

import { previewBookingVar } from '@context';

export function MyBookingDetailPage() {
  const { bookingId = null } = useParams();

  React.useEffect(() => {
    previewBookingVar(bookingId);
  }, [bookingId]);

  return null;
}
