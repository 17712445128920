import Stack from '@mui/material/Stack';
import * as Device from 'react-device-detect';

import { AccountTypeCounts } from './accountTypes';
import { LastTen } from './lastTen';

export function SupportDashboardPage() {
  if (Device.isMobile) {
    return (
      <Stack spacing={1} maxWidth="100%" p={2}>
        <AccountTypeCounts />
        <LastTen />
      </Stack>
    );
  }

  return (
    <Stack spacing={1} width="100%" p={2}>
      <Stack direction="row" spacing={2}>
        <AccountTypeCounts />
        <LastTen />
      </Stack>
    </Stack>
  );
}
