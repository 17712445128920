import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import moment from 'moment';

import { useSession, useToastError } from '@hooks';
import { useSignupSsoMutation, GenderEnum } from 'graphql';
import validationSchema from './validationSchemas';
import {
  Firstname,
  Lastname,
  Birthdate,
  Gender,
} from '../../../components/authentication/simple-signup/signup-form/FormFields';

interface SignupSSOFormProps {
  handleClose: () => void;
}

export function SignupSSOForm(props: SignupSSOFormProps) {
  const { handleClose } = props;
  const { user } = useSession();
  const theme = useTheme();
  const [isSignupSuccess, setIsSignupSuccess] = React.useState(false);
  const toastError = useToastError();
  const { t } = useTranslation('field');

  const [signupSSO] = useSignupSsoMutation();

  const defaultValues = {
    firstname: user?.firstname ?? '',
    lastname: user?.lastname ?? '',
    gender: '',
    birthdate: user?.birthdate ?? moment().subtract(18, 'years').subtract(3, 'day').toDate(),
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: defaultValues,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const validValues = {
        firstname: values.firstname,
        lastname: values.lastname,
        gender: values.gender.toUpperCase() as GenderEnum,
        birthdate: moment(values.birthdate).toISOString(),
      };

      signupSSO({
        variables: { input: validValues },
        onCompleted() {
          setIsSignupSuccess(true);
        },
        onError: toastError,
      });
    },
  });

  if (!user) {
    return null;
  }

  return !isSignupSuccess ? (
    <Stack width="100%" spacing={1} justifyContent="center" alignItems="center" flex={1}>
      <form onSubmit={formik.handleSubmit} style={{ width: 'auto', minWidth: '400px', height: 'auto' }}>
        <Stack spacing={3} mx={4} justifyContent="space-between" alignItems="center" height="100%">
          <Alert severity="info">Vérifie et / ou complète tes informations</Alert>
          <Firstname formik={formik} />
          <Lastname formik={formik} />
          <Birthdate formik={formik} />
          <Gender formik={formik} />
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={!formik.isValid}
            color="success"
            sx={{ ml: 'auto' }}
            startIcon={<theme.icons.rocket />}
          >
            {t("let's go", { ns: 'cta' })}
          </Button>
        </Stack>
      </form>
    </Stack>
  ) : (
    <Stack width="100%" height="100%" justifyContent="center" alignItems="center" spacing={2}>
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <Typography variant="h3" textAlign="center">
          {t('success.Congratulations', { ns: 'authentication', firstname: formik.values.firstname })} 🎉
        </Typography>
        <Typography textAlign="center">
          {t('success.Your account has been created. An email has been sent to', { ns: 'authentication' })}
        </Typography>
        <Alert icon={<theme.icons.check fontSize="inherit" />} severity="success">
          {user.email}
        </Alert>
        <Typography textAlign="center">
          {t('success.You should receive it soon, it will be used to validate your account.', {
            ns: 'authentication',
          })}
        </Typography>
        <Button variant="contained" onClick={handleClose}>
          {t('success.Close and login', { ns: 'authentication' })}
        </Button>
      </Stack>
    </Stack>
  );
}
