import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useReactiveVar } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useGetSpotBookingLazyQuery, useGetConsumerBookingLazyQuery } from '@graphql';
import { useSession, useToastError } from '@hooks';
import { previewBookingVar } from '@context';
import { APP_TITLE } from '@utils';
import BookingSpot from './BookingSpot';
import BookingConsumer from './BookingConsumer';
import EditConsumerForm from './booking-forms/EditConsumerForm';
import EditSpotForm from './booking-forms/EditSpotForm';

interface BookingDialogProps {
  Trigger?: React.ElementType;
}

export function BookingDialog(props: BookingDialogProps) {
  const { Trigger } = props;
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const [pageTitle, setPageTitle] = React.useState(`Booking | ${APP_TITLE}`);
  const { account } = useSession();
  const { t } = useTranslation('cta');
  const theme = useTheme();
  let { pathname } = useLocation();
  const navigate = useNavigate();
  const toastError = useToastError();
  const bookingId = useReactiveVar(previewBookingVar);

  const [getConsumerBooking, { data: consumer, loading: consumerLoading }] = useGetConsumerBookingLazyQuery({
    onCompleted({ booking }) {
      const date = moment(booking.startTime).format('LLL');
      const spotName = booking.spot.name;
      const pageTitle = `Booking | ${spotName} | ${date} | ${APP_TITLE}`;
      setPageTitle(pageTitle);
    },
    onError(error) {
      toastError({ message: t('toast.error.Unable to found the booking', { ns: 'booking' }) });
      handleClose();
    },
  });

  const [getSpotBooking, { data: spot, loading: spotLoading }] = useGetSpotBookingLazyQuery({
    onCompleted({ booking }) {
      const date = moment(booking.startTime).format('LLL');
      let clientName = '';
      if (booking.client.__typename === 'BookingClient') {
        const { firstname, lastname } = booking.client;
        clientName = `${firstname} ${lastname}`;
      } else if (booking.client.__typename === 'Consumer') {
        const { firstname, lastname } = booking.client.user;
        clientName = `${firstname} ${lastname}`;
      }
      const pageTitle = `Booking | ${clientName} | ${date} | ${APP_TITLE}`;
      setPageTitle(pageTitle);
    },
    onError(error) {
      toastError({ message: t('toast.error.Unable to found the booking', { ns: 'booking' }) });
      handleClose();
    },
  });

  React.useEffect(() => {
    if (bookingId) {
      handleOpen();
      if (account?.__typename === 'Consumer') {
        getConsumerBooking({ variables: { id: bookingId } });
      } else if (account?.__typename === 'Spot') {
        getSpotBooking({ variables: { id: bookingId } });
      }
    }
  }, [bookingId]);

  const tabPanelSx = { height: '100%', px: 0, pt: 3, pb: 1 };

  const handleOpen = () => {
    setValue('1');
    setOpen(true);
  };

  const handleClose = () => {
    previewBookingVar(null);
    const previousPath = pathname.split('/').slice(0, -1).join('/');
    navigate(previousPath, { preventScrollReset: true });
    setOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const EditForm = () => {
    if (account?.__typename === 'Consumer' && consumer?.booking) {
      return <EditConsumerForm booking={consumer?.booking} handleEdit={handleEdit} />;
    } else if (account?.__typename === 'Spot' && spot?.booking) {
      return <EditSpotForm booking={spot?.booking} handleEdit={handleEdit} />;
    }
  };

  React.useEffect(() => {
    if (!open) {
      setPageTitle(`Booking | ${APP_TITLE}`);
    }
  }, [open]);

  return (
    <>
      {Trigger && (
        <Box onClick={handleOpen} p={0} m={0}>
          <Trigger />
        </Box>
      )}
      <Dialog open={open} onClose={handleClose} fullScreen scroll="body">
        <Stack spacing={1} p={3} height="100%">
          {consumerLoading || spotLoading ? (
            <Stack justifyContent="center" alignItems="center" height="100vh">
              <CircularProgress color="primary" />
            </Stack>
          ) : isEdit ? (
            <EditForm />
          ) : (
            <>
              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  aria-label="booking tabs"
                  textColor="inherit"
                  indicatorColor="primary"
                >
                  <Tab label="Booking" value="1" />
                  <Tab label="Échanges" value="2" />
                </TabList>
                <TabPanel value="1" sx={tabPanelSx}>
                  {spot?.booking && <BookingSpot booking={spot.booking} handleEdit={handleEdit} />}
                  {consumer?.booking && (
                    <BookingConsumer booking={consumer.booking} handleEdit={handleEdit} />
                  )}
                </TabPanel>
                <TabPanel value="2" sx={tabPanelSx}>
                  {t('exchanges.empty state', { ns: 'booking' })}
                </TabPanel>
              </TabContext>
              <Button
                fullWidth={false}
                variant="contained"
                color="uncolored"
                startIcon={<theme.icons.close />}
                onClick={handleClose}
              >
                {t('close')}
              </Button>
            </>
          )}
        </Stack>
      </Dialog>
    </>
  );
}
