import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';
import { Calendar, BookingCreateDialog } from '@components';

export function MyCalendarPage() {
  const { account } = useSession();
  const { pathname } = useLocation();
  const { t } = useTranslation('cta');

  const CreateBookingTrigger = () => {
    return (
      <Button variant="outlined" color="primary" fullWidth={Device.isMobile}>
        {t('add a booking')}
      </Button>
    );
  };

  const CreateEventTrigger = () => {
    return (
      <Button variant="outlined" color="primary" fullWidth={Device.isMobile}>
        {t('add an event')}
      </Button>
    );
  };

  if (!account) {
    return null;
  }

  if (account.__typename === 'Consumer') {
    return (
      <>
        <Stack spacing={2} px={2} pt={2}>
          <BookingCreateDialog Trigger={CreateEventTrigger} slug={account.slug} />
          <Calendar accountId={account.id} variant="all" />
        </Stack>
        <Outlet />
      </>
    );
  }

  if (account.__typename === 'Spot') {
    const variant = pathname.split('/').pop() as 'booking' | 'event';
    const trigger = variant === 'booking' ? CreateBookingTrigger : CreateEventTrigger;

    return (
      <>
        <Stack spacing={2} px={2} pt={2}>
          <BookingCreateDialog Trigger={trigger} slug={account.slug} />
          <Calendar accountId={account.id} variant={variant} />
        </Stack>
        <Outlet />
      </>
    );
  }

  return null;
}
