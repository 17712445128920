import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useSearchParams, useNavigate, useLocation, useParams } from 'react-router-dom';

import { useSearchAccountsLazyQuery, SearchAccountsQuery, AccountTypeEnum } from '@graphql';
import { Typography, useTheme } from '@mui/material';

interface ResultAutocompleteProps {}

export function ResultAutocomplete(props: ResultAutocompleteProps) {
  const [open, setOpen] = React.useState(false);
  const [total, setTotal] = React.useState<number | null>(null);
  const [selectedValue, setSelectedValue] = React.useState<
    SearchAccountsQuery['searchAccounts']['accounts'][0] | null
  >(null);
  const { slug = '' } = useParams();
  const [localName, setLocalName] = React.useState(slug);
  const [results, setResults] = React.useState<SearchAccountsQuery['searchAccounts']['accounts']>([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [search, { loading }] = useSearchAccountsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ searchAccounts }) => {
      if (searchAccounts) {
        setTotal(searchAccounts.total);
        setResults(searchAccounts.accounts);
      }
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setResults([]);
  };

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setLocalName(value);
  };

  React.useEffect(() => {
    if (slug) {
      const searchOptions = getSearchOptions();

      search(searchOptions).then(({ data }) => {
        if (data?.searchAccounts) {
          if (data.searchAccounts.total) {
            const [account] = data.searchAccounts.accounts;
            setTotal(data.searchAccounts.total);
            setResults(data.searchAccounts.accounts);

            if (data.searchAccounts.accounts.length > 0) {
              setLocalName(account.name);
              setSelectedValue(account);
            }
          } else {
            navigate(`/support/accounts${location.search}`, {
              preventScrollReset: true,
              viewTransition: true,
            });
          }
        }
      });
    }
  }, []);

  React.useEffect(() => {
    const searchOptions = getSearchOptions();
    search(searchOptions);
  }, [localName, searchParams]);

  const getSearchOptions = () => {
    const sort = searchParams.get('sort');
    const type = searchParams.get('type');
    const isActive = searchParams.get('active');
    const isVerified = searchParams.get('verified');
    const isPrivate = searchParams.get('private');
    const isDelete = searchParams.get('delete');

    let spotType = '';

    if (type === 'consumer') {
      spotType = AccountTypeEnum.Consumer;
    } else if (type === 'artist') {
      spotType = AccountTypeEnum.Artist;
    } else if (type === 'spot') {
      spotType = AccountTypeEnum.Spot;
    } else if (type === 'support') {
      spotType = AccountTypeEnum.Support;
    }

    return {
      variables: {
        filters: {
          sort: sort?.length ? sort : undefined,
          name: localName?.length ? localName : undefined,
          type: spotType?.length ? (spotType as AccountTypeEnum) : undefined,
          isActive: isActive?.length ? isActive === 'true' : undefined,
          isVerified: isVerified?.length ? isVerified === 'true' : undefined,
          isPrivate: isPrivate?.length ? isPrivate === 'true' : undefined,
          isDelete: isDelete?.length ? isDelete === 'true' : undefined,
        },
        pagination: { page: 1, limit: 25 },
      },
    };
  };

  return (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      isOptionEqualToValue={(result, value) => {
        return result.name === value.name || result.slug === value.slug;
      }}
      onChange={(e, value) => {
        if (value) {
          setSelectedValue(value);
          navigate(`/support/accounts/${value.slug + location.search}`, {
            preventScrollReset: true,
            viewTransition: true,
          });
        }
      }}
      onInputChange={handleInputChange}
      noOptionsText={localName ? 'Aucun résultat' : 'Commencez à taper...'}
      filterOptions={(x) => x}
      getOptionLabel={(result) => result.name ?? ''}
      options={results}
      loading={loading}
      size="small"
      value={selectedValue}
      renderOption={(props, option, { inputValue }) => {
        const { key, ...optionProps } = props;
        const matches = match(option.name, inputValue, { insideWords: true });
        const parts = parse(option.name, matches);
        const slugMatches = match(option.slug, inputValue, { insideWords: true });
        const slugParts = parse(option.slug, slugMatches);

        return (
          <Box
            {...props}
            key={key}
            component="li"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Stack spacing={1} flex={1} direction="row" justifyContent="flex-start" alignItems="center">
              <Avatar src={option.avatar?.url} sx={{ width: 30, height: 30 }}>
                {option.name[0]}
              </Avatar>
              <Stack>
                <Box>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Box>
                <Stack direction="row" alignItems="center">
                  <Typography variant="caption" color="text.secondary">
                    @
                  </Typography>
                  <Box>
                    {slugParts.map((slugPart, index) => (
                      <span
                        key={index}
                        style={{
                          color: theme.palette.text[slugPart.highlight ? 'primary' : 'secondary'],
                          fontWeight: slugPart.highlight ? 700 : 400,
                        }}
                      >
                        {slugPart.text}
                      </span>
                    ))}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Nom"
          size="small"
          slotProps={{
            input: {
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            },
          }}
        />
      )}
    />
  );
}
