import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import * as Device from 'react-device-detect';

import { Logo, AccountHeader } from '@components';
import { NavTabs } from './NavTabs';

interface HideOnScrollProps {
  children?: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return Device.isDesktop ? (
    children
  ) : (
    <Slide appear={true} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

export function AppBar() {
  const theme = useTheme();

  return (
    <HideOnScroll>
      <MuiAppBar
        position="sticky"
        elevation={0}
        sx={{
          zIndex: Device.isMobileOnly ? theme.zIndex.appBar : theme.zIndex.drawer + 1,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          backdropFilter: 'blur(10px)',
        }}
      >
        <Toolbar>
          <Logo variant="full" size="large" />
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ml: 3 }}
          >
            <NavTabs />
            <AccountHeader />
          </Stack>
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
}
