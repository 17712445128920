import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

interface FiltersButtonsProps {
  handleClick: () => void;
}

interface ApplyFiltersButtonsProps {
  dirty: boolean;
  count: number;
  isValid: boolean;
  handleClick: () => void;
}

export const OpenFilters = (props: { count: number } & FiltersButtonsProps) => {
  const { count, handleClick } = props;
  const theme = useTheme();
  const { t } = useTranslation('cta');

  return (
    <Button
      startIcon={<theme.icons.filter />}
      variant="contained"
      color="uncolored"
      fullWidth
      size="small"
      onClick={handleClick}
    >
      {t(`search.filters count`, { ns: 'common', count })}
    </Button>
  );
};

export const ApplyFilters = (props: ApplyFiltersButtonsProps) => {
  const { count, dirty, isValid, handleClick } = props;
  const theme = useTheme();
  const { t } = useTranslation('cta');

  const isDisabled = !dirty || !isValid || !count;

  return (
    <Button
      disabled={isDisabled}
      startIcon={isDisabled ? null : <theme.icons.check />}
      variant="contained"
      color="success"
      fullWidth
      size="small"
      onClick={handleClick}
    >
      {count > 0 ? t('apply filters count', { count }) : t('no filter selected')}
    </Button>
  );
};

export const ClearFilters = (props: FiltersButtonsProps) => {
  const { handleClick } = props;
  const theme = useTheme();
  const { t } = useTranslation('cta');

  return (
    <Button
      startIcon={<theme.icons.filterClear />}
      variant="outlined"
      color="uncolored"
      fullWidth
      size="small"
      onClick={handleClick}
    >
      {t('clear filters')}
    </Button>
  );
};
