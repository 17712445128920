import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

interface FAQProps {
  FAQs: Array<{
    id: string;
    idx?: number;
    question: string;
    answer: string;
  }>;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} sx={{ backgroundColor: 'transparent' }} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: 'white' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderLeft: `1px solid ${theme.palette.background.paper}`,
  borderRight: `1px solid ${theme.palette.background.paper}`,
  backgroundColor: '#191919',
}));

function FAQ(props: FAQProps) {
  const { FAQs } = props;
  const theme = useTheme();
  const { t } = useTranslation('spot');
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    FAQs.length > 0 && (
      <div>
        <Typography variant="h6" mb={1}>
          {t('title.FAQ')}
        </Typography>
        {FAQs.map((faq, index, allFaqs) => {
          const isFirst = index === 0;
          const isLast = index === allFaqs.length - 1;

          let borderRadius = theme.spacing(0);

          if (isFirst) {
            borderRadius = theme.spacing(1, 1, 0, 0);
          } else if (isLast) {
            borderRadius = expanded === faq.id ? theme.spacing(0) : theme.spacing(0, 0, 1, 1);
          }

          return (
            <Accordion key={faq.id} expanded={expanded === faq.id} onChange={handleChange(faq.id)}>
              <AccordionSummary
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
                sx={{ borderRadius }}
              >
                <Typography
                  fontWeight="bold"
                  color={`text.${expanded === faq.id ? 'primary' : 'secondary'}`}
                  fontSize=".8rem"
                  pr={1}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ borderRadius: isLast && expanded === faq.id ? theme.spacing(0, 0, 1, 1) : 0 }}
              >
                <Typography color="text.primary" fontSize=".7rem">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    )
  );
}

export default React.memo(FAQ);
