import React from 'react';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

export const NameFormField = () => {
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: yup.string().required(),
    validateOnMount: true,
    onSubmit: ({ name }) => {
      setTimeoutId(null);

      if (name.length) {
        searchParams.set('name', name);
      } else {
        searchParams.delete('name');
      }

      setSearchParams(searchParams);
    },
  });

  // init values from params at mount
  React.useEffect(() => {
    const name = searchParams.get('name');
    name?.length && formik.setValues({ name });
  }, []);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    formik.setFieldValue('name', e.target.value);
    const newTmoutId = setTimeout(formik.submitForm, 500);
    setTimeoutId(newTmoutId);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        {...formik.getFieldProps('name')}
        onChange={handleChangeName} // override default onChange
        fullWidth
        placeholder="nom"
        size="small"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <theme.icons.search sx={{ color: theme.palette.text.secondary }} />
              </InputAdornment>
            ),
            endAdornment: timeoutId ? (
              <InputAdornment position="end">
                <CircularProgress size={20} sx={{ color: 'white' }} />
              </InputAdornment>
            ) : null,
          },
        }}
      />
    </form>
  );
};
