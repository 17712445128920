import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

import { ShareMenu } from '../../common';
import useStyles from './styles';
import { HeaderProps } from './types';

function MobileHeader(props: HeaderProps) {
  const { covers, configuration, handleTabChange, activeTab, handleBack } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles(theme);
  const { t } = useTranslation('cta');
  const aspectRatio = isMobile ? '4/3' : '16/9';
  const { isLandscape } = Device.useMobileOrientation();

  return (
    <>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        p={1}
        sx={{
          width: '100%',
          backdropFilter: 'blur(15px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.5),
        }}
      >
        <Stack direction="row" onClick={handleBack} sx={{ cursor: 'pointer' }}>
          <theme.icons.back />
          <Typography> {t('back')}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems={'center'} pr={1}>
          <ShareMenu />
        </Stack>
      </Stack>
      <Stack>
        {/* SLIDER */}
        <Swiper
          className="mySwiper"
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true, type: 'progressbar' }}
          navigation={isLandscape}
          loop={covers.length > 1}
          style={{ width: '100%', aspectRatio }}
        >
          {covers.length > 0 &&
            covers.map((cover) => (
              <SwiperSlide key={cover.id} style={{ width: '100%' }}>
                <img
                  src={cover.url}
                  alt="img"
                  style={{ width: '100%', objectFit: 'cover', objectPosition: 'center', aspectRatio }}
                />
              </SwiperSlide>
            ))}
          {covers.length === 0 && configuration.primaryType && (
            <SwiperSlide style={{ width: '100%' }}>
              <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                <img
                  src={`/spot/${configuration.primaryType}.jpg`}
                  alt="Default cover"
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    aspectRatio,
                    filter: 'grayscale(100%)',
                    opacity: 0.5,
                  }}
                />
              </Stack>
            </SwiperSlide>
          )}
          {covers.length === 0 && !configuration.primaryType && (
            <SwiperSlide style={{ width: '100%' }}>
              <Stack
                width="100%"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: '#000',
                }}
              >
                <theme.icons.noImage />
              </Stack>
            </SwiperSlide>
          )}
        </Swiper>
      </Stack>

      {/* TABS */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="inherit"
        indicatorColor="primary"
        aria-label="tabs-spot-menu"
        variant="fullWidth"
        TabIndicatorProps={{
          sx: styles.tabs,
        }}
      >
        <Tab icon={<theme.icons.home sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} />
        {/* <Tab icon={<theme.icons.restaurant sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} /> */}
        <Tab icon={<theme.icons.event sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} />
        {/* <Tab icon={<theme.icons.feedback sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} /> */}
      </Tabs>
    </>
  );
}

export default MobileHeader;
