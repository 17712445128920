import moment from 'moment';

import { BookingStatusEnum } from '@graphql';
import { Booking, } from './types';

export default function initValues(booking: Booking | null) {
  if (!booking) {
    // creation
    return {
      startDate: moment(),
      startTime: moment().add(1, 'hour').set({ minute: 0, second: 0, millisecond: 0 }),
      participants: "2",
      occasion: '',
      status: BookingStatusEnum.Accepted,
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
    }
  } else if ('client' in booking && booking.client.__typename === 'BookingClient') {
    return {
      startDate: moment(),
      startTime: moment(booking.startTime),
      participants: booking.participants.toString(),
      occasion: booking.occasion ?? '',
      status: booking.status,
      firstname: booking.client.firstname,
      lastname: booking.client.lastname ?? null,
      phone: booking.client.clientPhone,
      email: booking.client.email ?? null,
    }
  } else {
    return { status: booking?.status };
  }
}