import * as React from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

import { GetEventDetailedQuery } from '@graphql';
import LikeButton from './buttons/LikeButton';
import WriteButton from '../../common/WriteButton';

interface IDCardProps {
  event: GetEventDetailedQuery['event'];
  scrollToTickets: () => void;
}

function IDCard(props: IDCardProps) {
  const { event, scrollToTickets } = props;
  const { title, category, likers, tickets } = event;
  const [ticketType, setTicketType] = React.useState<string | null>(null);
  const theme = useTheme();
  const { t } = useTranslation('cta');

  React.useEffect(() => {
    if (!tickets.length) {
      setTicketType('VOID');
    } else if (tickets.length === 1 && tickets[0].price === 0) {
      setTicketType('FREE');
    } else if (tickets.length > 1) {
      setTicketType('MANY');
    }
  }, [tickets]);

  return (
    <Stack spacing={Device.isMobile ? 3 : 5} flex={1}>
      <Stack
        direction={Device.isMobileOnly ? 'column' : 'row'}
        spacing={2}
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" flex={1} width="100%" justifyContent="space-between" alignItems="center">
          <theme.icons.event sx={{ width: 75, height: 75 }} />
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Typography variant="body1" color="textPrimary" fontWeight="bold">
              {getCount(likers.count)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {likers.count > 1 ? 'likes' : 'like'}
            </Typography>
          </Stack>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Typography variant="body1" color="textPrimary" fontWeight="bold">
              {getCount(likers.count)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {likers.count > 1 ? 'billets vendus' : 'billet vendu'}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} flex={1} width="100%">
          {ticketType === 'VOID' ? null : ticketType === 'FREE' ? (
            <Stack
              p={1}
              alignItems="center"
              justifyContent="center"
              sx={{ backgroundColor: alpha(theme.palette.success.dark, 0.4), borderRadius: 1 }}
            >
              <Typography variant="body1" color="textSecondary" fontWeight="bold" textAlign="center">
                {t('free')}
              </Typography>
            </Stack>
          ) : ticketType === 'MANY' ? (
            <Button variant="contained" color="primary" fullWidth onClick={scrollToTickets}>
              {t('tickets')}
            </Button>
          ) : null}
          <Stack spacing={1} direction="row" width="100%">
            <LikeButton eventId={event.id} isLiking={event.isLiking} />
            <WriteButton recipientId={event.creator.id} />
          </Stack>
        </Stack>
      </Stack>
      <Stack flex={1}>
        <Typography variant="h5" color="textPrimary" fontWeight="bold">
          {title}
        </Typography>
        {category?.name && (
          <Typography variant="body1" color="textSecondary" fontWeight="bold">
            {category?.name ? t(`category.${category?.name}`, { ns: 'event' }) : null}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

function getCount(number: number) {
  if (number < 1000) {
    return number;
  } else if (number < 1_000_000) {
    return `${Math.floor(number / 1000)}K`;
  } else if (number < 1_000_000_000) {
    return `${Math.floor(number / 1_000_000)}M`;
  } else if (number < 1_000_000_000_000) {
    return `${Math.floor(number / 1_000_000_000)}B`;
  }
}

export default IDCard;
