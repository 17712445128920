import * as React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridRowModesModel,
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
  GridValueOptionsParams,
  GridRowModes,
} from '@mui/x-data-grid';
import moment from 'moment';
import { frFR, esES } from '@mui/x-data-grid/locales';

import {
  AccountTypeEnum,
  BookingStatusEnum,
  useListMySpotBookingsLazyQuery,
  ListMySpotBookingsQuery,
  DateRangeInput,
  useUpdateBookingMutation,
  BookingOccasionEnum,
} from '@graphql';
import WeekPicker from '../week-picker';
import StripedDataGrid from '../StripedDataGrid';

export default function BookingDataGrid() {
  const theme = useTheme();
  const [dateRange, setDateRange] = React.useState<DateRangeInput | null>(null);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const { t, i18n } = useTranslation('cta');

  const [getMyBookings, { data, loading }] = useListMySpotBookingsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateBooking] = useUpdateBookingMutation();

  const handleDateRangeChange = (dateRange: { from: string; to: string }) => {
    setDateRange(dateRange);
  };

  React.useEffect(() => {
    if (dateRange) {
      getMyBookings({ variables: { dateRange } });
    }
  }, [dateRange]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleClickEdit = (id: string) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.Edit },
    }));
  };
  const handleClickValidate = (id: string) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getLocale = () => {
    switch (i18n.language) {
      case 'fr':
        return frFR.components.MuiDataGrid.defaultProps.localeText;
      case 'es':
        return esES.components.MuiDataGrid.defaultProps.localeText;
      default:
        return undefined; // Par défaut, le DataGrid utilisera l'anglais
    }
  };

  const EditAction = ({ id }: { id: string }) => (
    <Tooltip title={t('edit')}>
      <GridActionsCellItem
        icon={<theme.icons.edit />}
        label="Edit"
        className="textPrimary"
        onClick={handleClickEdit(id)}
        color="inherit"
        sx={{
          color: theme.palette.text.secondary,
          '&:hover': {
            color: 'white',
          },
        }}
      />
    </Tooltip>
  );

  const ValidateAction = ({ id }: { id: string }) => (
    <Tooltip title={t('validate')}>
      <GridActionsCellItem
        icon={<theme.icons.publish />}
        label="Validate"
        className="textPrimary"
        onClick={handleClickValidate(id)}
        color="success"
        sx={{
          color: theme.palette.text.secondary,
          '&:hover': {
            color: 'white',
          },
        }}
      />
    </Tooltip>
  );

  const rows = (data?.myBookings || []).map((booking) => ({
    id: booking.id,
    client: getClientName(booking),
    avatar: getClientAvatar(booking),
    clientId: booking.client.id,
    age:
      booking.client.__typename === 'Consumer' && booking.client.user.birthdate
        ? moment(booking.client.user.birthdate).fromNow(true)
        : t(`word.null`, { ns: 'common' }),
    participants: booking.participants,
    occasion: booking.occasion ?? '',
    gender:
      booking.client.__typename === 'Consumer'
        ? t(`gender.${booking.client?.user.gender}`, { ns: 'common' })
        : t(`word.null`, { ns: 'common' }),
    startTime: booking.startTime,
    status: booking.status,
    phone: getClienPhone(booking),
    creator: t(`origin.${booking.origin}`, { ns: 'booking' }),
    origin: booking.origin,
  }));

  const columns: GridColDef[] = [
    { field: 'id', headerClassName: 'f6r-app-theme--header', headerName: 'ID', width: 90, hideable: true },
    {
      field: 'startTime',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Date', { ns: 'booking' }),
      minWidth: 300,
      type: 'dateTime',
      editable: true,
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${moment(value).format('LLLL')}`;
      },
    },
    {
      field: 'status',
      headerClassName: 'f6r-app-theme--header',
      headerName: 'Status',
      type: 'singleSelect',
      minWidth: 120,
      editable: true,
      valueOptions: (params: GridValueOptionsParams) => {
        const { status } = params.row;
        const options = [status];

        const isPassed = moment().isAfter(params.row.date);

        if (isPassed && status === BookingStatusEnum.Accepted) {
          options.push(BookingStatusEnum.NoShow);
        } else if (!isPassed) {
          if (status === BookingStatusEnum.Pending) {
            options.push(BookingStatusEnum.Accepted, BookingStatusEnum.Rejected);
          } else if (status === BookingStatusEnum.Accepted) {
            options.push(BookingStatusEnum.Canceled);
          } else if ([BookingStatusEnum.Rejected, BookingStatusEnum.Canceled].includes(status)) {
            options.push(BookingStatusEnum.Accepted);
          } else if (status === BookingStatusEnum.NoShow) {
            options.push(BookingStatusEnum.Accepted);
          }
        }

        return options.map((option) => ({
          value: option,
          label: t(`status.${option}`, { ns: 'booking' }),
        }));
      },

      renderCell: (params) => {
        return (
          <Chip
            label={t(`status.${params.value}`, { ns: 'booking' })}
            color={getStatusColor(params.value as BookingStatusEnum)}
          />
        );
      },
    },
    {
      field: 'creator',
      type: 'string',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Creator', { ns: 'booking' }),
      minWidth: 100,
      editable: false,
    },
    {
      field: 'client',
      type: 'string',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Client', { ns: 'booking' }),
      minWidth: 250,
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar src={params.row.avatar} alt={params.row.client} sx={{ width: 30, height: 30 }} />
            <span>{params.row.client}</span>
          </Stack>
        );
      },
    },
    {
      field: 'phone',
      type: 'string',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Phone', { ns: 'booking' }),
      minWidth: 150,
      editable: true,
      valueFormatter: (value: string | null) => {
        if (!value) {
          return t(`word.null`, { ns: 'common' });
        }

        return value.replace(/(\d{2})(?=\d)/g, '$1 ');
      },
    },
    {
      field: 'age',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Age', { ns: 'booking' }),
      minWidth: 150,
      editable: true,
    },
    {
      field: 'gender',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Gender', { ns: 'booking' }),
      minWidth: 150,
      editable: true,
    },
    {
      field: 'participants',
      type: 'number',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Participants', { ns: 'booking' }),
      minWidth: 150,
      editable: true,
    },
    {
      field: 'occasion',
      type: 'singleSelect',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Occasion', { ns: 'booking' }),
      minWidth: 200,
      editable: true,
      valueOptions: [
        { value: '', label: t(`word.null`, { ns: 'common' }) },
        ...Object.values(BookingOccasionEnum).map((option) => ({
          value: option,
          label: t(`occasion.${option}`, { ns: 'booking' }),
        })),
      ],
    },
    {
      field: 'actions',
      type: 'actions',
      headerClassName: 'f6r-app-theme--header-actions',
      headerName: t('dataGrid.Actions', { ns: 'event' }),
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: (params) => {
        const { id, row } = params;
        const isEditing = rowModesModel[id]?.mode === GridRowModes.Edit;
        const Action = isEditing ? ValidateAction : EditAction;

        return [<Action id={row.id} />];
      },
    },
  ];

  return (
    <Stack spacing={3}>
      <WeekPicker handleDateRangeChange={handleDateRangeChange} />
      <Paper elevation={10} sx={{ height: '100%', width: '100%' }}>
        <StripedDataGrid
          editMode="row"
          loading={loading}
          rows={rows}
          checkboxSelection={false}
          columns={columns}
          isCellEditable={(params) => {
            const { field, value, row, isEditable } = params;
            const isSpotCreator = row.origin === AccountTypeEnum.Spot;

            switch (field) {
              case 'status': {
                if (moment().isAfter(row.date)) {
                  return BookingStatusEnum.Accepted === (value as unknown as BookingStatusEnum);
                } else {
                  return true;
                }
              }
              case 'startTime':
              case 'age':
              case 'client':
              case 'phone':
              case 'gender':
              case 'participants':
              case 'occasion':
                return isSpotCreator;
              default:
                return false;
            }
          }}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          getRowId={(row) => row.id}
          columnVisibilityModel={{
            id: false,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            sorting: {
              sortModel: [{ field: 'startTime', sort: 'asc' }],
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          onProcessRowUpdateError={(error) => {
            import.meta.env.DEV && console.error(`[{PROCESS ERROR}]:`, error);
          }}
          processRowUpdate={async (updatedRow, originalRow) => {
            const updatedFields = Object.keys(updatedRow).filter(
              (key) => updatedRow[key] !== originalRow[key]
            );

            if (updatedFields.length > 0) {
              const updatedFieldsObject = updatedFields.reduce(
                (acc: Record<string, any>, key) => {
                  // client field case
                  if (['firstname', 'lastname', 'phone', 'email'].includes(key)) {
                    if (['firstname', 'phone'].includes(key) && !key.length) {
                      return acc;
                    }
                    if (!acc.client) {
                      acc.client = { id: updatedRow.clientId };
                    }
                    acc.client[key] = updatedRow[key];
                  } else {
                    // other fields case
                    acc[key] = updatedRow[key];
                  }

                  return acc;
                },
                { client: null }
              );

              const { data } = await updateBooking({
                variables: {
                  input: {
                    id: updatedRow.id,
                    ...updatedFieldsObject,
                  },
                },
              });

              if (data?.updateBooking) {
                return updatedRow;
              }
            }

            return originalRow;
          }}
          localeText={getLocale()}
          autoHeight
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: theme.palette.grey[700],
          }}
        />
      </Paper>
    </Stack>
  );
}

function getClientName(booking: ListMySpotBookingsQuery['myBookings'][0]) {
  let clientName = '';

  if (booking.client.__typename === 'BookingClient') {
    clientName = booking.client.firstname;

    if (booking.client.lastname) {
      clientName += ` ${booking.client.lastname}`;
    }
  } else if (booking.client.__typename === 'Consumer') {
    clientName = booking.client.user.firstname + ' ' + booking.client.user.lastname;
  }

  return clientName;
}

function getClientAvatar(booking: ListMySpotBookingsQuery['myBookings'][0]) {
  let avatar = '';

  if (booking.client.__typename === 'Consumer') {
    avatar = booking.client.avatar?.url ?? '';
  }

  return avatar;
}

function getClienPhone(booking: ListMySpotBookingsQuery['myBookings'][0]) {
  let phone = '';

  if (booking.client.__typename === 'BookingClient') {
    phone = booking.client.clientPhone;
  } else if (booking.client.__typename === 'Consumer') {
    phone = booking.client.user.mobilePhone ?? '';
  }

  return phone;
}

function getStatusColor(status: BookingStatusEnum) {
  switch (status) {
    case BookingStatusEnum.Accepted:
      return 'success';
    case BookingStatusEnum.Pending:
      return 'warning';
    case BookingStatusEnum.Rejected:
      return 'error';
    case BookingStatusEnum.Canceled:
      return 'error';
    default:
      return 'uncolored';
  }
}
