import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

import { useListEventCategoriesQuery, ListEventCategoriesQuery } from '@graphql';

type Categories = Array<ListEventCategoriesQuery['eventCategories'][0] & { label: string }>;

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(category: string, selected: string[], theme: Theme) {
  return {
    fontWeight: selected.includes(category)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export function EventCategoryField() {
  const [categories, setCategories] = React.useState<Categories>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('event');
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    if (value.includes('')) {
      setSelected(categories.map((c) => c.name));
    } else {
      setSelected(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const handleClose = (event: React.SyntheticEvent<Element, Event>) => {
    const sCategories = searchParams.get('categories')?.split(',') ?? [];

    // case where selected has been reset
    if (sCategories.length > 0 && selected.length === 0) {
      searchParams.delete('categories');
      setSearchParams(searchParams);
      return;
    }

    // case where selected has been updated
    if (sCategories.length !== selected.length || sCategories.some((c) => !selected.includes(c))) {
      searchParams.set('categories', selected.join(','));
      setSearchParams(searchParams);
      return;
    }
  };

  const { loading, called } = useListEventCategoriesQuery({
    fetchPolicy: 'cache-first',
    onCompleted({ eventCategories }) {
      setCategories(eventCategories.map((c) => ({ ...c, label: t(`category.${c.name}`) })));
    },
  });

  React.useEffect(() => {
    const sCategories = searchParams.get('categories')?.split(',') ?? [];

    if (sCategories.length > 0) {
      const { valid, invalid } = sCategories.reduce(
        (acc, category) => {
          if (categories.find((c) => c.name === category)) {
            acc.valid.push(category);
          } else {
            acc.invalid.push(category);
          }
          return acc;
        },
        { valid: [] as string[], invalid: [] as string[] }
      );

      if (valid.length && invalid.length) {
        searchParams.set('categories', valid.join(','));
        setSearchParams(searchParams);
      } else if (valid.length) {
        setSelected(valid);
      }
    }
  }, [searchParams]);

  if (called && !loading && categories.length === 0) {
    return null;
  }

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="event-category-select">{t('searchEventCategory.label', { ns: 'field' })}</InputLabel>
      {loading ? (
        <LinearProgress />
      ) : (
        <Select
          multiple
          labelId="event-category-select"
          id="event-category-select-small"
          value={selected}
          label={t('searchEventCategory.label', { ns: 'field' })}
          onChange={handleChange}
          onClose={handleClose}
          MenuProps={MenuProps}
        >
          <MenuItem value="">
            <em>{t('searchEventCategory.emptyState', { ns: 'field' })}</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem
              key={category.id}
              value={category.name}
              style={getStyles(category.name, selected, theme)}
            >
              {category.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}
