import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { Spot, useUpdateSpotPricingMutation, SpotPricingEnum } from '@graphql';
import { useToastError } from '@hooks';

const STEP = 10;
const MIN = 10;
const MAX = 1000;

interface AverageTicketProps {
  handleSwiperTouchMove: (isActive: boolean) => void;
  account: Spot;
}

export default function AverageTicket(props: AverageTicketProps) {
  const { account, handleSwiperTouchMove } = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateSpotPricing] = useUpdateSpotPricingMutation();

  const initialValues = { pricing: account.configuration.pricing };
  const validationSchema = yup.object({
    pricing: yup.string().oneOf(Object.values(SpotPricingEnum)).nullable().defined(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateSpotPricing({
        variables: { pricing: values.pricing as SpotPricingEnum },
        onCompleted({ updateSpotPricing }) {
          formik.resetForm({ values });
          toast(t('spotAverageTicket.toast.success', { ns: 'field' }), { type: 'success' });
        },
        onError: toastError,
      });
    },
  });

  if (account?.__typename !== 'Spot') {
    return null;
  }

  const handleEdit = () => {
    handleSwiperTouchMove(false);
    setIsEditing(true);
  };

  const handleCancel = () => {
    formik.resetForm({ values: initialValues });
    setIsEditing(false);
    handleSwiperTouchMove(true);
  };

  const handleSetVal = (averageTicket: number) => {
    formik.setFieldValue('averageTicket', averageTicket);
    formik.setFieldTouched(
      'averageTicket',
      (account.configuration.averageTicket as number) !== averageTicket
    );
  };

  const handleValidate = () => {
    formik.submitForm();
    setIsEditing(false);
    handleSwiperTouchMove(false);
  };
  return (
    <Stack width="100%" spacing={1}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack>
          <Typography fontWeight="bold">{t('spotAverageTicket.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotAverageTicket.caption', { ns: 'field' })}
          </Typography>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="flex-start">
          {isEditing ? (
            <>
              <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                {t('cancel')}
              </Button>
              <Button
                disabled={!formik.dirty || !formik.isValid}
                onClick={handleValidate}
                variant="contained"
                color="success"
                size="small"
                type="submit"
              >
                {t('validate')}
              </Button>
            </>
          ) : (
            <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
              {t('edit')}
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack>
        <Slider
          sx={{ mt: 5 }}
          name="averageTicket"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.averageTicket}
          defaultValue={formik.values.averageTicket}
          valueLabelDisplay="on"
          step={STEP}
          min={MIN}
          max={MAX}
          disabled={!isEditing}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2" onClick={() => handleSetVal(MIN)} sx={{ cursor: 'pointer' }}>
            {MIN}€
          </Typography>
          <Typography variant="body2" onClick={() => handleSetVal(MAX)} sx={{ cursor: 'pointer' }}>
            +{MAX}€
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
