import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import { GetEventDetailedQuery } from '@graphql';
import { ShareMenu } from '../common';

interface HeaderProps {
  handleBack: (e: React.SyntheticEvent, reason: string) => void;
  covers: GetEventDetailedQuery['event']['covers'];
}

function Header(props: HeaderProps) {
  const { covers = [] } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('cta');
  const aspectRatio = isMobile ? '4/3' : '16/9';

  return (
    <>
      {/* SLIDER */}
      <Swiper
        className="mySwiper"
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true, type: 'progressbar' }}
        navigation={true}
        loop={covers.length > 1}
        style={{ width: '100%', aspectRatio }}
      >
        {covers.length > 0 ? (
          covers.map((cover) => (
            <SwiperSlide key={cover.id} style={{ width: '100%' }}>
              <img
                src={cover.url}
                alt="img"
                style={{
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  aspectRatio,
                }}
              />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide style={{ width: '100%' }}>
            <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
              Festayre Event
            </Stack>
          </SwiperSlide>
        )}
      </Swiper>

      {/* HEADER */}
      <Stack
        direction="row"
        justifyContent={'space-between'}
        p={1}
        sx={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          backdropFilter: 'blur(15px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.5),
        }}
      >
        <Stack direction="row" onClick={props.handleBack} sx={{ cursor: 'pointer' }}>
          <theme.icons.back />
          <Typography> {t('back')}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems={'center'} pr={1}>
          <ShareMenu />
        </Stack>
      </Stack>
    </>
  );
}

export default React.memo(Header);
