import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import * as Device from 'react-device-detect';

export default function SpotSkeleton() {
  return (
    <Stack spacing={0} p={0} m={0} width="100%" height="100%" justifyContent="flex-start">
      {/* HEADER */}
      <Stack width="100%" sx={{ aspectRatio: '16/9' }} />
      {/* ID CARD */}
      <Stack
        direction={Device.isMobile ? 'column' : 'row'}
        flex={1}
        spacing={2}
        p={Device.isMobile ? 2 : 5}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" flex={1} width="100%" justifyContent="space-between" alignItems="center">
          {/* AVATAR */}
          <Skeleton animation="wave" variant="circular" width={100} height={100} />
          {/* COUNTS */}
          <Stack alignItems="center">
            <Skeleton animation="wave" variant="text" width={50} height={50} />
            <Skeleton animation="wave" variant="text" width={75} height={25} />
          </Stack>
          <Stack alignItems="center">
            <Skeleton animation="wave" variant="text" width={50} height={50} />
            <Skeleton animation="wave" variant="text" width={75} height={25} />
          </Stack>
        </Stack>
        {/* CTA */}
        <Stack flex={1} width="100%">
          <Skeleton animation="wave" width="100%" height={40} />
          <Stack direction="row" spacing={2}>
            <Skeleton animation="wave" width="100%" height={40} />
            <Skeleton animation="wave" width="100%" height={40} />
          </Stack>
        </Stack>
      </Stack>
      {/* Content */}
      <Stack flex={1} p={Device.isMobile ? 2 : 5}>
        <Skeleton animation="wave" variant="" width="30%" height={25} />
        <Stack p={0} m={0}>
          <Stack direction="row" spacing={2}>
            <Skeleton animation="wave" width="100%" height={75} />
            {Device.isDesktop && <Skeleton animation="wave" width="100%" height={75} />}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Skeleton animation="wave" width="100%" height={75} />
            {Device.isDesktop && <Skeleton animation="wave" width="100%" height={75} />}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Skeleton animation="wave" width="100%" height={75} />
            {Device.isDesktop && <Skeleton animation="wave" width="100%" height={75} />}
          </Stack>
        </Stack>
      </Stack>
      <Stack flex={1} p={Device.isMobile ? 2 : 5}>
        <Skeleton animation="wave" variant="" width="30%" height={25} />
        <Stack p={0} m={0}>
          <Skeleton animation="wave" width="100%" height={75} />
          <Skeleton animation="wave" width="100%" height={75} />
          <Skeleton animation="wave" width="100%" height={75} />
          <Skeleton animation="wave" width="100%" height={75} />
        </Stack>
      </Stack>
    </Stack>
  );
}
