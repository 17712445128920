import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import { GetConsumerDetailedQuery } from '@graphql';
import { EmptyState } from '../../common';

interface MemoriesProps {
  consumer: GetConsumerDetailedQuery['consumer'];
}

function Memories(props: MemoriesProps) {
  const { consumer } = props;
  const theme = useTheme();
  const { t } = useTranslation('account');

  return (
    <Stack spacing={5} p={3}>
      <EmptyState text={t('text.No memory yet')} />
    </Stack>
  );
}

export default React.memo(Memories);
