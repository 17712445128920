import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

interface TriStateCheckboxProps {
  label: string;
  dataKey: string;
  formik: FormikProps<any>;
}

export const TriStateCheckbox = React.memo((props: TriStateCheckboxProps) => {
  const { dataKey, label, formik } = props;
  const { t } = useTranslation('common');

  const getLabel = () => {
    switch (formik.values[dataKey]) {
      case true:
        return t('word.yes');
      case false:
        return t('word.no');
      default:
        return t('word.null').toLowerCase();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const value = formik.values[dataKey];
    const nextValue = value === true ? false : value === false ? null : true;
    formik.setFieldValue(dataKey, nextValue);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton role={undefined} onClick={handleClick} dense>
        <ListItemText id={`checkbox-list-label-${dataKey}`} primary={label} />
        <ListItemIcon>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values[dataKey] === true} // Checkbox appears checked only for `true`
                  indeterminate={formik.values[dataKey] === null} // Show indeterminate state for `null`
                  checkedIcon={<CheckBoxIcon color="success" />} // checked icon
                  icon={<DisabledByDefaultIcon color="error" />} // unchecked icon
                  indeterminateIcon={<CropSquareIcon color="uncolored" />} // indeterminate icon
                />
              }
              label={
                <Typography component="span" variant="body2" color="textSecondary" fontSize=".8rem">
                  {getLabel()}
                </Typography>
              }
              labelPlacement="start"
            />
          </FormGroup>
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
});
