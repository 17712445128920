import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { useToastError } from '@hooks';
import { Spot, useSupdateSpotCloseSpacesMutation } from '@graphql';

const STEP = 1;
const MIN = 0;
const MAX = 20;

interface CloseSpacesProps {
  account: Spot;
}

export default function CloseSpaces(props: CloseSpacesProps) {
  const { account } = props;
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateCloseSpaces] = useSupdateSpotCloseSpacesMutation();

  const initialValues = { closeSpaces: account.configuration.closeSpaces ?? 1 };
  const validationSchema = yup.object({
    closeSpaces: yup.number().min(0).max(100).required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateCloseSpaces({
        variables: { id: account.id, value: values.closeSpaces },
        onCompleted({ supdateSpotCloseSpaces }) {
          setTimeoutId(null);
          formik.resetForm({ values });
          toast(t('spotCloseSpaces.toast.success', { ns: 'field' }), { type: 'success' });
        },
        onError: toastError,
      });
    },
  });

  const handleSetVal = (closeSpaces: number) => {
    formik.setFieldValue('closeSpaces', closeSpaces);
    formik.setFieldTouched('closeSpaces', (account.configuration.closeSpaces as number) !== closeSpaces);
  };

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    if (formik.dirty && formik.isValid) {
      const newTmoutId = setTimeout(formik.submitForm, 500);
      setTimeoutId(newTmoutId);
    }
  }, [formik.dirty, formik.isValid, formik.values]);

  return (
    <Stack width="100%" spacing={1}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack>
          <Typography fontWeight="bold">{t('spotCloseSpaces.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotCloseSpaces.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>
      <Stack px={2}>
        <Slider
          sx={{ mt: 5 }}
          name="closeSpaces"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.closeSpaces}
          defaultValue={formik.values.closeSpaces}
          valueLabelDisplay="on"
          step={STEP}
          min={MIN}
          max={MAX}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2" onClick={() => handleSetVal(MIN)} sx={{ cursor: 'pointer' }}>
            {MIN}
          </Typography>
          <Typography variant="body2" onClick={() => handleSetVal(MAX)} sx={{ cursor: 'pointer' }}>
            +{MAX}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
