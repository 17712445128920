import * as yup from 'yup';
import { TFunction } from 'i18next';

export default function getValidationSchema(t: TFunction) {
  const boolean = yup.boolean().nullable();

  return yup.object({
    // Common filters
    name: yup.string(),
    location: yup.string(),
    // Atomsphere
    calm: boolean,
    lively: boolean,
    dancing: boolean,
    // Type
    bar: boolean,
    cafe: boolean,
    nightClub: boolean,
    restaurant: boolean,

    // Advanced filters
    // Bringable
    dogs: boolean,
    food: boolean,
    drinks: boolean,
    birthdayCake: boolean,
    // Configuration
    minCapacity: yup.number().min(1).nullable(),
    maxCapacity: yup.number().nullable(),
    minCloseSpace: yup.number().min(0).nullable(),
    maxCloseSpace: yup.number().nullable(),
    // Equipment
    amphitheater: boolean,
    auditorium: boolean,
    sono: boolean,
    wifi: boolean,
    stage: boolean,
    video: boolean,
    locker: boolean,
    terrace: boolean,
    toilets: boolean,
    prmAccess: boolean,
    dancefloor: boolean,
    microphone: boolean,
    prmToilets: boolean,
    smokingRoom: boolean,
    parking: boolean,
    changingTable: boolean,
    // Highlight
    mountainView: boolean,
    oceanView: boolean,
    rooftop: boolean,
    seaView: boolean,
    sunset: boolean,
    sunrise: boolean,
    // Payment
    amex: boolean,
    cash: boolean,
    checks: boolean,
    blueCard: boolean,
    mealTickets: boolean,
    // Service
    dj: boolean,
    karaoke: boolean,
    ownMusic: boolean,
    liveMusic: boolean,
    entertainmentShow: boolean,
    takeout: boolean,
    // Suitable
    bachelor: boolean,
    birthday: boolean,
    farewell: boolean,
    teamMeal: boolean,
    wendding: boolean,
    afterwork: boolean,
    exposition: boolean,
    cocktailPro: boolean,
    fashionShow: boolean,
    businessMeal: boolean,
    companyEvent: boolean,
    friendsDrink: boolean,
    studentParty: boolean,
    productLaunch: boolean,
    kids: boolean,
    watchSport: boolean,
    telework: boolean,
    veggie: boolean,
    vegan: boolean,
  }).required();
}