import * as React from 'react';
import { Theme, alpha, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment, { Moment } from 'moment-timezone';

import { Spot, useSupdateSpotScheduleMutation, SpotSchedulesOpening } from '@graphql';
import { useToastError } from '@hooks';

interface SpotSchedulesProps {
  account: Spot;
}

interface SpotScheduleProps {
  accountId: string;
  day: number;
  schedules: Record<number, SpotSchedulesOpening>;
  setSchedules: React.Dispatch<React.SetStateAction<Record<number, SpotSchedulesOpening>>>;
}

export function SpotSchedules(props: SpotSchedulesProps) {
  const { account } = props;
  const [schedules, setSchedules] = React.useState<Record<number, SpotSchedulesOpening>>(
    account.opening.reduce((acc, curr) => {
      acc[curr.dayOfWeek] = curr;
      return acc;
    }, {} as Record<number, SpotSchedulesOpening>)
  );
  const { t } = useTranslation('cta');

  if (account?.__typename !== 'Spot') {
    return null;
  }

  return (
    <Stack width="100%" spacing={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 3,
          borderRadius: (theme: Theme) => theme.borderRadius,
          backgroundColor: alpha('#fff', 0.1),
        }}
      >
        <Stack>
          <Typography fontWeight="bold">{t('spotSchedules.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotSchedules.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        spacing={5}
        justifyContent="center"
        divider={<Divider sx={{ backgroundColor: (theme: Theme) => theme.palette.grey[800] }} />}
      >
        <SpotSchedule accountId={account.id} day={1} schedules={schedules} setSchedules={setSchedules} />
        <SpotSchedule accountId={account.id} day={2} schedules={schedules} setSchedules={setSchedules} />
        <SpotSchedule accountId={account.id} day={3} schedules={schedules} setSchedules={setSchedules} />
        <SpotSchedule accountId={account.id} day={4} schedules={schedules} setSchedules={setSchedules} />
        <SpotSchedule accountId={account.id} day={5} schedules={schedules} setSchedules={setSchedules} />
        <SpotSchedule accountId={account.id} day={6} schedules={schedules} setSchedules={setSchedules} />
        <SpotSchedule accountId={account.id} day={0} schedules={schedules} setSchedules={setSchedules} />
      </Stack>
    </Stack>
  );
}

function SpotSchedule(props: SpotScheduleProps) {
  const { accountId, day, schedules, setSchedules } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const initialValues = {
    dayOfWeek: day,
    isOpen: Boolean(schedules[day].openTime) && Boolean(schedules[day].closingTime),
    isBreak: Boolean(schedules[day].breakIn) && Boolean(schedules[day].breakOut),
    openTime: schedules[day].openTime !== null ? moment(schedules[day].openTime, 'HH:mm:ss') : null,
    closingTime: schedules[day].closingTime !== null ? moment(schedules[day].closingTime, 'HH:mm:ss') : null,
    breakIn: schedules[day].breakIn !== null ? moment(schedules[day].breakIn, 'HH:mm:ss') : null,
    breakOut: schedules[day].breakOut !== null ? moment(schedules[day].breakOut, 'HH:mm:ss') : null,
  };

  const validationSchema = yup.object({
    dayOfWeek: yup.number().oneOf([0, 1, 2, 3, 4, 5, 6]).required(),
    isOpen: yup.boolean().required(),
    isBreak: yup.boolean().required(),
    openTime: yup.string().nullable(),
    closingTime: yup.string().nullable(),
    breakIn: yup.string().nullable(),
    breakOut: yup.string().nullable(),
  });

  const [updateSchedule] = useSupdateSpotScheduleMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        dayOfWeek: values.dayOfWeek,
        openTime: values.openTime ? values.openTime.format('HH:mm:ss') : null,
        closingTime: values.closingTime ? values.closingTime.format('HH:mm:ss') : null,
        breakIn: values.breakIn ? values.breakIn.format('HH:mm:ss') : null,
        breakOut: values.breakOut ? values.breakOut.format('HH:mm:ss') : null,
      };

      updateSchedule({
        refetchQueries: ['GetSpotDetailed'],
        variables: { id: accountId, input: payload },
        onCompleted({ supdateSpotSchedule }) {
          setSchedules({ ...schedules, [day]: { ...schedules[day], ...payload } });
          formik.resetForm({ values });
          toast.success('Horaires mis à jour avec succès');
        },
        onError: toastError,
      });
    },
  });

  const handleOpenChange = (event: React.SyntheticEvent, checked: boolean) => {
    formik.setFieldValue('isOpen', checked);

    if (!checked) {
      formik.setFieldValue('openTime', null);
      formik.setFieldValue('closingTime', null);
      formik.setFieldValue('breakIn', null);
      formik.setFieldValue('breakOut', null);
    }
  };

  const handleBreakChange = (event: React.SyntheticEvent, checked: boolean) => {
    formik.setFieldValue('isBreak', checked);

    if (!checked) {
      formik.setFieldValue('breakIn', null);
      formik.setFieldValue('breakOut', null);
    }
  };

  const handleTimeChange = (field: string, value: Moment | null) => {
    formik.setFieldValue(field, value);
  };

  const handleOpenCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCopy = (dayTarget: number) => {
    const toCopy = { ...schedules[day] };

    const copy = {
      dayOfWeek: dayTarget,
      openTime: toCopy.openTime,
      closingTime: toCopy.closingTime,
      breakIn: toCopy.breakIn,
      breakOut: toCopy.breakOut,
    };

    updateSchedule({
      variables: { id: accountId, input: copy },
      onCompleted({ supdateSpotSchedule }) {
        setSchedules({ ...schedules, [dayTarget]: { ...schedules[dayTarget], ...copy } });
        toast.success('Horaires copiée avec succès');
      },
      onError: toastError,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack spacing={5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h6" fontWeight="bold">
            {moment().day(day).format('dddd')}
          </Typography>

          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <FormControlLabel
              control={<Switch color="success" />}
              label={formik.values.isOpen ? 'Ouvert' : 'Fermé'}
              labelPlacement="end"
              checked={formik.values.isOpen}
              onChange={handleOpenChange}
            />
            <FormControlLabel
              control={<Switch color="success" />}
              label="Coupure"
              labelPlacement="end"
              checked={formik.values.isBreak}
              disabled={!formik.values.isOpen}
              onChange={handleBreakChange}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack direction="row" spacing={2}>
            {!formik.dirty && formik.isValid && (
              <>
                <Button
                  color="inherit"
                  size="small"
                  startIcon={<theme.icons.copy />}
                  onClick={handleOpenCopy}
                >
                  Copier ces horaires
                </Button>
                <Popover
                  id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 0].map((day) => (
                    <MenuItem key={day} onClick={() => handleCopy(day)} disabled={day === props.day}>
                      {moment().day(day).format('dddd')}
                    </MenuItem>
                  ))}
                </Popover>
              </>
            )}
            {formik.dirty && (
              <Button variant="contained" color="uncolored" size="small" onClick={formik.resetForm}>
                annuler
              </Button>
            )}
            {formik.dirty && formik.isValid && (
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<theme.icons.check />}
                onClick={formik.submitForm}
                loading={formik.isSubmitting}
                loadingPosition="start"
              >
                Valider
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="space-between">
        <TimePicker
          label="Ouverture"
          value={formik.values.openTime}
          onChange={(value) => handleTimeChange('openTime', value)}
          disabled={!formik.values.isOpen}
        />
        <TimePicker
          label="Coupure"
          value={formik.values.breakIn}
          onChange={(value) => handleTimeChange('breakIn', value)}
          disabled={!formik.values.isOpen || !formik.values.isBreak}
          shouldDisableTime={(time) => time.isBefore(formik.values.openTime)}
        />
        <TimePicker
          label="Reprise"
          value={formik.values.breakOut}
          onChange={(value) => handleTimeChange('breakOut', value)}
          disabled={!formik.values.isOpen || !formik.values.isBreak}
          shouldDisableTime={(time) => {
            if (formik.values.breakIn) {
              return time.isBefore(formik.values.breakIn);
            } else {
              return time.isBefore(formik.values.openTime);
            }
          }}
        />
        <TimePicker
          label="Fermeture"
          value={formik.values.closingTime}
          onChange={(value) => handleTimeChange('closingTime', value)}
          disabled={!formik.values.isOpen}
        />
      </Stack>
    </Stack>
  );
}
