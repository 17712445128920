import { useSearchParams, Link } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import _ from 'lodash';

import { useAppContext } from '@context';
import { useSession } from '@hooks';
import { AvatarCustom } from '@components';
import { GetConversationQuery } from '@graphql';
import { getConversationTitle } from '@utils';

interface HeaderProps {
  handleCloseDialog: () => void;
  conversation: GetConversationQuery['conversation'];
}

export function Header(props: HeaderProps) {
  const { handleCloseDialog, conversation } = props;
  const theme = useTheme();
  const { state } = useAppContext();
  const { user, account } = useSession();
  const [searchParams, setSearchParams] = useSearchParams();

  if (!user || !account || state.conversationId === null || !conversation) {
    return null;
  }

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    const participant = conversation.members.find((m) => m.id !== account.id);

    if (!participant) {
      return;
    }

    e.preventDefault();
    setSearchParams(
      {
        type: participant.__typename.toLowerCase(),
        q: participant.slug,
        tab: 'preview',
      },
      { preventScrollReset: true }
    );
  };

  const Duo = () => (
    <Typography
      component={Link}
      to={`?type=spot&q=${account.slug}`}
      onClick={handleOnClick}
      variant="body2"
      color="text.secondary"
      width="100%"
      sx={{
        alignSelf: 'flex-start',
        width: 'auto',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': { color: 'white' },
      }}
    >
      {'@' + conversation.members.find((m) => m.id !== account.id)?.slug}
    </Typography>
  );

  const Group = () => (
    <Typography variant="body2" color="text.secondary" width="100%">
      {conversation.members
        .filter((m) => m.id !== account.id)
        .map((m) => m.name)
        .join(', ')}
    </Typography>
  );

  return (
    <DialogTitle>
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" mb={1}>
        {conversation.members.length > 2 ? (
          <theme.icons.people
            sx={{
              width: 40,
              height: 40,
            }}
          />
        ) : (
          <AvatarCustom
            ressource={conversation.members.find((m) => m.id !== account.id)?.avatar?.url || null}
            id={conversation.id}
            type="account"
            sx={{
              width: 40,
              height: 40,
            }}
          />
        )}
        <Stack spacing={0} alignItems="center" width="100%">
          <Typography variant="h5" fontWeight="bold" width="100%">
            {getConversationTitle(account.id, conversation)}
          </Typography>
          {conversation.members.length === 2 ? <Duo /> : <Group />}
        </Stack>
        <theme.icons.close onClick={handleCloseDialog} sx={{ cursor: 'pointer' }} color="disabled" />
      </Stack>
    </DialogTitle>
  );
}
