import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { useToastError } from '@hooks';
import { Spot, useSupdateSpotCapacityMutation } from '@graphql';

const STEP = 10;
const MIN = 10;
const MAX = 1000;

interface CapacityProps {
  account: Spot;
}

export default function Capacity(props: CapacityProps) {
  const { account } = props;
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateCapacity] = useSupdateSpotCapacityMutation();

  const initialValues = { capacity: account.configuration.capacity || 0 };
  const validationSchema = yup.object({
    capacity: yup.number().min(10).max(1000).required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateCapacity({
        variables: { id: account.id, value: values.capacity },
        onCompleted({ supdateSpotCapacity }) {
          setTimeoutId(null);
          formik.resetForm({ values });
          toast(t('spotCapacity.toast.success', { ns: 'field' }), { type: 'success' });
        },
        onError: toastError,
      });
    },
  });

  const handleSetVal = (capacity: number) => {
    formik.setFieldValue('capacity', capacity);
    formik.setFieldTouched('capacity', (account.configuration.capacity as number) !== capacity);
  };

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    if (formik.dirty && formik.isValid) {
      const newTmoutId = setTimeout(formik.submitForm, 500);
      setTimeoutId(newTmoutId);
    }
  }, [formik.dirty, formik.isValid, formik.values]);

  return (
    <Stack width="100%" spacing={1}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack>
          <Typography fontWeight="bold">{t('spotCapacity.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotCapacity.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>
      <Stack px={2}>
        <Slider
          sx={{ mt: 5 }}
          name="capacity"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.capacity}
          defaultValue={formik.values.capacity}
          valueLabelDisplay="on"
          step={STEP}
          min={MIN}
          max={MAX}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2" onClick={() => handleSetVal(MIN)} sx={{ cursor: 'pointer' }}>
            {MIN} pers
          </Typography>
          <Typography variant="body2" onClick={() => handleSetVal(MAX)} sx={{ cursor: 'pointer' }}>
            +{MAX} pers
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
