import Stack from '@mui/material/Stack';

import { useListHomeSpotsQuery, ListHomeSpotsQuery } from '@graphql';
import { Sliders, Highlight } from '@components';

const SpotsPage = () => {
  const { data } = useListHomeSpotsQuery();

  if (!data?.homeSpots) {
    return null;
  }

  return (
    <Stack pb={8}>
      <Highlight type="spot" />
      <Sliders
        categories={data.homeSpots.reduce((categories, category) => {
          if (category.spots.length) {
            categories.push(category);
          }

          return categories;
        }, [] as ListHomeSpotsQuery['homeSpots'])}
      />
    </Stack>
  );
};

export default SpotsPage;
