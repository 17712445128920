import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Virtual } from 'swiper/modules';
import { useSearchParams } from 'react-router-dom';

import { useGetConsumerDetailedQuery, GetConsumerDetailedQuery } from '@graphql';
import Header from './header';
import Home from './home';
import Memories from './memories';
import Friends from './friends';

interface ConsumerDialogProps {
  handleBack: (e: React.SyntheticEvent, reason: string) => void;
}

function ConsumerDialog(props: ConsumerDialogProps) {
  const { handleBack } = props;
  const [activeTab, setActiveTab] = React.useState(0);
  const [consumer, setConsummer] = React.useState<GetConsumerDetailedQuery['consumer'] | null>(null);
  const swiperRef = React.useRef<SwiperClass>();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  useGetConsumerDetailedQuery({
    // if no slug, skip query. Slug is only used in searchs for spots
    skip: !searchParams.get('c'),
    variables: { slug: searchParams.get('c') || '' },
    onCompleted: ({ consumer }) => {
      setConsummer(consumer);
    },
  });

  if (!consumer) {
    return null;
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);

    if (swiperRef.current) {
      swiperRef.current.slideTo(newTab);
    }
  };

  return (
    <Stack sx={{ position: 'relative' }} minHeight="100vh">
      <Stack sx={{ position: 'relative' }}>
        <Header
          consumer={consumer}
          handleBack={handleBack}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
      </Stack>
      {consumer.isMyFriend ? (
        <DialogContent sx={{ p: 0 }}>
          <Stack spacing={5}>
            <Swiper
              modules={[Virtual]}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              slidesPerView={1}
              style={{ marginTop: 0 }}
              initialSlide={activeTab}
              virtual
            >
              <SwiperSlide virtualIndex={0}>{<Home consumer={consumer} />}</SwiperSlide>
              <SwiperSlide virtualIndex={1}>{<Memories consumer={consumer} />}</SwiperSlide>
              <SwiperSlide virtualIndex={2}>{<Friends consumer={consumer} />}</SwiperSlide>
            </Swiper>
          </Stack>
        </DialogContent>
      ) : (
        <Stack spacing={3} alignItems="center" justifyContent="center" flex={1}>
          <Stack p={2} sx={{ borderRadius: '50%', border: '1px solid white' }}>
            <theme.icons.lock fontSize="large" />
          </Stack>
          <Typography>Tu n'es pas encore ami avec ce compte</Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default React.memo(ConsumerDialog);
