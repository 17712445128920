import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { AvatarCustom } from '@components';
import { ShareMenu } from '../../common';
import FriendRequestButton from '../buttons/FriendRequestButton';
import useStyles from './styles';
import { HeaderProps } from './types';
import StatCards from './StatCards';

function MobileHeader(props: HeaderProps) {
  const { consumer, handleTabChange, activeTab, handleBack } = props;
  const { account } = useSession();
  const theme = useTheme();
  const styles = useStyles(theme);
  const { t } = useTranslation('cta');

  if (!consumer) {
    return null;
  }

  return (
    <>
      <Stack direction="row" justifyContent={'space-between'} p={3} width="100%">
        <Stack direction="row" onClick={handleBack} sx={{ cursor: 'pointer' }}>
          <theme.icons.back />
          <Typography> {t('back')}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems={'center'} pr={1}>
          <ShareMenu />
        </Stack>
      </Stack>
      <Stack spacing={3} py={3} alignItems="center">
        <AvatarCustom
          ressource={consumer.avatar?.url || null}
          id={consumer.id}
          type="account"
          alt={consumer.avatar?.url ? name + '-avatar' : ''}
          sx={{ width: 200, height: 200 }}
        />
        <Stack spacing={1} alignItems="center">
          <Typography variant="h4" color="textPrimary">
            {consumer.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            @{consumer.slug}
          </Typography>
        </Stack>
        <StatCards consumer={consumer} />
        {consumer.id !== account?.id && (
          <Stack spacing={1} px={2} width="100%" direction="row">
            <FriendRequestButton consumerId={consumer.id} />
            <Button
              variant="contained"
              color="uncolored"
              fullWidth
              startIcon={<theme.icons.send />}
              size="small"
            >
              {t('write')}
            </Button>
          </Stack>
        )}
      </Stack>

      {/* TABS */}
      {consumer.isMyFriend && (
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          indicatorColor="primary"
          aria-label="tabs-spot-menu"
          variant="fullWidth"
        >
          <Tab icon={<theme.icons.home sx={{ fontSize: '1.2rem' }} />} sx={styles.tab} />
          <Tab icon={<theme.icons.grid sx={{ fontSize: '1.2rem' }} />} sx={styles.tab} />
          <Tab icon={<theme.icons.friends sx={{ fontSize: '1.2rem' }} />} sx={styles.tab} />
        </Tabs>
      )}
    </>
  );
}

export default MobileHeader;
