import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useGetUserByEmailTokenLazyQuery, useVerifyEmailMutation } from '@graphql';
import { useSession, useToastError } from '@hooks';
import { APP_TITLE } from '@utils';

export function ActivatePage(): React.ReactElement {
  const { refetch } = useSession();
  const navigate = useNavigate();
  const toastError = useToastError();
  let [searchParams] = useSearchParams();
  const { t } = useTranslation('authentication');

  const [verifyEmail] = useVerifyEmailMutation({
    async onCompleted({ verifyEmail }) {
      await refetch();
      navigate('/', { replace: true });
      toast.success(t('Account successfully activated!'));
    },
    onError: toastError,
  });

  const [getUserByToken] = useGetUserByEmailTokenLazyQuery({
    onCompleted({ userByEmailToken }) {
      if (!userByEmailToken || userByEmailToken?.isVerified === true) {
        navigate('/', { replace: true });
      } else {
        const token = searchParams.get('token');
        verifyEmail({ variables: { token: token! } });
      }
    },
  });

  React.useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      getUserByToken({ variables: { token } });
    } else {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <Helmet>
      <title>{APP_TITLE}</title>
    </Helmet>
  );
}
