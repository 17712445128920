import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { EmptyState } from '../../common';
import { useTranslation } from 'react-i18next';

import { GetConsumerDetailedQuery } from '@graphql';

interface HomeProps {
  consumer: GetConsumerDetailedQuery['consumer'];
}

function Home(props: HomeProps) {
  const { consumer } = props;
  const theme = useTheme();
  const { t } = useTranslation('account');

  return (
    <Stack spacing={5} p={3}>
      <EmptyState text={t('text.No news yet')} />
    </Stack>
  );
}

export default React.memo(Home);
