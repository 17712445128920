import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

export const FromPicker = () => {
  const [fromValue, setFromValue] = React.useState<Moment | null>(null);
  const [toValue, setToValue] = React.useState<Moment | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { t } = useTranslation('cta');

  React.useEffect(() => {
    const from = searchParams.get('from');
    const to = searchParams.get('to');

    if (from) {
      setFromValue(moment(from).startOf('day'));
    }
    if (to) {
      setToValue(moment(to).startOf('day'));
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (fromValue === null) {
      searchParams.delete('from');
    } else {
      searchParams.set('from', moment(fromValue).format('YYYY-MM-DD'));
    }
    setSearchParams(searchParams);
  }, [fromValue]);

  return (
    <DatePicker
      label="Du"
      timezone="Europe/Paris"
      maxDate={toValue || undefined}
      value={fromValue}
      slotProps={{
        textField: {
          size: 'small',
          InputLabelProps: {
            style: {
              color: theme.palette.text.secondary,
            },
          },
          InputProps: {
            style: {
              color: theme.palette.text.secondary,
            },
          },
        },
        actionBar: {
          actions: Device.isMobile ? ['clear', 'today', 'accept'] : ['clear', 'today'],
          sx: {
            '& .MuiButton-root': {
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'white',
              },
            },
          },
        },
        toolbar: {
          hidden: true,
        },
      }}
      localeText={{
        cancelButtonLabel: t('cancel'),
        clearButtonLabel: t('clear'),
        todayButtonLabel: t('today'),
        okButtonLabel: 'OK',
      }}
      onAccept={(value) => setFromValue(value)}
    />
  );
};

export const ToPicker = () => {
  const [fromValue, setFromValue] = React.useState<Moment | null>(null);
  const [toValue, setToValue] = React.useState<Moment | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { t } = useTranslation('cta');

  React.useEffect(() => {
    const from = searchParams.get('from');
    const to = searchParams.get('to');

    if (from) {
      setFromValue(moment(from).startOf('day'));
    }
    if (to) {
      setToValue(moment(to).startOf('day'));
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (toValue === null) {
      searchParams.delete('to');
    } else {
      searchParams.set('to', moment(toValue).format('YYYY-MM-DD'));
    }
    setSearchParams(searchParams);
  }, [toValue, fromValue]);

  return (
    <DatePicker
      label="Au"
      timezone="Europe/Paris"
      minDate={fromValue || undefined}
      value={toValue}
      localeText={{
        cancelButtonLabel: t('cancel'),
        clearButtonLabel: t('clear'),
        todayButtonLabel: t('today'),
        okButtonLabel: 'OK',
      }}
      onAccept={(value) => setToValue(value)}
      slotProps={{
        textField: {
          size: 'small',
          InputLabelProps: {
            style: {
              color: theme.palette.text.secondary,
            },
          },
          InputProps: {
            style: {
              color: theme.palette.text.secondary,
            },
          },
        },
        actionBar: {
          actions: Device.isMobile ? ['clear', 'today', 'accept'] : ['clear', 'today'],
          sx: {
            '& .MuiButton-root': {
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'white',
              },
            },
          },
        },
        toolbar: {
          hidden: true,
        },
      }}
    />
  );
};
