import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multimonth from '@fullcalendar/multimonth';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { previewBookingVar } from '@context';
import { useListMySpotBookingsQuery, ListMySpotBookingsQuery } from '@graphql';
import * as helpers from './helpers';

export function DesktopCalendar() {
  const { user, account } = useSession();
  const location = useLocation();
  const [bookings, setBookings] = React.useState<ListMySpotBookingsQuery['myBookings']>([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();

  const { loading: bookingLoading } = useListMySpotBookingsQuery({
    skip: !account?.id,
    onCompleted: ({ myBookings }) => {
      setBookings(myBookings);
    },
  });

  function handleEventClick(clickInfo: any) {
    const { event } = clickInfo;

    if (event.extendedProps.__typename === 'Event') {
      // previewBookingVar(event.extendedProps);
    }
    if (event.extendedProps.__typename === 'Booking') {
      previewBookingVar(event.extendedProps.id);
    }

    // navigate(`${pathname}/${event.id}`);
  }

  function handleDateSelect(selectInfo: any) {
    const { start, end, allDay } = selectInfo;

    if (moment(start).isBefore(moment())) {
      return;
    }

    const from = moment(start).set({
      hour: moment().add(1, 'hour').hour(),
      minute: 0,
      seconds: 0,
      milliseconds: 0,
    });

    const to = moment(from).add(1, 'hour');
  }

  const locales = {
    fr: frLocale,
    es: esLocale,
    en: undefined,
  };
  const locale = user?.preferences.language
    ? locales[user?.preferences.language as 'fr' | 'es' | 'en']
    : undefined;

  return (
    <>
      <Paper
        elevation={5}
        sx={{
          border: '1px solid #3f3f3f',
          backgroundColor: theme.palette.background.paper,
          height: '80vh',
        }}
      >
        {bookingLoading ? (
          <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <FullCalendar
            height="100%"
            themeSystem="bootstrap5"
            firstDay={1} // monday
            locale={locale}
            plugins={[bootstrap5Plugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'today',
            }}
            views={{
              dayGridMonth: {
                dayMaxEventRows: 3,
                titleFormat: {
                  year: 'numeric',
                  month: 'long',
                },
              },
            }}
            // defaultAllDay
            events={bookings.map((booking) => ({
              ...booking,
              start: helpers.getItemStart(booking),
              title: helpers.getItemTitle(booking),
              backgroundColor: helpers.getItemColor(booking),
              extendedProps: { ...booking },
            }))}
            editable={true}
            selectable={true}
            select={handleDateSelect}
            eventClick={handleEventClick}
          />
        )}
      </Paper>
    </>
  );
}
